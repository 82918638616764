import React, {Component} from "react"
import HeaderWrapper from "./header.style"
import LeftMenu from "./menu/left-menu/left-menu";
import LogoImage from '../../assets/images/logo.svg';
import RightMenu from "./menu/right-menu/right-menu";
import Search from "../../features/search/search";
import {isOnHomePage, isOnOrderPage} from "../../utils/router-parser";
import Actions from "../../redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom"

function mapStateToProps(state) {
    return {shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Header extends Component {
    render() {
        const isHomePage = isOnHomePage.call(this)
        const isOrderPage = isOnOrderPage.call(this)

        let searchPlaceholder;
        if (isHomePage) {
            searchPlaceholder = "Search locations here"
        } else if (isOrderPage) {
            searchPlaceholder = "Search menu items here"
        }

        return (
            <HeaderWrapper {...this.props}>
                <LeftMenu logo={LogoImage}/>

                {searchPlaceholder ? (
                    <Search minimal={true} className="headerSearch" placeholder={searchPlaceholder}/>
                ) : <div/>}

                <RightMenu avatar={null} isAuthenticated={false}/>
            </HeaderWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
