import React, {Component} from "react"
import PropTypes from "prop-types"
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

class Scrollbar extends Component {
    render() {
        let {children, className, options, style,} = this.props;

        return (
            <OverlayScrollbarsComponent
                options={{
                    className: `${className} os-theme-thin`,
                    scrollbars: {
                        autoHide: 'leave',
                    },
                    ...options,
                }}
                style={style}
                {...this.props}
            >
                {children}
            </OverlayScrollbarsComponent>
        );
    }
}

Scrollbar.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    options: PropTypes.any,
    style: PropTypes.any
};

export default Scrollbar;