import React, {Component} from "react"
import {Form, withFormik} from "formik"
import * as Yup from "yup";
import {FieldWrapper, Heading} from "./notes-card.style";
import TextField from "../forms/text-field/text-field";
import Button from "../button/button";
import {FormattedMessage} from "react-intl";
import {closeModal} from '@redq/reuse-modal';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import "react-day-picker/lib/style.css"

function mapStateToProps(state) {
    return {checkout: state.checkout, user: state.user, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const FormEnhancer = withFormik({
    mapPropsToValues: ({notes: orderNotes}) => {
        return {notes: orderNotes || ""};
    },
    validationSchema: Yup.object().shape({
        notes: Yup.string().required('Notes is required'),
    }),
    handleSubmit: async (values, {status, resetForm, setErrors, setStatus, setSubmitting, setFieldError, props}) => {
        props.updateCheckoutNotes(values.notes);

        closeModal();
    },
});

class NotesCard extends Component {
    render() {
        let {values, touched, errors, handleChange, handleBlur, handleSubmit} = this.props;

        return (
            <Form>
                <Heading>Input Order Notes</Heading>

                <FieldWrapper>
                    <TextField
                        rows="3"
                        style={{minHeight: 30}}
                        id="notes"
                        as="textarea"
                        placeholder="Enter Notes"
                        error={touched.notes && errors.notes}
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FieldWrapper>

                <Button
                    onClick={handleSubmit}
                    type="submit"
                    style={{width: '100%', height: '44px'}}
                >
                    <FormattedMessage id="__TODO__" defaultMessage="Save Notes"/>
                </Button>
            </Form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEnhancer(NotesCard));
