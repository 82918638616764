import React, {Component} from "react"
import {
    CostCalculation,
    DeliveryInfo,
    OrderTable,
    OrderTableWrapper,
    Price,
    PriceRow,
    ProgressWrapper
} from "./order-details.style";
import {FormattedMessage} from "react-intl";
import Table from "rc-table";
import ProgressBox from "../../components/progress-box/progress-box";
import {
    getPaymentMethod,
    getProgressData,
    getProgressStep,
    orderTableColumns
} from "../../pages/receipt/receipt.constants";
import {toDollars} from "../../utils/util";
import {InfoBlock, InfoBlockWrapper, Text} from "../../pages/receipt/receipt.style";
import moment from "moment"

const progressData = ['Order Received', 'Order On The Way', 'Order Delivered'];

class OrderDetails extends Component {
    renderDiscount() {
        let {order} = this.props;

        if (order.DISCOUNTS === 0) {
            return <div/>
        }

        return (
            <PriceRow>
                <FormattedMessage
                    id="intlOrderDetailsDiscount"
                    defaultMessage="Discount"
                />
                <Price>
                    (${toDollars(order.DISCOUNTS)})
                </Price>
            </PriceRow>
        )
    }

    renderTip() {
        let {order} = this.props;

        if (order.TIP_AMOUNT === 0) {
            return <div/>
        }

        return (
            <PriceRow>
                <FormattedMessage
                    id="__TODO__"
                    defaultMessage="Tip"
                />
                <Price>
                    ${toDollars(order.TIP_AMOUNT)}
                </Price>
            </PriceRow>
        )
    }

    render() {
        let {items, order} = this.props;

        return (
            <>
                <DeliveryInfo>
                    {/*<DeliveryAddress>*/}
                    {/*    <h3>*/}
                    {/*        <FormattedMess   age*/}
                    {/*            id="__TODO__"*/}
                    {/*            defaultMessage="Notes"*/}
                    {/*        />*/}
                    {/*    </h3>*/}
                    {/*    <Address>This is notes</Address>*/}
                    {/*</DeliveryAddress>*/}

                    <InfoBlockWrapper style={{width: "100%"}}>
                        <InfoBlock>
                            <Text bold className="title">
                                <FormattedMessage
                                    id="orderNumberText"
                                    defaultMessage="Order Number"
                                />
                            </Text>
                            <Text>{order.ID}</Text>
                        </InfoBlock>

                        <InfoBlock>
                            <Text bold className="title">
                                <FormattedMessage id="__TODO__" defaultMessage="Date Ordered"/>
                            </Text>
                            <Text>{moment(order.DATE_SENT).format("MMMM DD, YYYY")}</Text>
                        </InfoBlock>

                        {order.DATE_ESTIMATE !== null ? (
                            <InfoBlock>
                                <Text bold className="title">
                                    <FormattedMessage id="__TODO__" defaultMessage="Date Estimate"/>
                                </Text>
                                <Text>{moment(order.DATE_ESTIMATE).format("h:mma ddd MMM Do")}</Text>
                            </InfoBlock>
                        ) : <div/>}

                        {order.DATE_SCHEDULED !== null ? (
                            <InfoBlock>
                                <Text bold className="title">
                                    <FormattedMessage id="__TODO__" defaultMessage="Date Scheduled"/>
                                </Text>
                                <Text>{moment(order.DATE_SCHEDULED).format("h:mma MMMM DD, YYYY")}</Text>
                            </InfoBlock>
                        ) : <div/>}
                        {/*<InfoBlock>*/}
                        {/*    <Text bold className="title">*/}
                        {/*        <FormattedMessage id="totalText" defaultMessage="Total" />*/}
                        {/*    </Text>*/}
                        {/*    <Text>$10,944.00</Text>*/}
                        {/*</InfoBlock>*/}

                        <InfoBlock>
                            <Text bold className="title">
                                <FormattedMessage
                                    id="paymenMethodText"
                                    defaultMessage="Payment Method"
                                />
                            </Text>
                            <Text>
                                {getPaymentMethod(order)}
                            </Text>
                        </InfoBlock>
                    </InfoBlockWrapper>

                    <CostCalculation>
                        <PriceRow>
                            <FormattedMessage id="subTotal" defaultMessage="Sub total"/>
                            <Price>
                                ${toDollars(order.SUBTOTAL + order.DISCOUNTS)}
                            </Price>
                        </PriceRow>
                        {this.renderDiscount()}
                        {order.FEES > 0 && (
                            <PriceRow>
                                <FormattedMessage id="__TODO__" defaultMessage="Fees"/>
                                <Price>
                                    ${toDollars(order.FEES)}
                                </Price>
                            </PriceRow>
                        )}
                        <PriceRow>
                            <FormattedMessage id="__TODO__" defaultMessage="Taxes"/>
                            <Price>
                                ${toDollars(order.TAXES)}
                            </Price>
                        </PriceRow>
                        {this.renderTip()}
                        <PriceRow className="grandTotal">
                            <FormattedMessage id="totalText" defaultMessage="Total"/>
                            <Price>
                                ${toDollars(order.TOTAL + order.TIP_AMOUNT)}
                            </Price>
                        </PriceRow>
                    </CostCalculation>
                </DeliveryInfo>

                <ProgressWrapper>
                    <ProgressBox data={getProgressData(order)} status={getProgressStep(order) + 1}/>
                </ProgressWrapper>

                <OrderTableWrapper>
                    <Table
                        columns={orderTableColumns}
                        data={items}
                        rowKey={(record) => record.id}
                        components={{
                            table: OrderTable
                        }}
                        className="orderDetailsTable"
                        // scroll={{ y: 350 }}
                    />
                </OrderTableWrapper>
            </>
        )
    }
}

export default OrderDetails;
