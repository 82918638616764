import React, {Component} from "react";
import styled from 'styled-components';
import Sticky from 'react-stickynode';
import CategoryWalker from "../../components/category-walker/category-walker";
import Scrollbar from "../../components/scrollbar/scrollbar";
import CardMenu from "../../components/card-menu/card-menu";

const Aside = styled.aside({
  width: '300px',
  left: 30,
  height: 'calc(100% - 110px)',
});

const CardMenuWrapper = styled.div({
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr 1fr',
  gridAutoRows: 'max-content',
  paddingBottom: 30,

  '@media (min-width: 550px) and (max-width: 990px)': {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
});

const MobileOnly = styled.div({
  display: 'none',
  zIndex: 10,

  '@media (max-width: 990px)': {
    display: 'block',
  },
});

const DesktopOnly = styled.div({
  display: 'none',
  '@media (min-width: 991px)': {
    display: 'block',
  },
});

class SidebarCardMenu extends Component {
  render() {
    let {onCategoryClick = () => console.log("YO"), active, data = []} = this.props;

    return (
      <React.Fragment>
        <MobileOnly>
          <div style={{height: 12}}/>
          <Sticky top={80}>
            <CategoryWalker
              {...this.props}
              ref={(e) => this.categoryWalker = e}
              category={active}
              style={{
                backgroundColor: '#ffffff',
                paddingTop: '15px',
                boxShadow: '0 1px 2px rgba(0,0,0,0.06)',
              }}
            >
              <CardMenuWrapper>
                <CardMenu
                  data={data}
                  onClick={(category) => {
                    onCategoryClick(category);

                    this.categoryWalker.close();
                  }}
                  active={active}
                />
              </CardMenuWrapper>
            </CategoryWalker>
          </Sticky>
        </MobileOnly>

        <DesktopOnly>
          <Aside>
            <Sticky top={120}>
              <Scrollbar
                style={{height: '100%', maxHeight: '100%'}}
                options={{
                  scrollbars: {
                    visibility: 'hidden',
                  },
                }}
              >
                <CardMenuWrapper>
                  <CardMenu
                    data={data}
                    onClick={onCategoryClick}
                    active={active}
                  />
                </CardMenuWrapper>
              </Scrollbar>
            </Sticky>
          </Aside>
        </DesktopOnly>
      </React.Fragment>
    );
  }
}

export default SidebarCardMenu;
