import React, {Component} from "react"
import PropTypes from "prop-types"
import {StyledButton} from "./load-more-button.style";
import {Spinner} from "./button.style";

class LoadMoreButton extends Component {
    render() {
        let {loading = false, children} = this.props;

        return (
            <StyledButton {...this.props} role="button">
                {children}
                {loading && <Spinner/>}
            </StyledButton>
        )
    }
}

LoadMoreButton.propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool
}

export default LoadMoreButton;