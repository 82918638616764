import React, {Component} from "react"
import PropTypes from "prop-types"
import {Waypoint} from "react-waypoint";
import {Box, Content, ContentRow, Description, SearchWrapper} from "./banner.style";
import Search from "../../features/search/search";
import {FormattedMessage} from "react-intl";
import Button from "../button/button";
import SpringModal from "../spring-modal/spring-modal";
import TypeNav from "../type-nav/type-nav";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {general: state.general, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class MobileBanner extends Component {
    state = {open: false};

    onWaypointPositionChange({currentPosition}) {
        if (!currentPosition || currentPosition === 'above') {
            this.props.updateSticky(true)
        }
    }

    renderLocations() {
        let {location, locations} = this.props.shop;

        if (location === null || locations.length === 1 || locations.length > 10) {
            return (
                <div />
            )
        }

        return (
            <ContentRow>
                <Description>
                    <FormattedMessage
                        id="__TODO__"
                        defaultMessage="Select a location"
                        values={{minute: 90}}
                    />
                </Description>

                <Button
                    variant="text"
                    onClick={() => this.setState({open: true})}
                    style={{textTransform: 'capitalize'}}
                >
                    {location.NAME}
                </Button>
            </ContentRow>
        )
    }

    render() {
        let {open} = this.state;

        return (
            <Box display={['flex', 'flex', 'none']}>
                <Content>
                    {this.renderLocations()}

                    <SearchWrapper>
                        <Search minimal={true} placeholder="Search locations here" />
                    </SearchWrapper>

                    <Waypoint
                        onEnter={() => this.props.updateSticky(false)}
                        onLeave={() => this.props.updateSticky(true)}
                        onPositionChange={this.onWaypointPositionChange.bind(this)}
                    />

                    <SpringModal isOpen={open} onRequestClose={() => this.setState({open: false})}>
                        <TypeNav onRequestClose={() => this.setState({open: false})}/>
                    </SpringModal>
                </Content>
            </Box>
        )
    }
}

MobileBanner.propTypes = {
    intlTitleId: PropTypes.string.isRequired,
    type: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileBanner);
