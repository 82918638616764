import React, {Component} from "react"
import {Col, HeadingSection, Row, SettingsForm, SettingsFormContent, Title} from "./settings.style";
import {FormattedMessage} from "react-intl";
import Input from "../../../components/forms/input";
import {Label} from "../../../pages/shop/shop.style";
import Button from "../../../components/button/button";
import Address from "../../address/address";
import Payment from "../../payment/payment";
import {bindActionCreators} from "redux";
import Actions from "../../../redux";
import {connect} from "react-redux";
import {request} from "../../../utils/request";
import Cards from "../../cards/cards";
import {asyncDelay, asyncTimeout} from "../../../utils/util";

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Settings extends Component {
    state = {name: "", email: "", loading: false};

    componentDidMount() {
        let {account} = this.props.user;

        this.setState({name: account.FULL_NAME, email: account.EMAIL})
    }

    handleSave() {
        let {account} = this.props.user;
        let {name, email} = this.state;

        let toChange = {};
        if (name !== account.FULL_NAME) {
            toChange.FULL_NAME = name;
        }

        if (email !== account.EMAIL) {
            toChange.EMAIL = email;
        }

        if (Object.keys(toChange).length === 0) {
            return;
        }

        let startTime = Date.now();
        this.setState({loading: true});
        request("account/info", "PATCH", toChange).then(async () => {
            this.props.updateAccount({...account, FULL_NAME: name, EMAIL: email});

            await asyncDelay(startTime);
        }).catch((err) => {
            alert(err.message);
        }).finally(() => {
            this.setState({loading: false})
        });
    }

    render() {
        let {name, email, loading} = this.state;

        return (
            <SettingsForm>
                <SettingsFormContent>
                    <HeadingSection>
                        <Title>
                            <FormattedMessage
                                id="profilePageTitle"
                                defaultMessage="Your Profile"
                            />
                        </Title>
                    </HeadingSection>
                    <Row style={{alignItems: 'flex-end', marginBottom: '50px'}}>
                        <Col xs={12} sm={5} md={5} lg={5}>
                            <Label>
                                <FormattedMessage
                                    id="profileNameField"
                                    defaultMessage="Your Name"
                                />
                            </Label>
                            <Input
                                type="text"
                                label="Name"
                                name="name"
                                value={name}
                                onChange={(e) => this.setState({name: e.target.value})}
                                backgroundColor="#F7F7F7"
                                height="48px"
                                // intlInputLabelId="profileNameField"
                            />
                        </Col>

                        <Col xs={12} sm={5} md={5} lg={5}>
                            <Label>
                                <FormattedMessage
                                    id="profileEmailField"
                                    defaultMessage="Your Email"
                                />
                            </Label>
                            <Input
                                type="email"
                                name="email"
                                label="Email Address"
                                value={email}
                                onChange={(e) => this.setState({email: e.target.value})}
                                backgroundColor="#F7F7F7"
                            />
                        </Col>

                        <Col xs={12} sm={2} md={2} lg={2}>
                            <Button size="big" style={{width: '100%'}} onClick={this.handleSave.bind(this)}
                                    loading={loading}>
                                <FormattedMessage id="profileSaveBtn" defaultMessage="Save"/>
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{position: 'relative'}}>
                            <SettingsFormContent>
                                <Address/>
                            </SettingsFormContent>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <SettingsFormContent>
                                <Cards/>
                            </SettingsFormContent>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <SettingsFormContent>
                                <Payment/>
                            </SettingsFormContent>
                        </Col>
                    </Row>
                </SettingsFormContent>
            </SettingsForm>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);