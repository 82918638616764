import React from 'react'
import {render} from 'react-dom'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {Provider} from 'react-redux'
import {combineReducers, createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk';
import {ThemeProvider} from "styled-components";
import {defaultTheme} from "./settings/theme";
import AppLayout from "./layouts/app";
import {messages} from './settings/translation/messages';
import {IntlProvider} from 'react-intl';
import {GlobalStyle} from "./assets/styles/global.style";
import {userReducer} from "./redux/user";
import {generalReducer} from "./redux/general";
import Shops from "./pages/shops";
import {cartReducer} from "./redux/cart";
import {shopReducer} from "./redux/shop";
import {modalReducer} from "./redux/modals";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {version} from "./../package.json"

import 'typeface-lato';
import 'typeface-poppins';
import Shop from "./pages/shop/shop";
import Checkout from "./pages/checkout/checkout";
import {checkoutReducer} from "./redux/checkout";
import Receipt from "./pages/receipt/receipt";
import Orders from "./pages/profile/orders";
import SettingsPage from "./pages/profile/settings";
import Help from "./pages/help/help";
import ProductPage from "./pages/product/product-page";

Date.prototype.getISODay = function () {
    let day = this.getDay();

    day -= 1;

    return day === -1 ? 6 : day;
};

Date.prototype.getCeilQuarterHour = function () {
    let clone = new Date(this);

    if (clone.getMinutes() > 52) {
        if (clone.getHours() === 23) {
            clone.setHours(0);
        } else {
            clone.setHours(clone.getHours() + 1);
        }
    }

    clone.setMinutes((Math.ceil(clone.getMinutes() / 15) * 15) % 60)
    clone.setSeconds(0);
    clone.setMilliseconds(0);

    return clone;
};

Date.prototype.getFloorQuarterHour = function () {
    let clone = new Date(this);

    clone.setMinutes((Math.floor(clone.getMinutes() / 15) * 15) % 60)
    clone.setSeconds(0);
    clone.setMilliseconds(0);

    return clone;
};

String.prototype.replaceAll = function (search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

if (window.location.href.indexOf('localhost') === -1) {
    Sentry.init({
        dsn: "https://99bf490306d34b12930c37a91ede4270@o434870.ingest.sentry.io/5442379",
        release: version,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
    });
}

let store = createStore(combineReducers({
    user: userReducer, general: generalReducer, cart: cartReducer, shop: shopReducer, modal: modalReducer,
    checkout: checkoutReducer
}), compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));

let locale = "en";
render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={defaultTheme}>
                <GlobalStyle/>

                <IntlProvider locale={locale} messages={messages[locale]}>
                    <AppLayout>
                        <Switch>
                            <Route path="/:ID/order" component={() => <Shop/>}/>
                            <Route path="/:ID/product/:PRODUCT" component={() => <ProductPage/>}/>
                            <Route path="/orders" component={() => <Orders/>}/>
                            <Route path="/profile" component={() => <SettingsPage/>}/>
                            <Route path="/help" component={() => <Help/>}/>
                            <Route path="/:ID/checkout" component={() => <Checkout/>}/>
                            <Route path="/:ID/receipt/:DATE/:ID" component={Receipt}/>
                            <Route path="/receipt/:DATE/:ID" component={Receipt}/>
                            <Route path="/*" component={() => <Shops/>}/>
                        </Switch>
                    </AppLayout>
                </IntlProvider>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
    ,
    document.getElementById('root')
);
