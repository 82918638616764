import {request} from "../utils/request";
import {asyncDelay, isValidPhone} from "../utils/util";
import {closeModal} from '@redq/reuse-modal';
import {updateAddress, updateCard, updateCheckoutName, updateCheckoutPhone} from "./checkout";
import { H } from 'highlight.run'

const defaultState = {
    loading: false, token: null, account: null, cards: [], addresses: [], giftCards: [], orders: [], phones: []
};

const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
const UPDATE_ACCOUNT_LEGACY = 'UPDATE_ACCOUNT_LEGACY';
const UPDATE_LOADING = 'UPDATE_LOADING';
const UPDATE_TOKEN = 'UPDATE_TOKEN';
const UPDATE_CARDS = 'UPDATE_CARDS';
const UPDATE_ADDRESSES = 'UPDATE_ADDRESSES';
const UPDATE_GIFT_CARDS = 'UPDATE_GIFT_CARDS';
const UPDATE_PHONES = 'UPDATE_PHONES';

export function updateAccount(credentials) {
    return {
        type: UPDATE_ACCOUNT,
        payload: credentials
    }
}

export function updatePhones(credentials) {
    return {
        type: UPDATE_PHONES,
        payload: credentials
    }
}

export function updateAccountLegacy(credentials) {
    return {
        type: UPDATE_ACCOUNT_LEGACY,
        payload: credentials
    }
}

export function updateGiftCards(credentials) {
    return {
        type: UPDATE_GIFT_CARDS,
        payload: credentials
    }
}

export function updateGiftCard(giftCard) {
    return (dispatch, getState) => {
        let {giftCards} = getState().user;

        let index = giftCards.findIndex((item) => item.ID === giftCard.ID);
        if (index === -1) {
            return Promise.resolve();
        }

        giftCards.splice(index, 1, giftCard);
        dispatch(updateGiftCards(giftCards));
    }
}

export function updateCards(credentials) {
    return {
        type: UPDATE_CARDS,
        payload: credentials
    }
}

export function addCard(credentials) {
    return (dispatch, getState) => {
        let {cards} = getState().user;

        dispatch(updateCards([...cards, credentials]));
    }
}

export function removeCard(card) {
    return (dispatch, getState) => {
        let {cards} = getState().user;

        let index = cards.findIndex((item) => item.ID === card.ID);
        if (index === -1) {
            return Promise.resolve();
        }

        cards.splice(index, 1);
        dispatch(updateCards(cards));
    }
}

export function updateAddresses(credentials) {
    return {
        type: UPDATE_ADDRESSES,
        payload: credentials
    }
}

export function addAddress(address) {
    return (dispatch, getState) => {
        let {addresses} = getState().user;

        dispatch(updateAddresses([...addresses, address]));
    }
}

export function removeAddress(address) {
    return (dispatch, getState) => {
        let {addresses} = getState().user;

        let index = addresses.findIndex((item) => item.ID === address.ID);
        if (index === -1) {
            return Promise.resolve();
        }

        addresses.splice(index, 1);
        dispatch(updateAddresses(addresses));
    }
}

function updateLoading(credentials) {
    return {
        type: UPDATE_LOADING,
        payload: credentials
    }
}


function updateToken(credentials) {
    return {
        type: UPDATE_TOKEN,
        payload: credentials
    }
}

export function submitLoginPhone(phone) {
    return async (dispatch, getState) => {
        let {loading} = getState().user;

        if (loading) {
            return;
        }

        if (!isValidPhone(phone)) {
            return Promise.reject("PHONE_INVALID");
        }

        let requestStart = Date.now();
        return await request('login/initiate', 'POST', {PHONE: phone}).then(async (payload) => {
            await asyncDelay(requestStart, 2500);

            return payload;
        }).catch(() => {
            return Promise.reject("SERVER_ERROR");
        });
    }
}

const COMPLETE_LOGIN = 'COMPLETE_LOGIN';

function completeLogin(credentials) {
    return {
        type: COMPLETE_LOGIN,
        payload: credentials
    }
}

export function submitLoginToken(token, unique) {
    return async (dispatch, getState) => {
        let {loading} = getState().user;

        if (loading) {
            return;
        }

        let requestStart = Date.now();
        await request('login/complete', 'POST', {
            UNIQUE: unique, TOKEN: token, CLIENT: {
                NAME: "DRIP.IS",
                INFO: "Website",
                TYPE: 2
            }
        }).then(async (payload) => {
            await asyncDelay(requestStart, 500);

            closeModal();

            localStorage.setItem("TOKEN", payload);

            let account = await request("account/valid", "GET", null);

            dispatch(completeLogin(account));
            dispatch(loadUser());

            if (account.EMAIL === null) {
                // TODO: Let's go to work
            } else {
            }
        }).catch(() => {
            closeModal();

            return Promise.reject("SERVER_ERROR");
        });

        return Promise.resolve();
    }
}

export function loadUser() {
    return async (dispatch, getState) => {
        let {
            ACCOUNT: account, ORDERS: orders, CARDS: cards, GIFT_CARDS: giftCards, PATRONS: patrons, ADDRESSES: addresses
        } = await request("user/cache", "GET", null);

        if (cards.length > 0) {
            dispatch(updateCard(cards[0].ID));
        }

        if (addresses.length > 0) {
            dispatch(updateAddress(addresses[0]));
        }

        orders.reverse();

        dispatch(updateAccountLegacy({account, cards, addresses, giftCards, orders}));
        dispatch(updateCheckoutPhone(account.PHONE))

        if (account.FULL_NAME.trim().length > 0) {
            dispatch(updateCheckoutName(account.FULL_NAME));
        }
    }
}

export function logout() {
    return async (dispatch, getState) => {
        if (!window.confirm("Are you sure you want to logout?")) {
            return;
        }

        request("/logout", "POST", null).then(() => {
            console.log("Logout");
        }).catch(() => {
            console.log("Could not logout")
        }).finally(() => {
            dispatch(updateAccountLegacy({account: null, cards: [], addresses: [], giftCards: [], orders: []}));
            dispatch(updateCheckoutName(null));
            dispatch(updateCheckoutPhone(null));

            localStorage.removeItem("TOKEN");
        });
    };
}

const RESET_USER_FORM = 'RESET_USER_FORM';

export function resetUserForm(credentials) {
    return {
        type: RESET_USER_FORM,
        payload: credentials
    }
}

export const userReducer = (state = defaultState, action) => {
    let {type, payload} = action;

    switch (type) {
        default:
            return state;
        case UPDATE_ACCOUNT:
            return {...state, account: payload};
        case UPDATE_ADDRESSES:
            return {...state, addresses: payload};
        case UPDATE_CARDS:
            return {...state, cards: payload};
        case UPDATE_LOADING:
            return {...state, loading: payload};
        case UPDATE_TOKEN:
            return {...state, token: payload, loading: false};
        case COMPLETE_LOGIN:
            return {...state, token: null, loading: false, account: payload};
        case RESET_USER_FORM:
            return {...state, token: null, loading: false};
        case UPDATE_PHONES:
            return {...state, phones: payload};
        case UPDATE_ACCOUNT_LEGACY:
            return {...state, ...payload};
    }
}
