import React, {Component} from "react"
import {SidebarTop, SidebarWrapper, SidebarMenu, LogoutButton, SidebarBottom} from "./sidebar.style";
import {PROFILE_SIDEBAR_BOTTOM_MENU, PROFILE_SIDEBAR_TOP_MENU} from "../../../settings/navigation";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import Actions from "../../../redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}


class Sidebar extends Component {
    render() {
        return (
            <>
                <SidebarWrapper>
                    <SidebarTop>
                        {PROFILE_SIDEBAR_TOP_MENU.map((item, index) => (
                            <SidebarMenu href={item.href} key={index} intlId={item.id}/>
                        ))}
                    </SidebarTop>

                    <SidebarBottom>
                        {PROFILE_SIDEBAR_BOTTOM_MENU.map((item, index) => (
                            <SidebarMenu href={item.href} key={index} intlId={item.id}/>
                        ))}

                        <LogoutButton type="button" onClick={() => this.props.logout()}>
                            <FormattedMessage id="nav.logout" defaultMessage="Logout"/>
                        </LogoutButton>
                    </SidebarBottom>
                </SidebarWrapper>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);