import React, {Component} from "react"
import {isMobile} from "react-device-detect"
import SpringModal from "../spring-modal/spring-modal";
import CenterModal from "./center-modal";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {modal: state.modal}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ModalContainer extends Component {
    render() {
        let {modals} = this.props.modal;
        let {children} = this.props;

        return (
            <React.Fragment>
                {children}

                {Object.keys(modals).map((currentKey) => {
                    const { component: Component2, isOpen, onClose } = modals[currentKey];

                    return window.matchMedia("(max-width: 767px)").matches ? (
                        <SpringModal
                            key={currentKey}
                            children={<Component2 />}
                            isOpen={isOpen}
                            onRequestClose={() => this.props.hideModal(currentKey, onClose)}
                        />
                    ) : (
                        <CenterModal
                            key={currentKey}
                            children={<Component2 />}
                            isOpen={isOpen}
                            onRequestClose={() => this.props.hideModal(currentKey, onClose)}
                        />
                    );
                })}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);