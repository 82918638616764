import React, {Component} from "react"
import {
    Button,
    Container,
    Heading,
    LinkButton,
    Offer,
    SubHeading,
    Wrapper,
} from '../authentication-form.style';
import {FormattedMessage, injectIntl} from "react-intl";
import Input from "../../../components/forms/input";
import Actions from "../../../redux/index";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MaskedInput from 'react-text-mask';

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class LoginForm extends Component {
    state = {phone: ""};

    toggleSignUpForm() {
        let {setParent} = this.props;

        setParent({currentForm: "signUp"})
    }

    async loginCallback(e) {
        let {setParent, parentState} = this.props;
        let {phone} = this.state;

        e.preventDefault();
        if (parentState.loading) {
            return
        }

        setParent({loading: true});

        this.props.submitLoginPhone("1" + phone.replaceAll("-", "")).then((token) => {
            setParent({currentForm: "token", loading: false, token});
        }).catch(() => {
            console.log("SHOW ERROR");
        });
    }

    render() {
        let {phone} = this.state;
        let {loading} = this.props.parentState;

        return (
            <Wrapper>
                <Container>
                    <Heading>
                        <FormattedMessage id='welcomeBack' defaultMessage='Welcome Back'/>
                    </Heading>

                    <SubHeading>
                        <FormattedMessage
                            id='loginText'
                            defaultMessage='Login by entering your phone number'
                        />
                    </SubHeading>

                    <form onSubmit={this.loginCallback.bind(this)}>
                        <MaskedInput
                            mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            placeholder="312-465-7984"
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            value={phone}
                            type='phone'
                            onChange={(e) => this.setState({phone: e.target.value})}
                            required
                            height='48px'
                            backgroundColor='#F7F7F7'
                            mb='10px'
                            render={(ref, props) => <Input ref={ref} {...props} />}
                        />

                        <Button
                            loading={loading}
                            variant='primary'
                            size='big'
                            style={{width: '100%'}}
                            type='submit'
                        >
                            <FormattedMessage id='continueBtn' defaultMessage='Continue'/>
                        </Button>
                    </form>

                    <Offer style={{padding: '20px 0'}}>
                        <FormattedMessage
                            id='dontHaveAccount'
                            defaultMessage="Hello bruv"
                        />{' '}
                        <LinkButton onClick={this.toggleSignUpForm.bind(this)}>
                            <FormattedMessage id='signUpBtnText' defaultMessage='Sign Up'/>
                        </LinkButton>
                    </Offer>
                </Container>
            </Wrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginForm));