import styled from "styled-components";
import {COLORS} from "../../utils/util";

export const RemoveButton = styled.button`
    padding: 5px;
    border: 0;
    outline: 0;
    margin-left: 15px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25);
    transition: all 0.4s ease;
    background-color: transparent;

    &:hover {
        color: ${COLORS.RED};
        cursor: pointer;
    }
`
