import React, {Component} from "react"
import PropTypes from "prop-types"
import {ButtonGroupWrapper, ButtonNext, ButtonPrev} from "./carousel.style";
import {ArrowPrev} from "../../assets/icons/ArrowPrev";
import {ArrowNext} from "../../assets/icons/ArrowNext";
import MultiCarousel from 'react-multi-carousel';

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
    },
};

const PrevButton = ({onClick, children}) => {
    return (
        <ButtonPrev
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
            className="prevButton"
        >
            {children}
        </ButtonPrev>
    );
};
const NextButton = ({onClick, children}) => {
    return (
        <ButtonNext
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
            className="nextButton"
        >
            {children}
        </ButtonNext>
    );
};

const ButtonGroup = ({next, previous}) => {
    return (
        <ButtonGroupWrapper>
            <>
                <PrevButton onClick={() => previous()}>
                    <ArrowPrev/>
                </PrevButton>
                <NextButton onClick={() => next()}>
                    <ArrowNext/>
                </NextButton>
            </>

        </ButtonGroupWrapper>
    );
};

class Carousel extends Component {
    render() {
        let {
            data,
            component,
            autoPlay = false,
            infinite = true,
            itemClass,
        } = this.props;

        return (
            <div dir="ltr">
                <MultiCarousel
                    arrows={false}
                    responsive={responsive}
                    showDots={false}
                    slidesToSlide={1}
                    infinite={infinite}
                    containerClass="container-with-dots"
                    itemClass={itemClass}
                    autoPlay={autoPlay}
                    autoPlaySpeed={3000}
                    renderButtonGroupOutside={true}
                    additionalTransfrom={0}
                    customButtonGroup={<ButtonGroup/>}
                    {...this.props}
                    // use dir ltr when rtl true
                >
                    {data.map((item, index) => {
                        if (component) return component(item);
                        return (
                            <div style={{padding: '0 15px', overflow: 'hidden'}} key={index}>
                                <a
                                    href={item.link}
                                    style={{display: 'flex', cursor: 'pointer'}}
                                >
                                    <img
                                        key={item.id}
                                        src={item.imgSrc}
                                        alt={item.alt}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'block',
                                            position: 'relative',
                                        }}
                                    />
                                </a>
                            </div>
                        );
                    })}
                </MultiCarousel>
            </div>
        );
    }
}

Carousel.propTypes = {
    data: PropTypes.any.isRequired,
    component: PropTypes.any,
    autoPlay: PropTypes.bool,
    infinite: PropTypes.bool,
    isRtl: PropTypes.bool,
    customLeftArrow: PropTypes.element,
    customRightArrow: PropTypes.element,
    itemClass: PropTypes.string
}

export default Carousel