import React, {Component} from "react"
import {openModal} from '@redq/reuse-modal';
import {withRouter} from "react-router-dom"

import {
    DrawerBody,
    HamburgerIcon,
    DrawerContentWrapper,
    DrawerClose,
    DrawerProfile,
    LogoutView,
    LoginView,
    UserAvatar,
    UserDetails,
    DrawerMenu,
    DrawerMenuItem,
    UserOptionMenu,
} from './../header.style';
import AuthenticationForm from "../../../features/authentication-form/authentication-form";
import {FormattedMessage, injectIntl} from "react-intl";
import {MOBILE_DRAWER_MENU, PROFILE_PAGE} from "../../../settings/navigation";
import NavLink from "../../../components/nav-link/nav-link";
import Drawer from "../../../components/drawer/drawer";
import {CloseIcon} from "../../../assets/icons/CloseIcon";
import Scrollbar from "../../../components/scrollbar/scrollbar";
import Button from "../../../components/button/button";
import {bindActionCreators} from "redux";
import Actions from "../../../redux";
import {connect} from "react-redux";
import {formatPhone, getObjectImage} from "../../../utils/util";

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class MobileDrawer extends Component {
    state = {open: false}

    handleLogout() {
        this.props.logout();
    }

    signInOutForm() {
        this.setState({open: false}, () => {
            this.props.resetUserForm();

            openModal({
                show: true,
                overlayClassName: 'quick-view-overlay',
                closeOnClickOutside: true,
                component: AuthenticationForm,
                closeComponent: '',
                config: {
                    enableResizing: false,
                    disableDragging: true,
                    className: 'quick-view-modal',
                    width: 458,
                    height: 'auto',
                },
            });
        })
    }

    render() {
        let {open} = this.state;
        let {account} = this.props.user;

        let isAuthenticated = account !== null;

        return (
            <Drawer
                width='316px'
                drawerHandler={
                    <HamburgerIcon>
                        <span/>
                        <span/>
                        <span/>
                    </HamburgerIcon>
                }
                open={open}
                toggleHandler={() => this.setState({open: !open})}
                closeButton={
                    <DrawerClose>
                        <CloseIcon/>
                    </DrawerClose>
                }
            >
                <DrawerBody>
                    <Scrollbar className='drawer-scrollbar'>
                        <DrawerContentWrapper>
                            <DrawerProfile>
                                {isAuthenticated ? (
                                    <LoginView>
                                        <UserAvatar>
                                            <img src={getObjectImage(account, "LOGO", "appicon.png")} alt='user_avatar'/>
                                        </UserAvatar>
                                        <UserDetails>
                                            <h3>{account.FULL_NAME}</h3>
                                            <span>{formatPhone(account.PHONE)}</span>
                                        </UserDetails>
                                    </LoginView>
                                ) : (
                                    <LogoutView>
                                        <Button variant='primary' onClick={this.signInOutForm.bind(this)}>
                                            <FormattedMessage
                                                id='mobileSignInButtonText'
                                                defaultMessage='join'
                                            />
                                        </Button>
                                    </LogoutView>
                                )}
                            </DrawerProfile>

                            <DrawerMenu>
                                {MOBILE_DRAWER_MENU.map((item) => (
                                    <DrawerMenuItem key={item.id}>
                                        <NavLink
                                            onClick={() => this.setState({open: !open})}
                                            href={item.href}
                                            label={item.defaultMessage}
                                            intlId={item.id}
                                            className='drawer_menu_item'
                                        />
                                    </DrawerMenuItem>
                                ))}
                            </DrawerMenu>

                            {isAuthenticated && (
                                <UserOptionMenu>
                                    <DrawerMenuItem>
                                        <NavLink
                                            href={PROFILE_PAGE}
                                            label='Your Account Settings'
                                            className='drawer_menu_item'
                                            intlId='navlinkAccountSettings'
                                        />
                                    </DrawerMenuItem>
                                    <DrawerMenuItem>
                                        <div onClick={this.handleLogout.bind(this)} className='drawer_menu_item'>
                                            <span className='logoutBtn'>
                                              <FormattedMessage
                                                  id='navlinkLogout'
                                                  defaultMessage='Logout'
                                              />
                                            </span>
                                        </div>
                                    </DrawerMenuItem>
                                </UserOptionMenu>
                            )}
                        </DrawerContentWrapper>
                    </Scrollbar>
                </DrawerBody>
            </Drawer>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileDrawer));