import React, {Component} from "react"
import LoginForm from "./forms/login-form";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import TokenForm from "./forms/token-form";
import SignupForm from "./forms/signup-form";

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class AuthenticationForm extends Component {
    state = {currentForm: "signIn", loading: false, token: null};

    componentDidMount() {
        let {isJoin} = this.props;

        if (!isJoin) {
            this.setState({currentForm: "signUp"});
        }

        document.body.style.overflowY = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflowY = "auto";
    }

    render() {
        let {currentForm} = this.state;
        let {token} = this.props.user;

        if (token !== null) {
            return <TokenForm parentState={this.state} setParent={(state) => this.setState(state)} />
        }

        switch (currentForm) {
            default:
            case "signIn":
                return <LoginForm parentState={this.state} setParent={(state) => this.setState(state)} />
            case "signUp":
                return <SignupForm parentState={this.state} setParent={(state) => this.setState(state)} />
            case "token":
                return <TokenForm parentState={this.state} setParent={(state) => this.setState(state)} />
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationForm);