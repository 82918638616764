import React, {Component} from "react"
import {RightMenuBox} from './right-menu.style';
import {openModal} from '@redq/reuse-modal';
import PropTypes from "prop-types"
import {HELP_MENU_ITEM, OFFER_MENU_ITEM} from "../../../../settings/navigation";
import AuthMenu from "../auth-menu";
import AuthenticationForm from "../../../../features/authentication-form/authentication-form";
import {HelpIcon} from "../../../../assets/icons/HelpIcon";
import NavLink from "../../../../components/nav-link/nav-link";
import {bindActionCreators} from "redux";
import Actions from "../../../../redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getObjectImage} from "../../../../utils/util";

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class RightMenu extends Component {
    handleJoin(isJoin) {
        this.props.resetUserForm();

        openModal({
            show: true,
            overlayClassName: 'quick-view-overlay',
            closeOnClickOutside: true,
            component: AuthenticationForm,
            closeComponent: '',
            componentProps: {isJoin},
            config: {
                enableResizing: false,
                disableDragging: true,
                className: 'quick-view-modal',
                width: 400,
                height: 'auto',
            },
        });
    }

    handleLogout() {
        this.props.logout();
    }

    render() {
        let {account} = this.props.user;

        return (
            <RightMenuBox>
                <NavLink
                    className="menu-item"
                    href={HELP_MENU_ITEM.href}
                    label={HELP_MENU_ITEM.defaultMessage}
                    intlId={HELP_MENU_ITEM.id}
                    iconClass="menu-icon"
                    icon={<HelpIcon />}
                />

                {account === null ? (
                    <NavLink
                        className="menu-item"
                        onClick={this.handleJoin.bind(this, true)}
                        label="Login"
                        intlId="__TODO__"
                    />
                ) : <div/>}

                <AuthMenu
                    avatar={account === null ? null : getObjectImage(account, "LOGO", "appicon.png")}
                    onJoin={this.handleJoin.bind(this, false)}
                    onLogout={this.handleLogout.bind(this)}
                    isAuthenticated={account !== null}
                />
            </RightMenuBox>
        )
    }
}

RightMenu.propTypes = {
    avatar: PropTypes.string,
    isAuthenticated: PropTypes.bool,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightMenu));