import {request} from "../utils/request";
import {getSearchParameters} from "../utils/router-parser";
import {loadCart} from "./cart";

const defaultState = {
    location: null, locations: [], categories: [], products: [], promotions: [],
    loading: true, loadingProducts: false,

    activeCategory: null, activeSearch: null
};

const UPDATE_LOCATION = 'UPDATE_LOCATION';
const UPDATE_LEGACY = 'UPDATE_LEGACY';
const UPDATE_ACTIVE_CATEGORY = 'UPDATE_ACTIVE_CATEGORY';
const UPDATE_ACTIVE_SEARCH = 'UPDATE_ACTIVE_SEARCH';
const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';

export function updateLocation(credentials) {
    return {
        type: UPDATE_LOCATION,
        payload: credentials
    }
}

function updateLegacy(credentials) {
    return {
        type: UPDATE_LEGACY,
        payload: credentials
    }
}

export function updateActiveCategory(credentials) {
    return {
        type: UPDATE_ACTIVE_CATEGORY,
        payload: credentials
    }
}

export function updateActiveSearch(credentials) {
    return {
        type: UPDATE_ACTIVE_SEARCH,
        payload: credentials
    }
}

export function updateLocations(credentials) {
    return {
        type: UPDATE_LOCATIONS,
        payload: credentials
    }
}

export function loadShop() {
    return async (dispatch, getState) => {
        let path = new URL(window.location.href).pathname.split("/");
        let locations = await request("company/" + path[1], "GET", null);
        let location = {...locations[0], ICON: locations.length < 20 ? locations[0].ICON : null};

        let params = getSearchParameters();
        if (params.has("location")) {
            let foundLocation = locations.find((item) => item.ID === parseInt(params.get("location")));

            if (foundLocation) {
                location = foundLocation;
            }
        }

        dispatch(switchShop(location));
        dispatch(updateLocations(locations));
        dispatch(loadCart());
    }
}

export function switchShop(location) {
    return (dispatch, getState) => {
        let {CATEGORIES: categories, PROMOTIONS: promotions} = location;

        let products = categories.reduce((arr, item) => {
            return [...arr, ...item.PRODUCTS]
        }, []);

        dispatch(updateLegacy({categories, products, location, promotions}));
        dispatch(loadCart());
    }
}

export function getPromotions() {
    return (dispatch, getState) => {
        let {cart, shop} = getState();
        let {promotions: rawPromotions} = shop;
        let {coupon} = cart;

        let promotions = JSON.parse(JSON.stringify(rawPromotions));
        if (coupon !== null) {
            promotions.push(coupon.PROMOTION);
        }

        return promotions;
    }
}

export function findCategory(name) {
}

export const shopReducer = (state = defaultState, action) => {
    let {type, payload} = action;

    switch (type) {
        default:
            return state;
        case UPDATE_LOCATION:
            return {...state, location: payload};
        case UPDATE_LOCATIONS:
            return {...state, locations: payload};
        case UPDATE_ACTIVE_CATEGORY:
            return {...state, activeCategory: payload};
        case UPDATE_LEGACY:
            return {...state, ...payload, loading: false};
        case UPDATE_ACTIVE_SEARCH:
            return {...state, activeSearch: payload};
    }
}
