import React, {Component} from "react"
import PropTypes from "prop-types"

class Truncate extends Component {
    state = {isExpanded: false};

    render() {
        let {isExpanded} = this.state;
        let {children, more, less, character} = this.props;

        if (!children) return null;

        return (
            <>
                {(children && children.length < character) || isExpanded ? children : children.substring(0, character)}
                {children && children.length > character && !isExpanded && (
                    <>
                        <br/>
                        <span>
                            <a
                                href="#"
                                onClick={() => this.setState({isExpanded: !isExpanded})}
                                style={{color: '#009e7f', fontWeight: 700}}
                            >
                              {more}
                            </a>
                        </span>
                    </>
                )}
                {children && children.length > character && isExpanded && (
                    <>
                        <br/>
                        <span>
                            <a
                                href="#"
                                onClick={() => this.setState({isExpanded: !isExpanded})}
                                style={{color: '#009e7f', fontWeight: 700}}
                            >
                              {less}
                            </a>
                      </span>
                    </>
                )}
            </>
        );
    }
}

Truncate.propTypes = {
    more: PropTypes.string,
    less: PropTypes.string,
    character: PropTypes.number
}

export default Truncate;