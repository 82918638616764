const defaultState = {
    isSidebarSticky: false,
    isSticky: false,
    deviceType: {mobile: false, tablet: false, desktop: false}
};

const UPDATE_STICKY = 'UPDATE_STICKY';
export function updateSticky(credentials) {
    return {
        type: UPDATE_STICKY,
        payload: credentials
    }
}

export const generalReducer = (state = defaultState, action) => {
    let {type, payload} = action;

    switch (type) {
        default:
            return state;
        case UPDATE_STICKY:
            return {...state, isSticky: payload};
    }
}