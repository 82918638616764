import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from "prop-types"
import {Icon, IconWrapper, Text} from "./type-nav-card.style";

class TypeNavCard extends Component {
    render() {
        let {icon, intlId, defaultMessage, active, onClick,} = this.props;

        return (
            <IconWrapper active={active} {...this.props} onClick={onClick}>
                {/*<Icon>{icon}</Icon>*/}

                <Text>
                    <FormattedMessage id={intlId} defaultMessage={defaultMessage}/>
                </Text>
            </IconWrapper>
        )
    }
}

TypeNavCard.propTypes = {
    icon: PropTypes.any,
    intlId: PropTypes.string,
    defaultMessage: PropTypes.string,
    active: PropTypes.bool,
    onClick: PropTypes.func
}

export default TypeNavCard;