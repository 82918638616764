import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
export const FieldWrapper = styled.div`
  textarea {
    height: auto;
    min-height: 171px;
    padding-top: 15px;
    resize: none;
  }
`;

export const InputFeedback = styled.span`
  font-family: ${themeGet('fonts.body', 'sans-serif')};
  font-size: ${themeGet('fontSizes.sm', '13')}px;
  font-weight: ${themeGet('fontWeights.regular', '400')};
  color: ${themeGet('colors.secondary.hover', '#FF282F')};
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;