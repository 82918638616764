import React, {Component} from "react"
import PropTypes from "prop-types"
import {CouponBoxWrapper, Error} from "./coupon.style";
import Input from "../../components/forms/input";
import Button from "../../components/button/button";
import {FormattedMessage, injectIntl} from "react-intl";
import {request} from "../../utils/request";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Coupon extends Component {
    state = {error: null, code: null};

    handleApplyCoupon() {
        let {code} = this.state;
        let {location} = this.props.shop;

        console.log(code);
        request(`shop/${location.ID}/coupon`, "POST", {CODE: code}).then((code) => {
            this.props.updateCoupon(code);

            this.props.saveCart();
        }).catch(() => {
            this.setState({error: "Coupon not found"});
        });
    }

    render() {
        let {disabled, className, style, errorMsgFixed = false} = this.props;
        let {error, code} = this.state;
        let {intl} = this.props;

        return (
            <>
                <CouponBoxWrapper
                    onSubmit={(e) => {
                        e.preventDefault();

                        this.handleApplyCoupon()
                    }}
                    className={className ? className : 'boxedCoupon'}
                    style={style}
                >
                    <Input
                        onChange={(e) => this.setState({code: e.target.value})}
                        value={code}
                        placeholder={intl.formatMessage({
                            id: '__TODO__',
                            defaultMessage: 'Enter Coupon Here',
                        })} {...this.props}
                    />
                    <Button
                        type='button'
                        onClick={this.handleApplyCoupon.bind(this)}
                        disabled={disabled}
                        padding='0 30px'
                    >
                        <FormattedMessage id='voucherApply' defaultMessage='Apply'/>
                    </Button>
                </CouponBoxWrapper>
                {error && (
                    <Error errorMsgFixed={errorMsgFixed}>
                        <FormattedMessage id='couponError' defaultMessage={error}/>
                    </Error>
                )}
            </>
        );
    }
}

Coupon.propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    errorMsgFixed: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Coupon));