import {sanitizeName} from "./util";

/**
 *
 * @return {URLSearchParams}
 */
export function getSearchParameters() {
  return new URLSearchParams(window.location.search);

}

export function updateSearchParameters(params, clear) {
  let urlSearchParams = new URLSearchParams(window.location.search);

  if (clear) {
    urlSearchParams = new URLSearchParams();
  }

  for (let key of Object.keys(params)) {
    if (params[key] === null || params[key].length === 0 && urlSearchParams.has(key)) {
      urlSearchParams.delete(key);
    } else {
      urlSearchParams.set(key, encodeURIComponent(params[key]));
    }
  }

  const newURL = window.location.protocol + "//" + window.location.host + window.location.pathname +
    "?" + urlSearchParams.toString();

  window.history.replaceState({path: newURL}, '', newURL);
}

export function getCategory() {
  let params = getSearchParameters();

  let category = params.get("category");
  if (category !== null) {
    return decodeURIComponent(category);
  }

  return category;
}

export function getSearch() {
  let params = getSearchParameters();

  let search = params.get("search");
  if (search !== null) {
    return decodeURIComponent(search).toLowerCase();
  }

  return search;
}

export function isOnHomePage() {
  let {location} = this.props.shop;

  if (location === null) {
    return false;
  }

  return this.props.history.location.pathname === "/"
    || this.props.history.location.pathname === "/" + sanitizeName(location.COMPANY.NAME)
    || this.props.history.location.pathname === "/" + sanitizeName(location.COMPANY.NAME) + "/"
}

export function isOnOrderPage() {
  let {location} = this.props.shop;

  if (location === null) {
    return false;
  }

  return this.props.history.location.pathname === "/" + sanitizeName(location.COMPANY.NAME) + "/order"
}

export function isOnCheckoutPage() {
  let {location} = this.props.shop;

  if (location === null) {
    return false;
  }

  return this.props.history.location.pathname === "/" + sanitizeName(location.COMPANY.NAME) + "/checkout"
}

export function isOnProductPage() {
  let {location} = this.props.shop;

  if (location === null) {
    return false;
  }

  return this.props.history.location.pathname.toLowerCase().startsWith("/" + sanitizeName(location.COMPANY.NAME).toLowerCase() + "/product/")
}

export function pushLocation(url, newParams = {}) {
  let {location} = this.props.shop;

  let params = getSearchParameters();
  this.props.history.push("/" + sanitizeName(location.COMPANY.NAME) + "/" + url)

  updateSearchParameters({...params, ...newParams});
}
