import React, {Component} from "react"
import PropTypes from "prop-types"
import {Information, ItemBox, Name, RemoveButton, Total} from "./cart-item.style";
import {toDollars} from "../../utils/util";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import Counter from "../counter/counter";
import QuickViewMobile from "../../features/quick-view/quick-view-mobile";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {getProductPrice} from "drip-drop/src/product/ProductHelper";

function mapStateToProps(state) {
    return {modals: state.modals, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class TextCartItem extends Component {
    openEditProduct() {
        let {data, onCloseBtnClick} = this.props;
        let {products} = this.props.shop;

        let item = products.find((item) => item.ID === data.ID);

        if (onCloseBtnClick) {
            onCloseBtnClick();
        }

        this.props.showModal(data.UNIQUE + "_CART", () => (
            <QuickViewMobile cartItem={data} modalProps={item} onModalClose={() => {
                this.props.hideModal(data.UNIQUE + "_CART")
            }}/>
        ), {
            onClose: () => {
                this.props.hideModal(data.UNIQUE + "_CART")
            }
        })
    }

    render() {
        let {data, onDecrement, onIncrement, onRemove} = this.props;
        let {location} = this.props.shop;

        let {price} = getProductPrice(location, data, this.props.getPromotions());

        return (
            <ItemBox>
                <Counter
                    value={data.QUANTITY}
                    onDecrement={onDecrement}
                    onIncrement={onIncrement}
                    variant="lightVertical"
                />
                {/* <Image src={image} /> */}
                <Information onClick={this.openEditProduct.bind(this)}>
                    <Name>
                        {data.NAME}
                    </Name>
                </Information>
                <Total>
                    ${toDollars(price)}
                </Total>

                <RemoveButton onClick={onRemove}>
                    <CloseIcon/>
                </RemoveButton>
            </ItemBox>
        );

    }
}

TextCartItem.propTypes = {
    data: PropTypes.array.isRequired,
    onDecrement: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(TextCartItem);
