import React, {Component} from "react";
import ModalContainer from "../../components/modal/modal-container";
import {Modal} from "@redq/reuse-modal";
import ProductSingleWrapper, {
  ProductDetailsWrapper,
  ProductPreview,
  ProductInfo,
  ProductTitlePriceWrapper,
  ProductTitle,
  ProductPriceWrapper,
  ProductPrice,
  ProductDescription,
  ProductCartWrapper,
  ProductCartBtn,
  ProductMeta, MetaTitle,
  MetaSingle,
  SalePrice,
  ButtonText, CustomizationWrapper, NotesWrapper, UpdateWrapper, RelatedItems
} from "./product-page.style";
import MultiCarousel from "../../components/multi-carousel/multi-carousel";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getObjectImage, parseIdDict, toDollars} from "../../utils/util";
import Counter from "../../components/counter/counter";
import {MetaItem} from "../../features/quick-view/quick-view.style";
import {getProductPrice} from "drip-drop/src/product/ProductHelper";
import Truncate from "../../components/truncate/truncate";
import {CartIcon} from "../../assets/icons/CartIcon";
import {FormattedMessage} from "react-intl";
import Button from "../../components/button/button";
import Customization from "../../components/customization/customization";
import TextField from "../../components/forms/text-field/text-field";
import CartPopup from "../../features/cart-popup/cart-popup";
import Cart from "../../features/cart/cart";
import ProductGrid from "../../features/product-grid/product-grid";
import {request} from "../../utils/request";

function mapStateToProps(state) {
  return {shop: state.shop, cart: state.cart}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

class ProductPage extends Component {
  state = {customizations: [], recommendations: [], product: null, unique: null, notes: ""};

  componentDidMount() {
    this.loadProduct();
  }

  loadProduct() {
    const {products, location} = this.props.shop;
    const {PRODUCT} = this.props.match.params;

    if (location === null) {
      return setTimeout(() => {
        this.loadProduct();
      }, 500);
    }

    const productDict = parseIdDict(products);
    const product = productDict[parseInt(PRODUCT)];

    if (!product) {
      return console.log("WHAT", location);
    }

    let customizations = [], notes = "";
    let cartItem = this.props.history.location.state && this.props.history.location.state.item;
    if (!cartItem) {
      for (let item of product.CUSTOMIZATIONS) {
        if (item.DEFAULT_OPTION === null) {
          continue;
        }

        let option = item.OPTIONS.find((option) => option.ID === item.DEFAULT_OPTION);
        if (!option) {
          continue;
        }

        customizations.push({
          ID: item.ID,
          VALUE: option.ID,
          LABEL: item.NAME,
          DESCRIPTOR: option.NAME,
          PRICE: option.PRICE,
          QUANTITY: 1,
          TYPE: item.TYPE,
          MODIFIER: item.MODIFIER,

          label: option.NAME,
          value: option.ID,
        })
      }
    } else {
      customizations = cartItem.CUSTOMIZATIONS;
      notes = cartItem.NOTES;
    }

    request("recommendations", "POST", {LOCATION_ID: location.ID, ITEM_ID: product.ID}).then((recommendations) => {
      this.setState({recommendations: recommendations.filter((item) => productDict[item.ID]).map((item) => productDict[item.ID])});
    }).catch(() => {
      this.setState({recommendations: []});
    });

    this.setState({customizations, notes, product, unique: cartItem ? cartItem.UNIQUE : null})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {unique} = this.state;

    if (prevProps.match.params.PRODUCT !== this.props.match.params.PRODUCT) {
      this.loadProduct();

      window.scrollTo(0, 0);
    } else if (prevProps.history && this.props.history.location.state &&
      (
        (!prevProps.history.location.state.item && !!this.props.history.location.state.item) ||
        (unique !== this.props.history.location.state.item.UNIQUE)
      )
    ) {
      this.setState({unique: this.props.history.location.state.item.UNIQUE}, () => this.loadProduct());
    }
  }

  addToCart = () => {
    let {product, customizations, unique: cartUnique, notes} = this.state;

    let isValid = true;
    for (let item of this.myRefs) {
      if (item === null) {
        continue;
      }

      if (!item.isValid()) {
        isValid = false;
      }
    }

    if (!isValid) {
      return;
    }

    if (cartUnique) {
      return this.props.updateCartItem({
        ...this.props.getCartItem(cartUnique),
        CUSTOMIZATIONS: JSON.parse(JSON.stringify(customizations)),
        NOTES: notes
      });
    }

    let unique = Math.round(Math.random() * 1000000);
    this.props.addCartItem({
      UNIQUE: unique,
      LOCATION_ID: product.LOCATION_ID,
      ID: product.ID,
      NAME: product.NAME,
      LOGO: product.LOGO,
      PRICE: product.PRICE,
      QUANTITY: 1,
      CATEGORY_ID: product.CATEGORY_ID,
      CUSTOMIZATIONS: JSON.parse(JSON.stringify(customizations)),
      NOTES: notes
    });

    this.setState({unique})
  }

  renderRecommendations() {
    let {recommendations} = this.state;

    if (recommendations.length === 0) {
      return (
        <div/>
      );
    }

    return (
      <RelatedItems>
        <h2>
          <FormattedMessage
            id="__TODO__"
            defaultMessage="Recommended Items"
          />
        </h2>

        <ProductGrid
          {...this.props}
          products={recommendations}
          loadMore={false}
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
          }}
        />
      </RelatedItems>
    )
  }

  render() {
    const {customizations, unique, product, notes} = this.state;
    const {location} = this.props.shop;
    const {cartItem} = this.props;

    if (!product) {
      return (
        <div>
          Product not found
        </div>
      )
    }

    let {
      price: finalPrice, discount, base, promotions: productPromotions
    } = getProductPrice(location, JSON.parse(JSON.stringify({
      UNIQUE: Math.round(Math.random() * 1000000),
      LOCATION_ID: product.LOCATION_ID,
      CATEGORY_ID: product.CATEGORY_ID,
      ID: product.ID,
      NAME: product.NAME,
      LOGO: product.LOGO,
      PRICE: product.PRICE,
      QUANTITY: 1,
      CUSTOMIZATIONS: customizations
    })), this.props.getPromotions());

    this.myRefs = [];

    return (
      <>
        <ModalContainer>
          <Modal>
            <ProductSingleWrapper>
              <ProductDetailsWrapper className="product-card">
                <CartPopup  {...this.props} />

                <ProductPreview>
                  <MultiCarousel
                    items={[getObjectImage(product, "LOGO", "appicon.png")]}
                  />
                </ProductPreview>

                <ProductInfo>
                  <ProductTitlePriceWrapper>
                    <ProductTitle>{product.NAME}</ProductTitle>
                  </ProductTitlePriceWrapper>

                  <ProductPriceWrapper>
                    <ProductPrice>
                      ${toDollars(finalPrice)}
                    </ProductPrice>

                    {discount > 0 && (
                      <SalePrice>
                        ${toDollars(base)}
                      </SalePrice>
                    )}
                  </ProductPriceWrapper>

                  <ProductDescription>
                    <Truncate character={600}>{product.DESCRIPTION}</Truncate>
                  </ProductDescription>

                  <CustomizationWrapper>
                    {product.CUSTOMIZATIONS.map((item, i) => (
                      <Customization
                        parentCustomizations={customizations}
                        ref={(e) => this.myRefs.push(e)}
                        updateCustomizations={(customizations, callback) => this.setState({customizations}, callback)}
                        item={item} updatePrice={() => {
                      }} {...this.props} key={i}/>
                    ))}
                  </CustomizationWrapper>

                  <NotesWrapper>
                    <TextField
                      rows="3"
                      style={{minHeight: 30}}
                      id="notes"
                      as="textarea"
                      placeholder="Enter Special Order Instructions Here"
                      // error={touched["address-line1"] && errors["address-line1"]}
                      value={notes}
                      onChange={(e) => this.setState({notes: e.target.value})}
                    />
                  </NotesWrapper>

                  <ProductCartWrapper>
                    <ProductCartBtn>
                      {unique === null ? (
                        <Button
                          className="cart-button"
                          variant="primary"
                          size="big"
                          onClick={this.addToCart}
                        >
                          <CartIcon mr={2}/>

                          <ButtonText>
                            <FormattedMessage
                              id="addToCartButton"
                              defaultMessage="Add to cart"
                            />
                          </ButtonText>
                        </Button>
                      ) : (
                        <UpdateWrapper>
                          <Button
                            style={{marginRight: 12}}
                            variant="primary"
                            size="big"
                            onClick={this.addToCart}
                          >
                            <CartIcon mr={2}/>

                            <ButtonText>
                              <FormattedMessage
                                id="__TODO__"
                                defaultMessage="Upate Item"
                              />
                            </ButtonText>
                          </Button>

                          <Counter // In cart
                            style={{marginRight: 8, borderRadius: 6}}
                            value={this.props.getCartItem(unique || cartItem.UNIQUE).QUANTITY}
                            onDecrement={() => {
                              if (this.props.getCartItem(unique || cartItem.UNIQUE).QUANTITY === 1) {
                                this.setState({unique: null});
                              }

                              this.props.decrementCartItem.call(this, unique || cartItem.UNIQUE)
                            }}
                            onIncrement={this.props.incrementCartItem.bind(this, unique || cartItem.UNIQUE)}
                            className="card-counter"
                            variant="altHorizontal"
                          />
                        </UpdateWrapper>
                      )}
                    </ProductCartBtn>
                  </ProductCartWrapper>
                </ProductInfo>
              </ProductDetailsWrapper>

              {this.renderRecommendations()}
            </ProductSingleWrapper>
          </Modal>
        </ModalContainer>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductPage));
