import React, {Component} from "react"
import PropTypes from "prop-types"
import {
    Discount,
    ItemInfo,
    Items,
    ItemUnavailable,
    Multiplier,
    Price,
    Quantity
} from "../../pages/checkout/checkout.style";
import {toDollars} from "../../utils/util";
import QuickViewMobile from "../../features/quick-view/quick-view-mobile";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {getProductPrice, isProductUnavailable} from "drip-drop/src/product/ProductHelper";

function mapStateToProps(state) {
    return {shop: state.shop, checkout: state.checkout, cart: state.cart}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class CheckoutItem extends Component {
    render() {
        let {product} = this.props;
        let {schedule} = this.props.checkout;
        let {products, location} = this.props.shop;
        let item = products.find((item) => item.ID === product.ID);
        let unavailable = isProductUnavailable(item, schedule ? new Date(schedule) : new Date());
        let {price, discount, base} = getProductPrice(location, product, this.props.getPromotions());

        return (
            <div>
                <Items key={product.ID} onClick={() => {
                    this.props.showModal(product.UNIQUE + "_CART", () => (
                        <QuickViewMobile cartItem={product} modalProps={item} onModalClose={() => {
                            this.props.hideModal(product.UNIQUE + "_CART")
                        }}/>
                    ), {
                        onClose: () => {
                            this.props.hideModal(product.UNIQUE + "_CART")
                        }
                    })
                }}>
                    <Quantity>{product.QUANTITY}</Quantity>
                    <Multiplier>x</Multiplier>

                    <ItemInfo style={{flex: 1}}>
                        {product.NAME}
                    </ItemInfo>

                    <div>
                        {discount > 0 ? (
                            <Discount>
                                ${toDollars(base)}
                            </Discount>
                        ) : undefined}

                        <Price>
                            ${toDollars(price)}
                        </Price>
                    </div>
                </Items>

                <ItemUnavailable>
                    {unavailable === null ? "" : (item.NAME + " " + unavailable)}
                </ItemUnavailable>
            </div>
        )
    }
}

CheckoutItem.propTypes = {
    product: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItem);
