const defaultState = {
    modals: {}
};

const UPDATE_MODALS = 'UPDATE_MODALS';

export function updateModals(credentials) {
    return {
        type: UPDATE_MODALS,
        payload: credentials
    }
}

export function showModal(key, modal, options = {}) {
    return (dispatch, getState) => {
        let {modals} = getState().modal;

        dispatch(updateModals({...modals, [key]: {component: modal, isOpen: true, ...options}}))
    }
}

export function hideModal(key, onClose) {
    return (dispatch, getState) => {
        let {modals} = getState().modal;

        dispatch(updateModals({...modals, [key]: {...modals[key], isOpen: false}}))

        if (onClose) {
            onClose();
        }
    }
}

export const modalReducer = (state = defaultState, action) => {
    let {type, payload} = action;

    switch (type) {
        default:
            return state;
        case UPDATE_MODALS:
            return {...state, modals: payload};
    }
}