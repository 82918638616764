import React, {Component} from "react";
import {Products} from "drip-drop";
import Counter from "../counter/counter";
import Select from "react-select";
import {COLORS} from "../../utils/util";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {RemoveButton} from "./customization.style";

class Customization extends Component {
    state = {error: null};

    getCustomizations() {
        let {parentCustomizations, item} = this.props;

        return parentCustomizations.filter((_item) => _item.ID === item.ID);
    }

    changeItem(dict) {
        let {item, parentCustomizations, updateCustomizations} = this.props;
        let {error} = this.state;
        let value = dict.value;

        let option = item.OPTIONS.find((_item) => _item.ID === value);

        let payload = {
            ID: item.ID,
            VALUE: value,
            LABEL: item.NAME,
            DESCRIPTOR: option.NAME,
            PRICE: option.PRICE,
            QUANTITY: 1,
            TYPE: item.TYPE,
            MODIFIER: item.MODIFIER,

            label: option.NAME,
            value: option.ID,
        };

        if (item.TYPE === 1 || item.TYPE === 0 || item.TYPE === Products.Constants.CUSTOMIZATION_TYPES.VARIANT) {
            parentCustomizations = parentCustomizations.filter((custom) => custom.ID !== item.ID);
        } else {
            parentCustomizations = parentCustomizations.filter((custom) => custom.ID !== item.ID || custom.VALUE !== value)
        }

        updateCustomizations([...parentCustomizations, payload], () => {
            if (error !== null) {
                this.isValid();
            }
        });
    }

    isValid() {
        let customizations = this.getCustomizations();
        let {item} = this.props;

        if (customizations.length === 0 && item.REQUIRED === 1) {
            this.setState({error: "This field is required"});

            return false;
        }

        this.setState({error: null});
        return true;
    }

    onDecrement(item, index) {
        let {parentCustomizations, updateCustomizations} = this.props;

        if (item.QUANTITY === 1) {
            parentCustomizations.splice(index, 1);
        } else {
            parentCustomizations.splice(index, 1, {...item, QUANTITY: item.QUANTITY - 1});
        }

        updateCustomizations(parentCustomizations);
    }

    onIncrement(item, index) {
        let {parentCustomizations, updateCustomizations} = this.props;

        if (item.QUANTITY < 10) {
            parentCustomizations.splice(index, 1, {...item, QUANTITY: item.QUANTITY + 1});
        }

        updateCustomizations(parentCustomizations);
    }

    onRemoveCustomization(index) {
        let {parentCustomizations, updateCustomizations} = this.props;

        parentCustomizations.splice(index, 1);
        updateCustomizations(parentCustomizations);
    }

    render() {
        let {item, parentCustomizations} = this.props;

        let customizations = this.getCustomizations();

        let otherValues = [];
        for (let i = 0; i < parentCustomizations.length; i++) {
            let customization = parentCustomizations[i];

            if (customization.ID !== item.ID) {
                continue
            }

            let option = item.OPTIONS.find((option) => option.ID === customization.VALUE);
            if (!option) {
                continue;
            }

            otherValues.push(
                <div key={i} style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 5,
                    alignItems: "center"
                }}>
                    <div>
                        {option.NAME}
                    </div>

                    {
                        item.TYPE === 2 || item.TYPE === Products.Constants.CUSTOMIZATION_TYPES.MULTI_SELECT_FIXED ?
                            <RemoveButton onClick={this.onRemoveCustomization.bind(this, i)}>
                                <CloseIcon/>
                            </RemoveButton> :
                            <Counter onDecrement={this.onDecrement.bind(this, customization, i)}
                                     onIncrement={this.onIncrement.bind(this, customization, i)}
                                     value={customization.QUANTITY}/>
                    }
                </div>
            )
        }


        if (item.TYPE === 0 || item.TYPE === Products.Constants.CUSTOMIZATION_TYPES.VARIANT) {
            otherValues = <div/>
        }

        let value = null;
        if ((item.TYPE === 0 || item.TYPE === Products.Constants.CUSTOMIZATION_TYPES.VARIANT) && customizations.length > 0) {
            value = customizations[0];
        }

        return (
            <div style={{marginBottom: 10}}>
                <label>{item.NAME}</label>
                <div style={{color: "red"}}>{this.state.error}</div>
                <Select menuPortalTarget={document.body} value={value} options={item.OPTIONS.map((_item) => {
                    return {value: _item.ID, label: _item.NAME}
                })} menuPlacement={window.matchMedia("(max-width: 767px)").matches ? "top" : "bottom"}
                        onChange={this.changeItem.bind(this)} styles={{
                    menuPortal: base => ({...base, zIndex: 999999}),
                    container: (base, {isFocused}) => ({
                        ...base,
                        paddingTop: 3,
                        paddingBottom: 4,
                        borderColor: isFocused ? COLORS.DRIP_MAIN : "#e1eaea"
                    }),
                }}/>
                {otherValues}
            </div>
        )
    }
}

export default Customization;