import React, {Component} from "react";
import * as icons from './../../assets/icons/category-icons';
import styled from 'styled-components';
import css from '@styled-system/css';
import {Text} from "../text";
import {Box} from "../box";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const CardBox = styled.div((props) =>
  css({
    backgroundColor: ['gray.200', 'gray.200', '#fff'],
    textAlign: 'center',
    padding: '1rem 10px',
    borderRadius: [10, 10, 6],
    cursor: 'pointer',
    border: props.active ? '2px solid' : '2px solid',
    borderColor: props.active ? '#212121' : ['gray.200', 'gray.200', '#fff'],
  })
);

const Icon = ({name, style}) => {
  return <FontAwesomeIcon style={style} icon={["fal", name]}/> ;
};

class CardMenu extends Component {
  onClick(id) {
    let {active, onClick} = this.props;

    if (active === id) {
      return onClick(null);
    }

    onClick(id);
  }

  render() {
    let {data, active, style = {}} = this.props;

    return (
      <>
        {data.map(({id, title, icon, slug}) => (
          <CardBox
            key={id}
            onClick={this.onClick.bind(this, id)}
            active={id === active}
            role='button'
            style={style}
          >
            <Box
              padding='10px 15px'
              height={80}
              alignItems='center'
              justifyContent='center'
              display='flex'
            >
              <Icon name={icon} style={{height: 40, width: 'auto'}}/>
            </Box>

            <Text as='span' color='#212121' fontSize={14} fontWeight={600}>
              {title}
            </Text>
          </CardBox>
        ))}
      </>
    )
  }
}

export default CardMenu;
