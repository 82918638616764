import React, {Component} from "react"
import PropTypes from "prop-types"
import Button from "../../../components/button/button";
import {FormattedMessage} from "react-intl";
import Popover from "../../../components/popover/popover";
import AuthorizedMenu from "./authorized-menu";

class AuthMenu extends Component {
    render() {
        let {isAuthenticated, onJoin, onLogout, avatar} = this.props;

        return !isAuthenticated ? (
            <Button variant="primary" onClick={onJoin}>
                <FormattedMessage id="joinButton" defaultMessage="join"/>
            </Button>
        ) : (
            <Popover
                direction="right"
                className="user-pages-dropdown"
                handler={<img src={avatar} alt="user"/>}
                content={<AuthorizedMenu onLogout={onLogout}/>}
            />
        );
    }
}

AuthMenu.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    onJoin: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    avatar: PropTypes.string
}

export default AuthMenu;