import React, {Component} from "react"
import PropTypes from "prop-types"
import MasterCard from './image/master-card.png';
import Paypal from './image/paypal.png';
import Visa from './image/visa.png';
import Amex from './image/amex.png';
import Discover from './image/discover.png';
import {CardLogo, CardNumber, CardNumTitle, Name, PaymentCardWrapper} from "./payment-card.style";

class Card extends Component {
    render() {
        let {id, name, cardType, lastFourDigit, color} = this.props;

        const logo =
            (cardType === 'paypal' && Paypal) ||
            (cardType === 'MasterCard' && MasterCard) ||
            (cardType === 'American Express' && Amex) ||
            (cardType === 'Discover' && Discover) ||
            (cardType === 'Visa' && Visa);

        return (
            <PaymentCardWrapper className="payment-card" color={color}>
                <CardLogo>
                    <img src={logo} alt={`card-${id}`}/>
                </CardLogo>
                <CardNumTitle>Card Number</CardNumTitle>
                <CardNumber>
                    <span>****</span>
                    <span>****</span>
                    <span>****</span>
                    <span className="card-number">{lastFourDigit}</span>
                </CardNumber>
                <Name>{name}</Name>
            </PaymentCardWrapper>
        );
    }
}

Card.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cardType: PropTypes.string.isRequired,
    lastFourDigit: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
}

export default Card;