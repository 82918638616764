import React, {useState, useEffect, useRef, Component} from 'react';
import PopoverWrapper from './popover.style';
import PropTypes from "prop-types";

class Popover extends Component {
    state = {open: false};

    clickOutside(event) {
        let {open} = this.state;

        if (!open || !this.popoverRef || this.popoverRef.contains(event.target)) {
            return
        }

        this.setState({open: false});
    }

    componentDidMount() {
        window.addEventListener('click', this.handleDocumentClick.bind(this));

        document.addEventListener('mousedown', this.clickOutside.bind(this));
        document.addEventListener('touchstart', this.clickOutside.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleDocumentClick.bind(this));

        document.removeEventListener('mousedown', this.clickOutside.bind(this));
        document.removeEventListener('touchstart', this.clickOutside.bind(this));
    }

    handleDocumentClick(e) {
        let {open} = this.state;

        e.stopPropagation();

        if (open) {
            this.handleToggle(e);
        }
    };

    handleToggle(e) {
        let {open} = this.state;

        e.stopPropagation();
        this.setState({open: !open});
    }

    render() {
        let {className, content, direction, handler} = this.props;
        let addAllClasses = ['popover-wrapper'];
        let {open} = this.state;

        if (className) {
            addAllClasses.push(className);
        }

        if (direction) {
            addAllClasses.push(direction);
        }

        return (
            <PopoverWrapper ref={(e) => this.popoverRef = e} className={addAllClasses.join(' ')}>
                <div className="popover-handler" onClick={this.handleToggle.bind(this)}>
                    {handler}
                </div>

                {open && (
                    <div className="popover-content">
                        {content && (
                            <div className="inner-wrap" onClick={this.handleToggle.bind(this)}>
                                {content}
                            </div>
                        )}
                    </div>
                )}
            </PopoverWrapper>
        );
    }
}

Popover.propTypes = {
    className: PropTypes.string,
    handler: PropTypes.node,
    content: PropTypes.node,
    direction: PropTypes.string
}

export default Popover;
