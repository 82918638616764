import React, {Component} from "react"
import PropTypes from "prop-types"
import {FieldWrapper, InputFeedback} from "./text-field.style";
import Input from "../input";

class TextField extends Component {
    render() {
        let {type, id, label, error, value, onChange, className, innerRef} = this.props;

        return (
            <FieldWrapper className={className}>
                {label && (
                    <label className='label' htmlFor={id}>
                        {label}
                    </label>
                )}

                <Input id={id} type={type} value={value} onChange={onChange} ref={innerRef} {...this.props} />
                {error && <InputFeedback>{error}</InputFeedback>}
            </FieldWrapper>
        );
    }
}

TextField.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.number,
    className: PropTypes.string
}

export default TextField;