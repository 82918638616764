import React, {Component} from "react"
import PropTypes from "prop-types"

import {
    FoodCardWrapper,
    FoodImageWrapper,
    ProductInfo,
    Category,
    Duration,
    ProductMeta,
    DeliveryOpt,
    DiscountPercent,
} from "./location-card.style"
import Image from "../image/image";
import {getObjectImage, sanitizeName} from "../../utils/util";
import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import {getSearchParameters, updateSearchParameters} from "../../utils/router-parser";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}


class LocationCard extends Component {
    onClick() {
        let {data} = this.props;
        let {location} = this.props.shop;

        if (location.ID !== data.ID) {
            this.props.switchShop(data);
        }

        window.scrollTo(0, 0);
        this.props.history.push("/" + sanitizeName(data.COMPANY.NAME) + "/order");
        updateSearchParameters({location: data.ID});
    }

    render() {
        let {data} = this.props;
        let {NAME: name} = data;

        return (
            <FoodCardWrapper onClick={this.onClick.bind(this)} className="product-card">
                <FoodImageWrapper>
                    <Image
                        url={getObjectImage(data, "LOGO", "appicon.png")}
                        className="product-image"
                        style={{position: 'relative'}}
                        alt={name}
                    />
                </FoodImageWrapper>
                <ProductInfo
                    style={{display: 'flex', flexDirection: 'column', height: '100%'}}
                >
                    <h3 className="product-title">{name}</h3>
                    <Category style={{marginBottom: 20, display: 'inline-block'}}>
                        Coffee
                    </Category>
                    {/*<ProductMeta style={{marginTop: 'auto'}}>*/}
                    {/*    <DeliveryOpt>*/}
                    {/*        $5{' '}*/}
                    {/*        <FormattedMessage id="deliveryText" defaultMessage="Delivery"/>*/}
                    {/*    </DeliveryOpt>*/}

                    {/*    <Duration>{5}</Duration>*/}
                    {/*</ProductMeta>*/}
                </ProductInfo>
            </FoodCardWrapper>
        )
    }
}

LocationCard.propTypes = {
    data: PropTypes.object.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationCard));
