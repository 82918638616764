import React, {Component} from "react"
import PropTypes from "prop-types"
import {DeleteButton, Wrapper} from "./payment-card.style";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import Card from "./card";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {request} from "../../utils/request";

function mapStateToProps(state) {
    return {checkout: state.checkout, user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class PaymentCard extends Component {
    async handleDelete() {
        let {card} = this.props;
        let {card: checkoutCard} = this.props.checkout;
        let {account} = this.props.user;
        let {ID: id, LAST_FOUR: last4} = card;

        if (!window.confirm("Are you sure you want to delete this card that ends in " + last4)) {
            return
        }

        if (account !== null) {
            await request("cards/" + id, "DELETE", {});
        }

        if (checkoutCard === id) {
            this.props.updateCard(null);
        }

        this.props.removeCard(card);
    }

    render() {
        let {className, card} = this.props;
        let {BRAND: type, LAST_FOUR: lastFour, ID: id} = card;
        let {card: checkoutCard} = this.props.checkout;

        return (
            <Wrapper
                htmlFor={`payment-card-${id}`}
                className={`payment-card-radio ${className ? className : ''}`}
            >
                <input
                    type='radio'
                    id={`payment-card-${id}`}
                    value={`payment-card-${id}`}
                    onChange={() => this.props.updateCard(id)}
                    checked={checkoutCard === id}
                />

                <Card
                    id={`card-${2}`}
                    cardType={type}
                    lastFourDigit={lastFour}
                    color={"rgb(228, 244, 252)"}
                />

                <DeleteButton
                    onClick={this.handleDelete.bind(this)}
                    className='card-remove-btn'
                >
                    <CloseIcon/>
                </DeleteButton>
            </Wrapper>
        )
    }
}

PaymentCard.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCard);