import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useMeasure, usePrevious} from "../../utils/resizer";
import {useSpring, animated} from "react-spring";
import {Content, Frame, Header, IconWrapper, Title} from "./tree-menu.style";
import Button from "../button/button";
import {ArrowNext} from "../../assets/icons/ArrowNext";
import * as Icons from './../../assets/icons/category-icons';

// Tree.propTypes = {
//     children: PropTypes.node.isRequired,
//     name: PropTypes.string.isRequired,
//     icon: PropTypes.string.isRequired,
//     onClick: PropTypes.func.isRequired,
//     dropdown: PropTypes.bool,
//     onToggleBtnClick: PropTypes.func,
//     depth: PropTypes.number,
//     defaultOpen: PropTypes.bool
// }

export const Tree = React.memo(
    ({
         children,
         name,
         icon,
         // isOpen,
         onClick,
         dropdown,
         onToggleBtnClick,
         depth,
         defaultOpen = false,
     }) => {
        const [isOpen, setOpen] = useState(defaultOpen);
        useEffect(() => {
            setOpen(defaultOpen);
        }, [defaultOpen]);
        const previous = usePrevious(isOpen);
        const [bind, {height: viewHeight}] = useMeasure();
        const {height, opacity, transform} = useSpring ({
            from: {height: 0, opacity: 0, transform: 'translate3d(20px,0,0)'},
            to: {
                height: isOpen ? viewHeight : 0,
                opacity: isOpen ? 1 : 0,
                transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
            },
        });
        // const Icon = icon ? Icons[icon] : depth === 'child' ? Icons['Minus'] : null;
        const Icon = icon ? Icons[icon] : null;
        return (
            <Frame depth={depth} onClick={onClick}>
                <Header open={isOpen} depth={depth} className={depth}>
                    {Icon !== null && (
                        <IconWrapper depth={depth}>
                            <Icon/>
                        </IconWrapper>
                    )}
                    <Title onClick={onClick}>{name}</Title>

                    {dropdown === true && (
                        <Button
                            onClick={() => setOpen(!isOpen)}
                            variant="text"
                            className="toggleButton"
                        >
                            <ArrowNext width="16px"/>
                        </Button>
                    )}
                </Header>
                <Content
                    style={{
                        opacity,
                        height: isOpen && previous === isOpen ? 'auto' : height,
                    }}
                >
                    <animated.div style={{transform}} {...bind} children={children}/>
                </Content>
            </Frame>
        );
    }
);