import React, {Component} from "react"
import PropTypes from "prop-types"
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {SidebarLoader, SidebarMobileLoader} from "../../components/placeholder/placeholder";
import {CategoryWrapper, PopoverWrapper, RequestMedicine, SidebarWrapper, TreeWrapper} from "./sidebar.style";
import CategoryWalker from "../../components/category-walker/category-walker";
import {Link, withRouter} from "react-router-dom";
import {REQUEST_MEDICINE_MENU_ITEM} from "../../settings/navigation";
import {FormattedMessage} from "react-intl";
import TreeMenu from "../../components/tree-menu/tree-menu";
import {getSearchParameters, updateSearchParameters} from "../../utils/router-parser";
import Sticky from 'react-stickynode';
import Scrollbar from "../../components/scrollbar/scrollbar";
import {isMobile, isTablet} from 'react-device-detect';

function mapStateToProps(state) {
    return {general: state.general, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Sidebar extends Component {
    onCategoryClick(category) {
        if (category.ID === -1) {
            updateSearchParameters({category: null});
        } else {
            updateSearchParameters({category: category.NAME});
        }

        this.props.updateActiveCategory(category.NAME);

        return true;
    }

    render() {
        let {type} = this.props;
        let {categories, loading} = this.props.shop;
        let {isSidebarSticky} = this.props.general;
        let params = getSearchParameters();

        let category = params.get("category") || "All";
        if (category !== null) {
            category = decodeURIComponent(category);
        }

        if (!categories || loading) {
            if (isMobile || isTablet) {
                return <SidebarMobileLoader/>;
            }

            return <SidebarLoader/>;
        }

        let categoriesWithAll = [{ID: -1, NAME: "All", ICON: null}, ...categories];

        return (
            <CategoryWrapper>
                <PopoverWrapper>
                    <CategoryWalker ref={(e) => this.walkerRef = e}>
                        {type === 'medicine' && (
                            <Link to={REQUEST_MEDICINE_MENU_ITEM.href}>
                                <RequestMedicine>
                                    <FormattedMessage
                                        id={REQUEST_MEDICINE_MENU_ITEM.id}
                                        defaultMessage={REQUEST_MEDICINE_MENU_ITEM.defaultMessage}
                                    />
                                </RequestMedicine>
                            </Link>
                        )}
                        <TreeMenu
                            data={categoriesWithAll}
                            onClick={(item) => {
                                this.walkerRef.close();

                                this.onCategoryClick(item)
                            }}
                            active={category}
                        />
                    </CategoryWalker>
                </PopoverWrapper>

                <SidebarWrapper style={{paddingTop: 45}}>
                    <Sticky enabled={isSidebarSticky} top={110}>
                        {type === 'medicine' && (
                            <Link to={REQUEST_MEDICINE_MENU_ITEM.href}>
                                <RequestMedicine>
                                    <FormattedMessage
                                        id={REQUEST_MEDICINE_MENU_ITEM.id}
                                        defaultMessage={REQUEST_MEDICINE_MENU_ITEM.defaultMessage}
                                    />
                                </RequestMedicine>
                            </Link>
                        )}

                        <Scrollbar className='sidebar-scrollbar'>
                            <TreeWrapper>
                                <TreeMenu
                                    data={categoriesWithAll}
                                    onClick={this.onCategoryClick.bind(this)}
                                    active={category}
                                />
                            </TreeWrapper>
                        </Scrollbar>
                    </Sticky>
                </SidebarWrapper>
            </CategoryWrapper>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
