import React, {Component} from "react"
import {
    Button,
    Container,
    Error,
    Heading,
    InputFeedback,
    LinkButton,
    Offer,
    SubHeading,
    Wrapper,
} from '../authentication-form.style';
import {FormattedMessage, injectIntl} from "react-intl";
import Input from "../../../components/forms/input";
import Actions from "../../../redux/index";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MaskedInput from 'react-text-mask';

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class TokenForm extends Component {
    state = {phone: ""};

    async loginCallback(e) {
        let {phone} = this.state;
        let {parentState, setParent} = this.props;

        e.preventDefault();
        if (parentState.loading) {
            return
        }

        setParent({loading: true});
        this.props.submitLoginToken(phone, parentState.token).then(() => {
            setParent({loading: false})
        }).catch(() => {
            console.log("SHOW ERROR");
        });
    }

    render() {
        let {phone} = this.state;
        let {loading} = this.props.parentState;

        return (
            <Wrapper>
                <Container>
                    <Heading>
                        <FormattedMessage id='_LATER_' defaultMessage='Enter Token'/>
                    </Heading>

                    <SubHeading>
                        <FormattedMessage
                            id='_LATER_'
                            defaultMessage='Enter token sent to phone below'
                        />
                    </SubHeading>

                    <form onSubmit={this.loginCallback.bind(this)} style={{paddingBottom: 40}}>
                        <MaskedInput
                            mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                            placeholder="123456"
                            pattern="[0-9]{6}"
                            value={phone}
                            onChange={(e) => this.setState({phone: e.target.value})}
                            required
                            height='48px'
                            backgroundColor='#F7F7F7'
                            mb='10px'
                            render={(ref, props) => <Input ref={ref} {...props} />}
                        />

                        <Button
                            loading={loading}
                            variant='primary'
                            size='big'
                            style={{width: '100%'}}
                            type='submit'
                        >
                            <FormattedMessage id='continueBtn' defaultMessage='Continue'/>
                        </Button>
                    </form>
                </Container>
            </Wrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TokenForm));
