import React, {Component} from "react"
import PropTypes from "prop-types"
import {
    Address, CardWrapper,
    CostCalculation,
    DeliveryAddress, DeliveryInfo, Meta, OrderDetail, OrderListHeader, OrderMeta,
    OrderTableMobile,
    Price,
    PriceRow,
    ProgressWrapper, Status, TrackID
} from "./order-card-mobile.style";
import {
    ImageWrapper,
    ItemDetails,
    ItemName, ItemPrice, ItemSize,
    ItemWrapper,
    OrderTable
} from "../../features/order-details/order-details.style";
import Table from "rc-table";
import ProgressBox from "../progress-box/progress-box";
import Collapse, {Panel} from "rc-collapse";
import {
    getPaymentMethod,
    getProgressData,
    getProgressStep,
    orderTableColumns
} from "../../pages/receipt/receipt.constants";
import {getObjectImage, toDollars} from "../../utils/util";
import moment from "moment"
import {Products} from "drip-drop";

class OrderCardMobile extends Component {
    getItems(order) {
        return order.PRODUCTS.map((item) => {
            let customizations = Products.Helpers.getProductCustomizations(item);

            let values = Object.values(customizations).map((item, i) => (
                <>
                    <span style={{fontWeight: "bold"}}>
                        {item[0].KEY + ": "}
                    </span>

                    {item.map((option) => (
                        option.VALUE + (option.QUANTITY > 1 ? ` (${option.QUANTITY})` : "")
                    )).join(", ")}{i !== Object.values(customizations).length - 1 ? ", " : <div/>}
                </>
            ))

            return {
                title: item.NAME,
                image: getObjectImage(item, "LOGO", "appicon.png"),
                customizations: values,
                price: toDollars(item.PRICE),
                quantity: item.QUANTITY,
                total: toDollars(item.PRICE)
            }
        })
    }

    renderDiscount() {
        let {order} = this.props;

        if (order.DISCOUNTS === 0) {
            return <div/>
        }

        return (
            <PriceRow>
                Discount
                <Price>
                    ${3}
                </Price>
            </PriceRow>
        );
    }

    renderTip() {
        let {order} = this.props;

        if (order.TIP_AMOUNT === 0) {
            return <div/>
        }

        return (
            <PriceRow>
                Tip
                <Price>
                    ${toDollars(order.TIP_AMOUNT)}
                </Price>
            </PriceRow>
        );
    }

    render() {
        let {className, order: rawOrder, orders, onClick} = this.props;
        let items = this.getItems(rawOrder);

        let addAllClasses = ['accordion'];
        if (className) {
            addAllClasses.push(className);
        }

        return (
            <>
                <Collapse
                    activeKey={rawOrder.ID}
                    accordion={true}
                    className={addAllClasses.join(' ')}
                    defaultActiveKey="active"
                    expandIcon={() => {
                    }}
                >
                    {orders.map((order) => (
                        <Panel
                            header={
                                <CardWrapper onClick={() => onClick && onClick(order)}>
                                    <OrderListHeader>
                                        <TrackID>
                                            Order <span>#{order.ID}</span>
                                        </TrackID>
                                        <Status>{getProgressData(order)[getProgressStep(order)]}</Status>
                                    </OrderListHeader>

                                    <OrderMeta>
                                        <Meta>
                                            Order Date: <span>{moment(order.DATE_SENT).format("MMMM DD, YYYY")}</span>
                                        </Meta>
                                        {order.DATE_SCHEDULED !== null ? (
                                            <Meta>
                                                Scheduled
                                                Date: <span>{moment(order.DATE_SCHEDULED).format("h:mma MMMM DD, YYYY")}</span>
                                            </Meta>
                                        ) : <div/>}
                                        <Meta>
                                            Payment Method: <span>{getPaymentMethod(order)}</span>
                                        </Meta>
                                        <Meta className="price">
                                            Total Price:
                                            <span>
                                                ${toDollars(order.TOTAL + order.TIP_AMOUNT)}
                                            </span>
                                        </Meta>
                                    </OrderMeta>
                                </CardWrapper>
                            }
                            headerClass="accordion-title"
                            key={order.ID}
                        >
                            <OrderDetail>
                                <DeliveryInfo>
                                    {/*<DeliveryAddress>*/}
                                    {/*    <h3>Delivery Address</h3>*/}
                                    {/*    <Address>{123}</Address>*/}
                                    {/*</DeliveryAddress>*/}

                                    <CostCalculation>
                                        <PriceRow>
                                            Subtotal
                                            <Price>
                                                ${toDollars(order.SUBTOTAL)}
                                            </Price>
                                        </PriceRow>
                                        <PriceRow>
                                            Taxes
                                            <Price>
                                                ${toDollars(order.TAXES)}
                                            </Price>
                                        </PriceRow>
                                        {this.renderDiscount()}
                                        {this.renderTip()}
                                        <PriceRow className="grandTotal">
                                            Total
                                            <Price>
                                                ${toDollars(order.TOTAL + order.TIP_AMOUNT)}
                                            </Price>
                                        </PriceRow>
                                    </CostCalculation>
                                </DeliveryInfo>

                                <ProgressWrapper>
                                    <ProgressBox data={getProgressData(order)} status={getProgressStep(order) + 1}/>
                                </ProgressWrapper>

                                <OrderTableMobile>
                                    <Table
                                        columns={orderTableColumns}
                                        data={items}
                                        rowKey={(record) => record.ID}
                                        components={{
                                            table: OrderTable
                                        }}
                                        scroll={{x: 450}}
                                        // scroll={{ y: 250 }}
                                    />
                                </OrderTableMobile>
                            </OrderDetail>
                        </Panel>
                    ))}
                </Collapse>
            </>
        );
    }
}

OrderCardMobile.propTypes = {
    className: PropTypes.string
}

export default OrderCardMobile;
