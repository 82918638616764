function getURL() {
    if (window.location.href.indexOf('qe.order.drip.is') !== -1) {
        return 'https://qe.api.drip.is/'
    }

    if (window.location.href.indexOf('localhost') === -1) {
        return 'https://api.dripdrinks.co/'
    }

    return 'http://localhost:6969/api/'
}

export function getEnvironment() {
    if (window.location.href.indexOf('qe.order.drip.is') !== -1) {
        return 'qe'
    }

    if (window.location.href.indexOf('localhost') === -1) {
        return 'production'
    }

    return 'test'
}

export function getShortURL() {
    if (window.location.href.indexOf('qe.order.drip.is') !== -1) {
        return 'https://qe.api.drip.is/'
    }

    if (window.location.href.indexOf('localhost') === -1) {
        return 'https://api.dripdrinks.co/'
    }

    return 'http://localhost:6969/'
}

export function getStripeID() {
    if (window.location.href.indexOf('qe.order.drip.is') !== -1) {
        return 'pk_test_SJXSgpblYrYssIxYRBbWemYg'
    }

    if (window.location.href.indexOf('localhost') === -1) {
        return 'pk_live_OQCRXlIgLqB8vC3TQaKr7d6Y'
    }

    return 'pk_test_SJXSgpblYrYssIxYRBbWemYg'
}

export function request(endpoint, method, body, callback = function () {
}) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        if (typeof window.localStorage['TOKEN'] !== 'undefined') {
            headers['Authentication'] = window.localStorage['TOKEN']
        }

        if (body !== null) {
            fetch(getURL() + endpoint, {
                method: method,
                headers: headers,
                body: JSON.stringify(body),
                credentials: 'same-origin'
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['success']) {
                        callback(undefined, responseJson.data);
                        resolve(responseJson.data)
                    } else {
                        callback(responseJson, responseJson.code);
                        reject(responseJson)
                    }
                })
                .catch((error) => {
                    callback(error, endpoint);
                    reject(error)
                });
        } else {
            fetch(getURL() + endpoint, {
                method: method,
                headers: headers,
                credentials: 'same-origin'
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson['success']) {
                        callback(undefined, responseJson.data);
                        resolve(responseJson.data)
                    } else {
                        callback(responseJson, responseJson.code);
                        reject(responseJson)
                    }
                })
                .catch((error) => {
                    callback(JSON.stringify(error), endpoint);
                    reject(error)
                });
        }
    });
}

export function uploadImage(endpoint, image, callback, options) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
    };

    let data = new FormData();
    data.append('file', {uri: image.path, name: 'selfie.jpg', type: 'image/jpg'});

    const config = {
        method: 'POST',
        headers: headers,
        body: data,
    };

    fetch(getURL() + endpoint, config).then((response) => response.json())
        .then((responseJson) => {
            if (responseJson['success']) {
                callback(undefined, responseJson.data);
            } else {
                callback(responseJson, responseJson.code);
            }
        })
        .catch((error) => {
            callback(error, endpoint);
        });
}