import React, {Component} from 'react';
import {LogoBox, LogoImage} from './logo.style';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {getObjectImage, sanitizeName} from "../../utils/util";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {isOnCheckoutPage, isOnProductPage} from "../../utils/router-parser";

function mapStateToProps(state) {
    return {shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Logo extends Component {
    onLogoClick() {
        let {onClick} = this.props;
        let {location, locations} = this.props.shop;

        window.scrollTo(0, 0)
        if (isOnCheckoutPage.call(this) || isOnProductPage.call(this)) {
            return this.props.history.push("/" + sanitizeName(location.COMPANY.NAME) + "/order");
        }

        if (locations.length < 10) {
            return this.props.history.push("/" + sanitizeName(location.COMPANY.NAME));
        }

        this.props.history.push("/");

        if (onClick) {
            onClick();
        }
    }

    render() {
        let {alt} = this.props;
        let {location} = this.props.shop;

        return (
            <LogoBox onClick={this.onLogoClick.bind(this)}>
                <LogoImage src={getObjectImage(location, "ICON", "banner.png")} alt={alt}/>
            </LogoBox>
        );
    }
}

Logo.propTypes = {
    imageUrl: PropTypes.string,
    alt: PropTypes.string,
    onClick: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logo));
