import React, {Component} from "react"
import PropTypes from "prop-types"
import {
    Header,
    PaymentCardList,
    IconWrapper,
    SavedCard,
    OtherPayOption,
} from './payment-group.style';
import {FormattedMessage} from "react-intl";
import Button from "../button/button";
import {Plus} from "../../assets/icons/PlusMinus";
import PaymentCard from "../payment-card/payment-card";
import Carousel from "../carousel/carousel";

class PaymentGroup extends Component {
    handleAddCard() {

    }

    onDelete() {

    }

    render() {
        let {handleAddNewCard, items} = this.props;

        return (
            <>
                {/* {deviceType === 'desktop' && ( */}
                <Header>
                    {items.length !== 0 && (
                        <SavedCard>
                            <FormattedMessage id="savedCardsId" defaultMessage="Saved Cards"/>
                        </SavedCard>
                    )}

                    <Button
                        variant="text"
                        type="button"
                        onClick={handleAddNewCard}
                        className="addCard"
                    >
                        <IconWrapper>
                            <Plus width="10px"/>
                        </IconWrapper>
                        <FormattedMessage id="addCardBtn" defaultMessage="Add Card"/>
                    </Button>
                </Header>
                <PaymentCardList>
                    <Carousel
                        autoPlay={false}
                        infinite={false}
                        data={items}
                        component={(item) => (
                            <PaymentCard
                                key={item.ID} card={item}
                                onDelete={() => this.onDelete(item)}
                                {...item}
                            />
                        )}
                    />
                </PaymentCardList>

                {items.mobileWallet === true || items.cashOnDelivery === true ? (
                    <OtherPayOption>
                        {/* Mobile Wallet */}
                        {items.mobileWallet === true ? (
                            <label
                                htmlFor="mobile-wallet"
                                key="${1}-mobile-wa"
                                className="other-pay-radio"
                            >
                                <input
                                    type="radio"
                                    id="mobile-wallet"
                                    name={1}
                                    value="mobile-wallet"
                                    onChange={this.handleChange.bind(this)}
                                />
                                <span>Mobile Wallet</span>
                            </label>
                        ) : (
                            ''
                        )}

                        {/* Cash On Delivery */}
                        {items.cashOnDelivery === true ? (
                            <label
                                htmlFor="cash-on-delivery"
                                key="${1}-cash"
                                className="other-pay-radio cash-on-delivery"
                            >
                                <input
                                    type="radio"
                                    id="cash-on-delivery"
                                    name={1}
                                    value="cash-on-delivery"
                                    onChange={this.handleChange.bind(this)}
                                />
                                <span>Cash On Delivery</span>
                            </label>
                        ) : (
                            ''
                        )}
                    </OtherPayOption>
                ) : (
                    ''
                )}
            </>
        );
    }
}

PaymentGroup.propTypes = {
    handleAddNewCard: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired
}

export default PaymentGroup