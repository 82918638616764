import React, {Component} from "react"
import PropTypes from "prop-types"
import {withFormik, Form} from "formik"
import * as Yup from "yup";
import {FieldWrapper, Heading} from "./address-card.style";
import TextField from "../forms/text-field/text-field";
import Button from "../button/button";
import {FormattedMessage} from "react-intl";
import {closeModal} from '@redq/reuse-modal';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {request} from "../../utils/request";

function mapStateToProps(state) {
    return {checkout: state.checkout, user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const FormEnhancer = withFormik({
    mapPropsToValues: (props) => {
        return {
            address: null,
            id: props.item.id || null,
            "address-line1": props.item["address-line1"] || '',
            "address-line2": props.item["address-line2"] || null,
            "address-notes": props.item["address-notes"] || null,
        };
    },
    validationSchema: Yup.object().shape({
        "address-line1": Yup.string().required('Address is required'),
    }),
    onSubmit: (a, b) => {
        console.log(a, b)
    },
    handleSubmit: async (values, {status, resetForm, setErrors, setStatus, setSubmitting, setFieldError, props, ...other}, three) => {
        if (values.address === null) {
            return setFieldError("address-line1", "Please select dropdown from value after typing")
        }

        let {"address-notes": notes, "address-line2": line2} = values;
        let {street_number, route, locality, postal_code, administrative_area_level_1, latitude, longitude} = values.address;
        let addressPayload = {
            ID: null,
            LINE_ONE: (street_number ? street_number.short : "") + " " + route.long,
            LINE_TWO: line2,
            CITY: locality.long,
            ZIPCODE: postal_code.short,
            STATE: administrative_area_level_1.short,
            LATITUDE: latitude,
            LONGITUDE: longitude,
            NOTES: notes
        }

        let {account} = props.user;
        if (account !== null) {
            delete addressPayload.ID;

            addressPayload = await request("address", "POST", addressPayload)
        }

        props.addAddress(addressPayload);
        props.updateAddress(addressPayload.ID);

        closeModal();
    },
});

class AddressCard extends Component {
    state = {address: null}

    componentDidMount() {
        let {setFieldValue, setStatus} = this.props;

        var input = document.getElementById('address-line1');
        var options = {
            types: ['address']
        };

        geolocate();
        let autocomplete = new window.google.maps.places.Autocomplete(input, options);
        autocomplete.addListener('place_changed', async () => {
            let address = await fillInAddress();

            setFieldValue("address-line1", address.formatted);
            setFieldValue("address", address);
        });

        function geolocate() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    let geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    let circle = new window.google.maps.Circle(
                        {center: geolocation, radius: position.coords.accuracy});
                    autocomplete.setBounds(circle.getBounds());
                });
            }
        }

        async function fillInAddress() {
            let place = autocomplete.getPlace();

            if (!place.geometry) {
                return {};
            }

            let {lat: latitude, lng: longitude} = place.geometry.location;
            return place.address_components.reduce((dict, item, i) => {
                let {long_name, short_name, types} = item;

                for (let type of types) {
                    dict[type] = {short: short_name, long: long_name}
                }

                return dict;
            }, {
                latitude: await latitude(), longitude: await longitude(),
                formatted: place.formatted_address
            });
        }
    }

    render() {
        let {address, values, touched, errors, handleChange, handleBlur, handleSubmit} = this.props;

        return (
            <Form>
                <Heading>{address && address.ID ? "Edit Address" : "Add New Address"}</Heading>

                <FieldWrapper>
                    <TextField
                        rows="3"
                        style={{minHeight: 30}}
                        id="address-line1"
                        as="textarea"
                        placeholder="Enter Address"
                        error={touched["address-line1"] && errors["address-line1"]}
                        value={values["address-line1"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <TextField
                        id="address-line2"
                        type="text"
                        placeholder="Enter Apt, Suite, Other"
                        error={touched["address-line2"] && errors["address-line2"]}
                        value={values["address-line2"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <TextField
                        rows="3"
                        style={{minHeight: 30}}
                        id="address-notes"
                        as="textarea"
                        placeholder="Enter Delivery Notes"
                        error={touched["address-notes"] && errors["address-notes"]}
                        value={values["address-notes"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FieldWrapper>

                <Button
                    onClick={handleSubmit}
                    type="submit"
                    style={{width: '100%', height: '44px'}}
                >
                    <FormattedMessage id="savedAddressId" defaultMessage="Save Address"/>
                </Button>
            </Form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEnhancer(AddressCard));