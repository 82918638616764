const geolib = require('geolib');
const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October",
    "November", "December"];

export function getDistance(item, item2=null) {
    if (item2 === null) {
        item2 = {
            LATITUDE: this.props.user.location.latitude,
            LONGITUDE: this.props.user.location.longitude
        }
    }

    return geolib.getDistance({
        latitude: item.LATITUDE,
        longitude: item.LONGITUDE
    }, {
        latitude: item2.LATITUDE,
        longitude: item2.LONGITUDE
    });
}

export function formatDate(date) {
    if (typeof date.getMonth === "undefined") {
        date = new Date(date);
    }

    let str = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    if (date.getHours() >= 12) {
        str = "PM " + str
    } else {
        str = "AM " + str;
    }

    str = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + str;

    if (date.getHours() !== 0) {
        str = (date.getHours() > 12 ? date.getHours() % 12 : date.getHours()) + ":" + str;
    } else {
        str = "12:" + str;
    }

    return str;
}

/**
 * Exports only the date to (dd Month Year)
 */
export function formatOnlyDate(date) {
    console.dir(date);
    if (typeof date.getMonth === "undefined") {
        date = new Date(date);
    }

    return date.getDate() + " " + MONTHS[date.getMonth()] + " " + date.getFullYear();
}

let DEBUG = false;

export function log(message) {
    if (!DEBUG) {
        return;
    }

    alert(JSON.stringify(message));
}

export function getQueryParams() {
    document.location.search.split('+').join(' ');

    let params = {},
        tokens,
        re = /[?&]?([^=]+)=([^&]*)/g;

    while (tokens = re.exec(document.location.search)) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
}

export const COLORS = {
    DRIP_GOLD: "#b99438",
    SOON_ORANGE: "#FFAC5D",
    DRIP_MAIN: "#4ACE9C",
    CORK_BLUE: "#cea35b",
    ORANGE: "#D56D44",
    BACKGROUND: "#F6F6F6",
    RED: "#AA3939",
    DRIP_GRAY: "#242426",
    TEXT_BLACK: "#171717",
    TEXT_GRAY: "#ADADAD",
    TEXT_DARK_GRAY: "#808080",
    MONEY_GREEN: "#1C9115",
    DRIP_GREEN: "#4ACE9C",
    GRAYED_OUT: "#F4F4F4",
    BUTTON_DISABLED: "#C6C6C6",
    BORDER: "#E6E6E6",
    TEXT_LIGHT_GRAY: "#868686"
};

export function urlHelper(look) {
    return window.location.href.split(look)[1].split("").filter((item) => item === "/").map((_) => "../").join("")
}

const AWS_S3_URL = "https://drip-drinks.s3.amazonaws.com";

export function getObjectImage(obj, key, backup) {
    if (!obj || obj.hasOwnProperty(key) === false || obj[key] === null) {
        return getUrl(backup);
    }

    return getUrl(obj[key]);
}

export function getUrl(url) {
    if (url.startsWith("http")) {
        return url;
    }

    return AWS_S3_URL + "/" + url;
}

export function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

export function decimalToDollars(str) {
    if (!str) {
        return 0;
    }

    return Math.round(100 * parseFloat(str.replace(/[$,]/g, '')));
}

export function toDollars(cents) {
    let str = "" + Math.ceil(cents);

    switch (str.length) {
        default:
            return str.substring(0, str.length - 2) + "." + str.substring(str.length - 2, str.length);
        case 0:
            return "0.00";
        case 1:
            return "0.0" + str;
        case 2:
            return "0." + str;
    }
}

export function stripPhone(phone) {
    return phone.replace(/[^\d]/g, '')
}

export function isValidPhone(phone) {
    if (phone === null || phone.length === 0) {
        return false;
    }

    return /^[+]?(1-|1\s|1|\d{3}-|\d{3}\s|)?((\(\d{3}\))|\d{3})(-|\s)?(\d{3})(-|\s)?(\d{4})$/g.test(phone);
}

const DEFAULT_DELAY = 500;

export function asyncDelay(start, delay = DEFAULT_DELAY) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, Math.min(delay - (new Date().getTime() - start), delay));
    })
}

export async function asyncTimeout(timeout) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, timeout);
    })
}

export function formatPhone(str) {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return null;
}

export function sanitizeName(name) {
    return name.replaceAll(" ", "-");
}

export function parseIdDict(arr, key = "ID") {
    return arr.reduce((dict, item) => {
        dict[item[key]] = item;
        return dict;
    }, {});
}
