import React, {Component} from "react"
import ModalContainer from "../../components/modal/modal-container";
import {Modal} from "@redq/reuse-modal";
import {Heading, HelpPageContainer, HelpPageWrapper} from "./help.style";
import Accordion from "../../components/accordion/accordion";

const accordionData = [
    {
        id: 1,
        intlTitleId: 'faqNo1Title',
        intlDetailsId: 'faqNo1Desc',
        values: {number1: 7, number2: 2},
    }
];

class Help extends Component {
    render() {
        return (
            <ModalContainer>
                <Modal>
                    <HelpPageWrapper>
                        <HelpPageContainer>
                            <Heading>F.A.Q</Heading>
                            <Accordion items={accordionData}/>
                        </HelpPageContainer>
                    </HelpPageWrapper>
                </Modal>
            </ModalContainer>
        )
    }
}

export default Help;