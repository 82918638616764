import React, {Component} from "react"
import {CardHeader} from "../../components/card-header/card-header";
import {FormattedMessage} from "react-intl";
import PaymentGroup from "../../components/payment-group/payment-group";
import {openModal} from '@redq/reuse-modal';
import StripeForm from "./stripe/stripe-form";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Payment extends Component {
    handleOnDelete() {

    }

    handleModal() {
        openModal({
            show: true,
            config: {
                width: 360,
                height: 'auto',
                enableResizing: false,
                disableDragging: true,
                className: "add-address-modal stripe-modal",
            },
            closeOnClickOutside: true,
            component: StripeForm,
            componentProps: {item: {totalPrice: 1}, buttonText: "Add Card"},
        });
    }

    render() {
        let {increment} = this.props;
        let {cards} = this.props.user;

        return (
            <>
                <CardHeader increment={increment}>
                    <FormattedMessage
                        id="selectPaymentText"
                        defaultMessage="Select Payment Option"
                    />
                </CardHeader>

                <PaymentGroup
                    name="payment"
                    items={cards}
                    onDelete={(item) => this.handleOnDelete(item)}
                    onChange={(item) => {

                    }}
                    handleAddNewCard={this.handleModal.bind(this)}
                />
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);