import React, {Component} from "react"
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {CardHeader} from "../../components/card-header/card-header";
import {FormattedMessage} from "react-intl";
import RadioGroup from "../../components/radio-group/radio-group";
import RadioCard from "../../components/radio-card/radio-card";
import {openModal} from "@redq/reuse-modal";
import NameCard from "../../components/name-card/name-card";
import AuthenticationForm from "../../features/authentication-form/authentication-form";
import TipCard from "../../components/tip-card/tip-card";
import {decimalToDollars, toDollars} from "../../utils/util";
import {ButtonGroup} from "../../components/button-group/button-group";

function mapStateToProps(state) {
    return {checkout: state.checkout, user: state.user, shop: state.shop};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Tip extends Component {
    onModalOpen(props) {
        openModal({
            show: true,
            config: {
                width: 360,
                height: 'auto',
                enableResizing: false,
                disableDragging: true,
                className: "add-address-modal",
            },
            closeOnClickOutside: true,
            component: TipCard,
            componentProps: {item: props},
        });
    }

    render() {
        let {increment, total} = this.props;
        let {account} = this.props.user;
        let {location} = this.props.shop;
        let {name, tip} = this.props.checkout;

        let names = [{
            id: null,
            title: "No Tip",
            time_slot: "Pass on giving a tip this time"
        }, {
            id: -1,
            title: "Custom Tip Amount",
            time_slot: "Click to set custom tip amount"
        }];

        let tipOptions = JSON.parse(location.SETTINGS.TIP_OPTIONS) || ["10%", "15%", "20%"];
        tipOptions.slice().reverse().forEach((item) => {
            let percent = item[item.length - 1] === "%";
            let str = percent ? item : "$" + item;

            let amount = percent ? parseInt(("" + item).replace("%", "")) : decimalToDollars("" + item);
            if (percent) {
                amount = Math.round(amount / 100 * (total));
            }

            names.splice(1, 0, {
                id: amount,
                title: str,
                time_slot: "Tip $" + toDollars(amount)
            })
        });

        let roundUpAmount = (Math.ceil(total * 0.01) * 100) - total;
        names.splice(4, 0, {
            id: roundUpAmount,
            title: "Round Up",
            time_slot: "Tip $" + toDollars(roundUpAmount)
        });

        let checked = null, selected = names.find((item) => item.id === tip);
        if (tip === roundUpAmount) {
            checked = roundUpAmount;
        } else if (selected) {
            checked = tip;
        } else if (tip !== null) {
            checked = -1;

            names.splice(5, 1, {
                id: -1,
                title: "Custom Tip Amount",
                time_slot: "Custom amount is $" + toDollars(tip)
            })
        }

        return (
            <>
                <CardHeader increment={increment}>
                    <FormattedMessage
                        id="__TODO__"
                        defaultMessage="Select Tip"
                    />
                </CardHeader>

                <ButtonGroup flexStart={true}>
                    <RadioGroup
                        items={names}
                        component={(item) => (
                            <RadioCard
                                id={item.id}
                                key={item.id}
                                title={item.title}
                                content={item.time_slot}
                                name="name"
                                checked={item.id === checked}
                                withActionButtons={false}
                                onChange={(id) => {
                                    if (id === -1) {
                                        this.onModalOpen({tip});
                                    } else {
                                        this.props.updateCheckoutTip(id);
                                    }
                                }}
                            />
                        )}
                    />
                </ButtonGroup>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tip);