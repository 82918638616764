import React, {Component} from "react"
import PropTypes from "prop-types"
import {RadioGroupWrapper} from "./radio-group.style";

class RadioGroup extends Component {
    render() {
        let {items = [], component, containerClassName, secondaryComponent} = this.props;

        return (
            <RadioGroupWrapper className={`radioGroup ${containerClassName}`.trim()}>
                {items.map(
                    (item, index) => component && component(item, index)
                )}

                {secondaryComponent && secondaryComponent}
            </RadioGroupWrapper>
        );
    }
}

RadioGroup.propTypes = {
    items: PropTypes.array.isRequired,
    containerClassName: PropTypes.string,
    secondaryComponent: PropTypes.node,
    component: PropTypes.node,
}

export default RadioGroup;