import React, {Component} from "react"
import {MainMenu, IconWrapper, MenuItem, SelectedItem, Icon, Arrow, LeftMenuBox,} from './left-menu.style';
import {FormattedMessage} from 'react-intl';
import * as categoryMenuIcons from './../../../../assets/icons/category-menu-icons';
import {CATEGORY_MENU_ITEMS} from "../../../../settings/navigation";
import Logo from "../../../logo/logo";
import PropTypes from "prop-types"
import Popover from "../../../../components/popover/popover";
import {MenuDown} from "../../../../assets/icons/MenuDown";
import {
    getSearchParameters,
    isOnCheckoutPage,
    isOnProductPage,
    updateSearchParameters
} from "../../../../utils/router-parser";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import Actions from "../../../../redux";
import {connect} from "react-redux";
import {sanitizeName} from "../../../../utils/util";

function mapStateToProps(state) {
    return {shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class LeftMenu extends Component {
    state = {activeMenu: CATEGORY_MENU_ITEMS[0]};

    componentDidMount() {
        let params = getSearchParameters();
        if (params.has("type")) {
            let category = CATEGORY_MENU_ITEMS.find((item) => item.href.slice(1) === params.get("type"));

            if (category) {
                this.setState({activeMenu: category});
            }
        }
    }

    onRequestHome() {
        let {locations, location} = this.props.shop;

        window.scrollTo(0, 0)
        if (isOnCheckoutPage.call(this) || isOnProductPage.call(this)) {
            return this.props.history.push("/" + sanitizeName(location.COMPANY.NAME) + "/order");
        }

        if (locations.length < 10) {
            return this.props.history.push("/" + sanitizeName(location.COMPANY.NAME));
        }

        this.props.history.push("/");
    }

    renderLocations() {
        let {locations, location} = this.props.shop;

        if (location === null || locations.length === 1 || locations.length > 10) {
            return (
                <div />
            )
        }

        return (
            <MainMenu>
                <Popover className='right' handler={
                    <SelectedItem>
                          <span>
                            {/*<Icon>*/}
                            {/*  <CategoryIcon name={activeMenu.icon}/>*/}
                            {/*</Icon>*/}
                            <span>
                              <FormattedMessage
                                  id={"__TODO__"}
                                  defaultMessage={location.NAME}
                              />
                            </span>
                          </span>
                        <Arrow>
                            <MenuDown/>
                        </Arrow>
                    </SelectedItem>
                } content={<CategoryMenu {...this.props} />}/>
            </MainMenu>
        )
    }

    render() {
        let {logo} = this.props;

        return (
            <LeftMenuBox>
                <Logo
                    imageUrl={logo}
                    alt={'Shop Logo'}
                    onClick={this.onRequestHome.bind(this)}
                />

                {this.renderLocations()}
            </LeftMenuBox>
        )
    }
}

class CategoryMenu extends Component {
    handleOnClick(item) {
        updateSearchParameters({location: item.ID});

        window.scrollTo(0, 0);
        this.props.switchShop(item)
    }

    render() {
        let {locations} = this.props.shop;

        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {locations.map((item) => (
                    <MenuItem key={item.ID} {...this.props} onClick={() => this.handleOnClick(item)}>
                        {/*<IconWrapper>*/}
                        {/*    <CategoryIcon name={item.icon}/>*/}
                        {/*</IconWrapper>*/}

                        <FormattedMessage id={"__TODO__"} defaultMessage={item.NAME}/>
                    </MenuItem>
                ))}
            </div>
        );
    }
}

LeftMenu.propTypes = {
    logo: PropTypes.string
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftMenu));
