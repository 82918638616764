import React, {Component} from "react"
import PropTypes from "prop-types"
import {
    Box,
    Image,
    Content,
    Title,
    Description,
    SearchWrapper,
} from './banner.style';

import {Waypoint} from 'react-waypoint';
import Search from './../../features/search/search';
import {FormattedMessage, injectIntl} from "react-intl";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {general: state.general, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Banner extends Component {
    onWaypointPositionChange({currentPosition}) {
        if (!currentPosition || currentPosition === 'above') {
            this.props.updateSticky(true)
        }
    }

    render() {
        let {imageUrl, intlTitleId, intlDescriptionId,} = this.props;
        let {location} = this.props.shop;

        let website = null;
        if (location !== null) {
            website = location.WEBSITE;
        }

        return (
            <Box display={['none', 'none', 'flex']}>
                <Image backgroundImage={`url(${imageUrl})`}/>
                <Content>
                    <Title>
                        <FormattedMessage
                            id="__TODO__"
                            defaultMessage={website && website.TAGLINE ? website.TAGLINE : "Welcome to our website"}
                            values={{minute: 90}}
                        />
                    </Title>
                    <Description>
                        <FormattedMessage
                            id="__TODO__"
                            defaultMessage={website && website.SUBTITLE ? website.SUBTITLE : "Find some products and buy some products"}
                        />
                    </Description>
                    <SearchWrapper>
                        <Search
                            className='banner-search'
                            shadow='0 21px 36px rgba(0,0,0,0.05)'
                            placeholder='Search locations here'
                        />
                    </SearchWrapper>
                    <Waypoint
                        onEnter={() => this.props.updateSticky(false)}
                        onLeave={() => this.props.updateSticky(true)}
                        onPositionChange={this.onWaypointPositionChange.bind(this)}
                    />
                </Content>
            </Box>
        );
    }
}

Banner.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    intlTitleId: PropTypes.string.isRequired,
    intlDescriptionId: PropTypes.string.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
