import React, {Component} from "react"
import {CardHeader} from "../../components/card-header/card-header";
import {FormattedMessage} from "react-intl";
import {ButtonGroup} from "../../components/button-group/button-group";
import RadioCard from "../../components/radio-card/radio-card";
import RadioGroup from "../../components/radio-group/radio-group";
import Button from "../../components/button/button";
import {Box} from "../../components/box";
import {Plus} from "../../assets/icons/PlusMinus";
import {ORDER_TYPE} from "../../redux/checkout";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {checkout: state.checkout, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class OrderType extends Component {
    onModalOpen() {

    }

    handleOnDelete() {

    }

    render() {
        let {flexStart = false} = this.props;
        let {location} =  this.props.shop;
        let {type} = this.props.checkout;

        let types = [{
            id: ORDER_TYPE.PICKUP,
            name: "Pickup",
            info: "Grab your order from the store",
        }];

        if (location.SETTINGS.DELIVERY_ENABLED === "1") {
            types.push({
                id: ORDER_TYPE.DELIVERY,
                name: "Delivery",
                info: "Have us drop off the order",
            });
        }

        if (location.SETTINGS.PICKUP_ENABLED === "1") {
            types.push({
                id: ORDER_TYPE.CONTACTLESS,
                name: "Contactless",
                info: "We come out and give you your order",
            });
        }

        if (location.SETTINGS.IN_STORE_PICKUP_ENABLED === "1") {
            types.push({
                id: ORDER_TYPE.IN_STORE,
                name: "Pay In-Store Pickup",
                info: "Pay for your order in store",
            });
        }

        return (
            <>
                <CardHeader increment={true}>
                    <FormattedMessage
                        id='__TODO__'
                        defaultMessage='Order Type'
                    />
                </CardHeader>

                <ButtonGroup flexStart={flexStart}>
                    <RadioGroup items={types} component={(item) => (
                        <RadioCard
                            id={item.id}
                            key={item.id}
                            title={item.name}
                            content={item.info}
                            name='address'
                            checked={type === item.id}
                            onChange={(id) => {
                                this.props.updateCheckoutType(id);
                            }}
                            onEdit={this.onModalOpen.bind(this, true)}
                            onDelete={() => this.handleOnDelete(item)}
                        />
                    )}/>
                </ButtonGroup>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderType);
