import React, {Component} from "react"
import PropTypes from "prop-types"
import {
    CheckMarkWrapper,
    ProgressBar,
    ProgressBarWrapper,
    ProgressStep,
    StatusBox,
    StatusDetails, StatusTitle
} from "./progress-box.style";
import {CheckMark} from "../../assets/icons/CheckMark";

class ProgressBox extends Component {
    render() {
        let {data, status} = this.props;

        return (
            <>
                {data.map((item, index) => (
                    <ProgressStep key={index}>
                        <ProgressBarWrapper className={status >= index + 1 ? 'checked' : ''}>
                            <StatusBox>
                                {status >= index + 1 ? (
                                    <CheckMarkWrapper>
                                        <CheckMark />
                                    </CheckMarkWrapper>
                                ) : (
                                    index + 1
                                )}
                            </StatusBox>
                            <ProgressBar />
                        </ProgressBarWrapper>
                        <StatusDetails>
                            {item ? <StatusTitle>{item}</StatusTitle> : ''}
                        </StatusDetails>
                    </ProgressStep>
                ))}
            </>
        )
    }
}

ProgressBox.propTypes = {
    data: PropTypes.any,
    status: PropTypes.any
}

export default ProgressBox;