import React, {Component} from "react"
import {CounterBox, CounterButton, CounterValue} from './counter.style';
import PropTypes from "prop-types"
import {Plus, Minus} from "../../assets/icons/PlusMinus";

class Counter extends Component {
    render() {
        let {onDecrement, onIncrement, value, variant, className, ...other} = this.props;

        return (
            <CounterBox variant={variant} className={className} {...other}>
                <CounterButton onClick={onDecrement} variant={variant}>
                    <Minus/>
                </CounterButton>
                <CounterValue>{value}</CounterValue>
                <CounterButton onClick={onIncrement} variant={variant}>
                    <Plus/>
                </CounterButton>
            </CounterBox>
        )
    }
}

Counter.propTypes = {
    onDecrement: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    variant: PropTypes.string,
    className: PropTypes.string
}

export default Counter;