import React, {Component} from "react"
import PropTypes from "prop-types"
import {Category, CategoryWrapper, NoCategory, WalkerWrapper} from "./category-walker.style";
import Button from "../button/button";
import SpringModal from "../spring-modal/spring-modal";
import {parseIdDict} from "../../utils/util";

class CategoryWalker extends Component {
  state = {isOpen: false};

  close() {
    this.setState({isOpen: false});
  }

  render() {
    let {isOpen} = this.state;
    let {categories} = this.props.shop;
    let {children, category, style} = this.props;

    let categoryDict = parseIdDict(categories);
    if (category !== null && categoryDict[category]) {
      category = categoryDict[category].NAME;
    }

    return (
      <WalkerWrapper style={style}>
        <CategoryWrapper>
          {category !== null ? (
            <Category>{category}</Category>
          ) : (
            <NoCategory>All Products</NoCategory>
          )}
          {/* <IconWrapper>
           <ArrowNext width="13px" />
           </IconWrapper>
           <Category>{child}</Category> */}
        </CategoryWrapper>

        <Button variant='text' onClick={() => this.setState({isOpen: !isOpen})}>
          See All
        </Button>

        <SpringModal isOpen={isOpen} onRequestClose={() => this.setState({isOpen: !isOpen})}>
          {children}
        </SpringModal>
      </WalkerWrapper>
    );
  }
}

CategoryWalker.propTypes = {
  children: PropTypes.any.isRequired,
  parent: PropTypes.string,
  child: PropTypes.string
}

export default CategoryWalker;
