import React, {Component} from "react"
import PropTypes from "prop-types"
import {ButtonWrapper, ImageWrapper, NoResultWrapper} from "./no-result.style";
import {FormattedMessage} from "react-intl";
import NoResultSvg from "./no-result.svg";
import Button from "./../button/button";
import {ArrowPrev} from "../../assets/icons/ArrowPrev";
import {withRouter} from "react-router-dom";

class NoResult extends Component {
    render() {
        let {id} = this.props;

        return (
            <NoResultWrapper id={id}>
                <h3>
                    <FormattedMessage
                        id="noResultFound"
                        defaultMessage="Sorry, No result found :("
                    />
                </h3>

                <ImageWrapper>
                    <img src={NoResultSvg} alt="No Result"/>
                </ImageWrapper>

                <ButtonWrapper>
                    <div onClick={() => this.props.history.goBack()}>
                        <Button>
                            <ArrowPrev/> Go Back
                        </Button>
                    </div>
                </ButtonWrapper>
            </NoResultWrapper>
        )
    }
}

NoResult.propTypes = {
    id: PropTypes.string
}

export default withRouter(NoResult);