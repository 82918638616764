import React, {Component} from "react"
import PropTypes from "prop-types"
import Button from "../../components/button/button";
import {Box} from "../../components/box";
import {FormattedMessage} from "react-intl";
import RadioCard from "../../components/radio-card/radio-card";
import {CardHeader} from "../../components/card-header/card-header";
import {ButtonGroup} from "../../components/button-group/button-group";
import RadioGroup from "../../components/radio-group/radio-group";
import AddressCard from "../../components/address-card/address-card";
import {openModal} from '@redq/reuse-modal';
import {Plus} from "../../assets/icons/PlusMinus";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {request} from "../../utils/request";

function mapStateToProps(state) {
    return {user: state.user, checkout: state.checkout}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Address extends Component {
    onModalOpen(props) {
        openModal({
            show: true,
            config: {
                width: 360,
                height: 'auto',
                enableResizing: false,
                disableDragging: true,
                className: "add-address-modal",
            },
            closeOnClickOutside: false,
            component: AddressCard,
            componentProps: {item: props},
        });
    }

    async handleOnDelete(address) {
        let {account} = this.props.user;
        if (!window.confirm("Are you sure you want to delete this address?")) {
            return
        }

        if (account !== null) {
            await request("address/" + address.ID, "DELETE", {});
        }

        this.props.removeAddress(address)
    }

    render() {
        let {addresses} = this.props.user;
        let {address} = this.props.checkout;
        let {
            increment = false, flexStart = false, icon = false, buttonProps = {
                size: 'big',
                variant: 'outlined',
                type: 'button',
                className: 'add-button',
            }
        } = this.props;

        return (
            <>
                <CardHeader increment={increment}>
                    <FormattedMessage
                        id='checkoutDeliveryAddress'
                        defaultMessage='Select Your Delivery Address'
                    />
                </CardHeader>
                <ButtonGroup flexStart={flexStart}>
                    <RadioGroup
                        items={addresses}
                        component={(item) => (
                            <RadioCard
                                id={item.ID}
                                key={item.ID}
                                title={item.LINE_ONE}
                                content={item.CITY + " " + item.STATE + ", " + item.ZIPCODE}
                                name="address"
                                checked={item.ID === address.ID}
                                onChange={(id) => {
                                    this.props.updateAddress(addresses.find((item) => item.ID === id));
                                }}
                                withActionButtons={true} hasDelete={true}
                                onEdit={this.onModalOpen.bind(this, true)}
                                onDelete={() => this.handleOnDelete(item)}
                            />
                        )}
                        secondaryComponent={
                            <Button
                                {...buttonProps}
                                onClick={this.onModalOpen.bind(this, "add-address-modal")}
                            >
                                {icon && (
                                    <Box mr={2}>
                                        <Plus width='10px'/>
                                    </Box>
                                )}
                                <FormattedMessage
                                    id='addAddressBtn'
                                    defaultMessage='Add Address'
                                />
                            </Button>
                        }
                    />
                </ButtonGroup>
            </>
        )
    }
}

Address.propTypes = {
    increment: PropTypes.bool,
    icon: PropTypes.bool,
    buttonProps: PropTypes.any,
    flexStart: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(Address);