import React, {Component} from "react"
import PropTypes from "prop-types"
import {closeModal, openModal} from "@redq/reuse-modal";
import {withRouter} from "react-router-dom"
import {
    DrawerWrapper, LogoWrapper,
    MobileHeaderInnerWrapper,
    MobileHeaderWrapper,
    SearchModalClose,
    SearchModalWrapper, SearchWrapper
} from "../header.style";
import {LongArrowLeft} from "../../../assets/icons/LongArrowLeft";
import Search from "../../../features/search/search";
import {isOnHomePage, isOnOrderPage} from "../../../utils/router-parser";
import MobileDrawer from "./mobile-drawer";
import Logo from "../../logo/logo";
import LogoImage from '../../../assets/images/logo.svg';
import {SearchIcon} from "../../../assets/icons/SearchIcon";
import {bindActionCreators} from "redux";
import Actions from "../../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {user: state.user, general: state.general, shop : state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class MobileHeader extends Component {
    handleSearchModalFactory({ placeholder }) {
      return () => {
        openModal({
            show: true,
            config: {
                enableResizing: false,
                disableDragging: true,
                className: 'search-modal-mobile',
                width: '100%',
                height: '100%',
            },
            closeOnClickOutside: false,
            component: SearchModal,
            componentProps: {
              placeholder,
            },
            closeComponent: () => <div/>,
        });
      }
    };

    render() {
        const {className} = this.props;

        const isHomePage = isOnHomePage.call(this)
        const isOrderPage = isOnOrderPage.call(this)

        return (
            <MobileHeaderWrapper>
                <MobileHeaderInnerWrapper className={className}>
                    <DrawerWrapper>
                        <MobileDrawer/>
                    </DrawerWrapper>

                    <LogoWrapper>
                        <Logo imageUrl={LogoImage} alt='shop logo'/>
                    </LogoWrapper>

                    {isHomePage ? (
                        <SearchWrapper
                            onClick={this.handleSearchModalFactory({
                              placeholder: 'Search locations here'
                            })}
                            className='searchIconWrapper'
                        >
                            <SearchIcon/>
                        </SearchWrapper>
                    ) : null}

                    {isOrderPage ? (
                        <SearchWrapper
                            onClick={this.handleSearchModalFactory({
                              placeholder: 'Search menu items here'
                            })}
                            className='searchIconWrapper'
                        >
                            <SearchIcon/>
                        </SearchWrapper>
                    ) : null}
                </MobileHeaderInnerWrapper>
            </MobileHeaderWrapper>
        );
    }
}

class SearchModal extends Component {
    onSubmit() {
        closeModal();
    }

    render() {
        return (
            <SearchModalWrapper>
                <SearchModalClose type='submit' onClick={() => closeModal()}>
                    <LongArrowLeft/>
                </SearchModalClose>

                <Search
                    className='header-modal-search'
                    showButtonText={false}
                    onSubmit={this.onSubmit}
                    placeholder={this.props.placeholder}
                />
            </SearchModalWrapper>
        )
    }
}

SearchModal.propTypes = {
  placeholder: PropTypes.string,
}

MobileHeader.propTypes = {
    className: PropTypes.string,
    closeSearch: PropTypes.any
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHeader));
