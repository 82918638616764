import React, {Component} from "react"
import {bindActionCreators} from "redux";
import Actions from "../../../redux";
import {connect} from "react-redux";
import {
    DesktopView, MobileView,
    NoOrderFound,
    OrderBox,
    OrderDetailsWrapper,
    OrderList,
    OrderListWrapper,
    Title
} from "./orders.style";
import {FormattedMessage} from "react-intl";
import Scrollbar from "../../../components/scrollbar/scrollbar";
import OrderCard from "./order-card/order-card";
import OrderDetails from "../../order-details/order-details";
import {Products} from "drip-drop";
import {getObjectImage, toDollars} from "../../../utils/util";
import {getSearchParameters, updateSearchParameters} from "../../../utils/router-parser";
import OrderCardMobile from "../../../components/order-card-mobile/order-card-mobile";

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}


class Orders extends Component {
    state = {id: null}

    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    componentDidMount() {
        let {orders} = this.props.user;

        let params = getSearchParameters();

        if (params.has("order")) {
            this.setState({id: parseInt(params.get("order"))})
        } else if (orders.length > 0) {
            this.setState({id: orders[0].ID})
        }
    }

    onClick(order) {
        this.setState({id: order.ID}, () => updateSearchParameters({order: order.ID}));
    }

    getItems(order) {
        return order.PRODUCTS.map((item) => {
            let customizations = Products.Helpers.getProductCustomizations(item);

            let values = Object.values(customizations).map((item, i) => (
                <>
                    <span style={{fontWeight: "bold"}}>
                        {item[0].KEY + ": "}
                    </span>

                    {item.map((option) => (
                        option.VALUE + (option.QUANTITY > 1 ? ` (${option.QUANTITY})` : "")
                    )).join(", ")}{i !== Object.values(customizations).length - 1 ? ", " : <div/>}
                </>
            ))

            return {
                title: item.NAME,
                image: getObjectImage(item, "LOGO", "appicon.png"),
                customizations: values,
                price: toDollars(item.PRICE),
                quantity: item.QUANTITY,
                total: toDollars(item.PRICE)
            }
        })
    }

    render() {
        let {orders} = this.props.user;
        let {id} = this.state;

        let order = orders.find((item) => item.ID === id);

        return (
            <OrderBox>
                <DesktopView>
                    <OrderListWrapper>
                        <Title style={{padding: '0 20px'}}>
                            <FormattedMessage
                                id='intlOrderPageTitle'
                                defaultMessage='My Order'
                            />
                        </Title>
                        <Scrollbar className='order-scrollbar'>
                            <OrderList>
                                {orders.length !== 0 ? (
                                    orders.map((current) => (
                                        <OrderCard
                                            order={current}
                                            key={current.ID}
                                            className={id === current.ID ? 'active' : ''}
                                            onClick={this.onClick.bind(this, current)}
                                        />
                                    ))
                                ) : (
                                    <NoOrderFound>
                                        <FormattedMessage
                                            id='intlNoOrderFound'
                                            defaultMessage='No order found'
                                        />
                                    </NoOrderFound>
                                )}
                            </OrderList>
                        </Scrollbar>
                    </OrderListWrapper>

                    <OrderDetailsWrapper>
                        <Title style={{padding: '0 20px'}}>
                            <FormattedMessage
                                id='orderDetailsText'
                                defaultMessage='Order Details'
                            />
                        </Title>

                        {order && (
                            <OrderDetails ref={this.myRef} order={order} items={this.getItems(order)}/>
                        )}
                    </OrderDetailsWrapper>
                </DesktopView>

                <MobileView>
                    <OrderList>
                        {order && (
                            <OrderCardMobile
                                orders={orders}
                                order={order}
                                className="active"
                                onClick={this.onClick.bind(this)}
                            />
                        )}
                    </OrderList>
                </MobileView>
            </OrderBox>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
