import React, {Component} from "react"
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {CardHeader} from "../../components/card-header/card-header";
import {FormattedMessage} from "react-intl";
import RadioGroup from "../../components/radio-group/radio-group";
import RadioCard from "../../components/radio-card/radio-card";
import {openModal} from "@redq/reuse-modal";
import ScheduleCard from "../../components/schedule-card/schedule-card";
import moment from "moment"
import {ButtonGroup} from "../../components/button-group/button-group";
import {ORDER_TYPE} from "../../redux/checkout";

function mapStateToProps(state) {
    return {checkout: state.checkout, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Schedule extends Component {
    onModalOpen(props) {
        openModal({
            show: true,
            config: {
                width: 360,
                height: 'auto',
                enableResizing: false,
                disableDragging: true,
                className: "add-address-modal",
            },
            closeOnClickOutside: false,
            component: ScheduleCard,
            componentProps: {item: props},
        });
    }

    render() {
        let {schedule, type} = this.props.checkout
        let {location} = this.props.shop;
        let {increment} = this.props;

        let schedules = [{
            id: 0,
            title: "ASAP Order",
            time_slot: type === 2 ? "~" + location.SETTINGS.DELIVERY_ESTIMATE + " Minutes" : "15-30 minute order"
        }, {
            id: 1,
            title: "Custom Time",
            time_slot: "Select Custom Time"
        }];

        if (schedule !== null) {
            schedules.splice(1, 1, {
                id: 1,
                title: "Custom Time",
                time_slot: moment(schedule).format("MM/DD/YY hh:mma")
            })
        }

        if (location.SETTINGS.SCHEDULING_ENABLED === "0") {
            schedules.splice(1);
        }

        return (
            <>
                <CardHeader increment={increment}>
                    <FormattedMessage
                        id="__TODO__"
                        defaultMessage={type === ORDER_TYPE.DELIVERY ? "Delivery Time" : "Pickup Time"}
                    />
                </CardHeader>

                <ButtonGroup flexStart={true}>
                    <RadioGroup
                        items={schedules}
                        component={(item) => (
                            <RadioCard
                                id={item.id}
                                key={item.id}
                                title={item.title}
                                content={item.time_slot}
                                name="schedule"
                                checked={item.id === 1 ? schedule !== null : schedule === null}
                                withActionButtons={false}
                                onClick={(id) => {
                                    if (id === 1) {
                                        this.onModalOpen({schedule})
                                    } else {
                                        this.props.updateSchedule(null);
                                    }
                                }}
                                onChange={(id) => {
                                    if (id === 1) {
                                        this.onModalOpen({schedule})
                                    } else {
                                        this.props.updateSchedule(null);
                                    }
                                }}
                            />
                        )}
                    />
                </ButtonGroup>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
