import React, {Component} from "react";
import {Link} from "react-router-dom";

import styled from 'styled-components';
import css from '@styled-system/css';
import {Box} from '../box';
import {getObjectImage, sanitizeName, toDollars} from "../../utils/util";
import {getSearchParameters} from "../../utils/router-parser";
import {getDefaultProduct, getProductPrice} from "drip-drop/src/product/ProductHelper";

const Card = styled.div({
  backgroundColor: '#fff',
  overflow: 'hidden',
  borderRadius: 6,
  border: '1px solid #f3f3f3',
  display: 'flex',
  flexDirection: 'column',
  transition: '0.3s ease-in-out',
  cursor: 'pointer',

  ':hover': {
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.08)',
    transform: 'translateY(-5px)',
  },
});

const ImageWrapper = styled.div({
  height: 290,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  overflow: 'hidden',

  '@media screen and (max-width: 1280px)': {
    height: 250,
  },

  '@media screen and (max-width: 560px)': {
    height: 180,
  },
});

const Image = styled.img({
  maxWidth: '100%',
  maxHeight: '100%',
  height: 'auto',
});

const Discount = styled.div(
  css({
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    backgroundColor: 'primary.regular',
    color: '#fff',
    overflow: 'hidden',
    padding: '0.25rem 0.5rem',
    fontSize: 12,
    borderRadius: 6,
    pointerEvents: 'none',
  })
);

const Title = styled.h2({
  marginBottom: 10,
  color: '#999',
  fontSize: 14,
  fontWeight: 'normal',
});

const PriceWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 10,
});

const Price = styled.span(
  css({
    color: 'text.bold',
    fontSize: 18,
    fontWeight: 'semiBold',
    lineHeight: 1,
  })
);

const SalePrice = styled.span(
  css({
    color: 'text.regular',
    fontSize: 15,
    lineHeight: 1,
    fontWeight: 'regular',
    padding: '0 5px',
    overflow: 'hidden',
    position: 'relative',
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',

    ':before': {
      content: '""',
      width: '100%',
      height: 1,
      display: 'inline-block',
      backgroundColor: 'text.regular',
      position: 'absolute',
      top: '50%',
      left: 0,
    },
  })
);

class GridCard extends Component {
  render() {
    let {item} = this.props;
    let {location} = this.props.shop;

    let {price, discount} = getProductPrice(location, getDefaultProduct(item), this.props.getPromotions());

    return (
      <Link onClick={() => window.scrollTo(0, 0)}
            to={"/" + sanitizeName(location.COMPANY.NAME) + "/product/" + item.ID + "?" + getSearchParameters().toString()}>
        <Card>
          <ImageWrapper>
            <Image src={getObjectImage(item, "LOGO", "appicon.png")} alt={item.NAME}/>
          </ImageWrapper>

          <Box px={20} pt={3} pb={20}>
            <PriceWrapper>
              <Price>${toDollars(discount > 0 ? price + discount : price)}</Price>

              {discount > 0 && <SalePrice>${price}</SalePrice>}
            </PriceWrapper>
            <Title>{item.NAME}</Title>

            {/*<AddItemToCart data={data} variant="full" buttonText="Add" />*/}
          </Box>
        </Card>
      </Link>
    );
  }
}

export default GridCard;
