import React, {Component} from "react"
import PropTypes from "prop-types"
import {
  CartPopupBody, CheckoutButton, CheckoutButtonWrapper,
  CloseButton, CouponBoxWrapper,
  ItemWrapper,
  NoProductImg,
  NoProductMsg,
  PopupHeader,
  PopupItemCount, PriceBox, PromoCode, Title
} from "./cart.style";
import {ShoppingBagLarge} from "../../assets/icons/ShoppingBagLarge";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import Scrollbar from "../../components/scrollbar/scrollbar";
import {NoCartBag} from "../../assets/icons/NoCartBag";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import CartItem from "../../components/cart-item/cart-item";
import TextCartItem from "../../components/cart-item/text-cart-item";
import {getFullTotal} from "drip-drop/src/checkout/CheckoutHelper";
import {sanitizeName, toDollars} from "../../utils/util";
import Coupon from "../coupon/coupon";
import {CouponCode} from "../fixed-cart/fixed-cart.style";
import {getSearchParameters} from "../../utils/router-parser";

class Cart extends Component {
  state = {showCoupon: false};

  render() {
    let {style, className, onCloseBtnClick,} = this.props;
    let {items, coupon} = this.props.cart;
    let {location} = this.props.shop;
    let {showCoupon} = this.state;

    let {total} = getFullTotal(location, items, this.props.getPromotions())

    return (
      <CartPopupBody className={className} style={style}>
        <PopupHeader>
          <PopupItemCount>
            <ShoppingBagLarge width='19px' height='24px'/>
            <span>
            {items.length}
              &nbsp;
              {items.length > 1 ? (
                <FormattedMessage id='cartItems' defaultMessage='items'/>
              ) : (
                <FormattedMessage id='cartItem' defaultMessage='item'/>
              )}
          </span>
          </PopupItemCount>

          <CloseButton onClick={onCloseBtnClick}>
            <CloseIcon/>
          </CloseButton>
        </PopupHeader>

        <Scrollbar className='cart-scrollbar'>
          <ItemWrapper className='items-wrapper'>
            {items.length > 0 ? (
              items.map((item) => (
                <CartItem
                  {...this.props}
                  closeModal={this.props.closeModal}
                  key={`cartItem-${item.UNIQUE}`}
                  onIncrement={this.props.incrementCartItem.bind(this, item.UNIQUE)}
                  onDecrement={this.props.decrementCartItem.bind(this, item.UNIQUE)}
                  onRemove={this.props.removeCartItem.bind(this, item.UNIQUE)}
                  data={item}
                />
              ))
            ) : (
              <>
                <NoProductImg>
                  <NoCartBag/>
                </NoProductImg>
                <NoProductMsg>
                  <FormattedMessage
                    id='noProductFound'
                    defaultMessage='No products found'
                  />
                </NoProductMsg>
              </>
            )}
          </ItemWrapper>
        </Scrollbar>

        <CheckoutButtonWrapper>
          <PromoCode>
            {!coupon ? (
              <>
                {!showCoupon ? (
                  <button onClick={() => this.setState({showCoupon: true})}>
                    <FormattedMessage
                      id='specialCode'
                      defaultMessage='Have a special code?'
                    />
                  </button>
                ) : (
                  <CouponBoxWrapper>
                    <Coupon/>
                  </CouponBoxWrapper>
                )}
              </>
            ) : (
              <CouponCode>
                <FormattedMessage
                  id='couponApplied'
                  defaultMessage='Coupon Applied'
                />
                <span>{coupon.CODE}</span>
              </CouponCode>
            )}
          </PromoCode>

          {items.length !== 0 ? (
            <Link to={"/" + sanitizeName(location.COMPANY.NAME) + "/checkout?" + getSearchParameters().toString()}>
              <CheckoutButton onClick={onCloseBtnClick}>
                <>
                  <Title>
                    <FormattedMessage
                      id='nav.checkout'
                      defaultMessage='Checkout'
                    />
                  </Title>
                  <PriceBox>
                    ${toDollars(total)}
                  </PriceBox>
                </>
              </CheckoutButton>
            </Link>
          ) : (
            <CheckoutButton>
              <>
                <Title>
                  <FormattedMessage id='nav.checkout' defaultMessage='Checkout'/>
                </Title>
                <PriceBox>
                  ${toDollars(total)}
                </PriceBox>
              </>
            </CheckoutButton>
          )}
        </CheckoutButtonWrapper>
      </CartPopupBody>
    );
  }
}

Cart.propTypes = {
  style: PropTypes.object,
  className: PropTypes.object,
  onCloseBtnClick: PropTypes.func
}

export default Cart;
