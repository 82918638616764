import React, {Component} from "react";
import {createGlobalStyle} from 'styled-components';
import {themeGet} from '@styled-system/theme-get';
import CartPopupButton, {BoxedCartButton} from "../../components/cart-popup-button/cart-popup-button";
import {getFullTotal} from "drip-drop/src/checkout/CheckoutHelper";
import {openModal, closeModal} from '@redq/reuse-modal';
import {CartSlidePopup} from "../cart/cart.style";
import {toDollars} from "../../utils/util";
import {FormattedMessage} from "react-intl";
import Cart from "../cart/cart";

const CartPopupStyle = createGlobalStyle`
  .cartPopup {
    top: auto !important;
    left: auto !important;
    bottom: 50px !important;
    right: 50px !important;
    box-shadow: ${themeGet('shadows.big', '0 21px 36px rgba(0, 0, 0, 0.16)')};
    transform-origin: bottom right;

    @media (max-width: 767px) {
      max-width: none !important;
      width: 100% !important;
      bottom: 0 !important;
      left: 0 !important;
      background: ${themeGet('colors.white', '#ffffff')};
      overflow: initial !important;
      transform-origin: bottom center;
    }
  }
`;

class CartPopup extends Component {
  state = {isOpen: false};

  componentDidMount() {
    window.addEventListener("resize", this.onResize);

    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    let isMobile = window.matchMedia("(max-width: 767px)").matches;

    this.setState({isMobile});
  }

  openModal() {
    openModal({
      show: true,
      config: {
        className: 'cartPopup',
        width: 'auto',
        height: 'auto',
        enableResizing: false,
        disableDragging: true,
        transition: {
          tension: 360,
          friction: 40,
        },
      },
      closeOnClickOutside: true,
      component: Cart,
      closeComponent: () => <div/>,
      componentProps: {onCloseBtnClick: closeModal, closeModal, scrollbarHeight: 330, ...this.props},
    });
  }

  render() {
    let {items} = this.props.cart;
    let {location} = this.props.shop;
    let {total} = getFullTotal(location, items, this.props.getPromotions())
    let {isOpen, isMobile} = this.state;

    let cartSliderClass = isOpen === true ? 'cartPopupFixed' : '';

    return (
      <>
        {isMobile ? (
          <>
            <CartPopupStyle/>
            <CartPopupButton
              className='product-cart'
              itemCount={items.length}
              itemPostfix={
                items.length > 1 ? (
                  <FormattedMessage id='cartItems' defaultMessage='items'/>
                ) : (
                  <FormattedMessage id='cartItem' defaultMessage='item'/>
                )
              }
              onClick={this.openModal.bind(this)}
              price={total}
              pricePrefix='$'
            />
          </>
        ) : (
          <>
            <CartSlidePopup className={cartSliderClass}>
              {isOpen && (
                <Cart onCloseBtnClick={() => this.setState({isOpen: !isOpen})}
                      closeModal={() => this.setState({isOpen: !isOpen})}
                      scrollbarHeight='100vh' {...this.props} />
              )}
            </CartSlidePopup>

            <BoxedCartButton
              className='product-cart'
              itemCount={items.length}
              itemPostfix={
                items.length > 1 ? (
                  <FormattedMessage id='cartItems' defaultMessage='items'/>
                ) : (
                  <FormattedMessage id='cartItem' defaultMessage='item'/>
                )
              }
              price={toDollars(total)}
              pricePrefix="$"
              onClick={() => this.setState({isOpen: !isOpen})}
            />
          </>
        )}
      </>
    );
  }
}

export default CartPopup;
