import React, {Component} from "react"
import {Modal} from '@redq/reuse-modal';
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import ModalContainer from "../../components/modal/modal-container";
import {getSearchParameters, pushLocation, updateSearchParameters} from "../../utils/router-parser";
import {withRouter} from "react-router-dom"
import SidebarCardMenu from "../../layouts/sidebar/sidebar-card-menu";
import styled from "styled-components"
import css from '@styled-system/css';
import ProductGrid from "../../features/product-grid/product-grid";
import {Box} from "../../components/box";
import Sticky from 'react-stickynode';
import {
  CartWrapper,
  CategoriesInner,
  CategoriesWrapper,
  DeliveryOpt, HelpText,
  InfoBlock,
  Infos,
  ItemCategoryName,
  ItemCategoryWrapper, ItemDetails, ItemDiscount, ItemName,
  ItemNameDetails, ItemNamePricing, ItemPrice,
  ItemWrapper,
  Label,
  MainContent,
  MenuContainer,
  ProductDetailsWrapper,
  ProductPreview,
  RestaurantAddress,
  RestaurantMeta,
  RestaurantName,
  RestaurantNameAddress,
  RestaurantOtherInfos
} from "./shop.style";
import {getObjectImage} from "../../utils/util";
import CartPopup from "../../features/cart-popup/cart-popup";

function mapStateToProps(state) {
  return {shop: state.shop, cart: state.cart}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const ContentArea = styled.div(
  css({
    overflow: 'hidden',
    padding: ['30px 0 100px', '30px 0 50px', '30px 2rem 50px'],
    display: 'grid',
    minHeight: '100vh',
    gridColumnGap: '30px',
    gridRowGap: ['15px', '20px', '0'],
    gridTemplateColumns: [
      'minmax(0, 1fr)',
      'minmax(0, 1fr)',
      '300px minmax(0, 1fr)',
    ],
    backgroundColor: '#f9f9f9',
  })
);

class Shop extends Component {
  state = {category: null};

  onCheckout() {
    pushLocation.call(this, "checkout")
  }

  componentDidMount() {
    let category = getSearchParameters().get("category");
    if (category) {
      this.setState({category: parseInt(category)});
    }

    window.addEventListener('resize', () => {
      this.forceUpdate();
    });
  }

  onWaypointPositionChange({currentPosition}) {
    if (!currentPosition || currentPosition === 'above') {
      this.props.updateSticky(true)
    }
  }

  render() {
    const {location} = this.props.shop;
    const {category} = this.state;

    if (location === null) {
      return (
        <div>
          Loading
        </div>
      )
    }

    return (
      <>
        <ModalContainer>
          <Modal>
            <CartPopup  {...this.props} />

            <Box position='relative' bg='gray.200' pt={[60, 89, 78]}>
              <ProductDetailsWrapper>
                <ProductPreview>
                  <img src={getObjectImage(location, "LOGO", "appicon.png")} alt={location.NAME}/>
                </ProductPreview>

                <div id='cart-sticky'>
                  <RestaurantMeta id='restaurantMeta'>
                    <RestaurantNameAddress>
                      <RestaurantName>
                        {location.NAME}
                      </RestaurantName>

                      <RestaurantAddress>
                        {location.ADDRESS}
                      </RestaurantAddress>
                    </RestaurantNameAddress>

                    <RestaurantOtherInfos>
                      {/*<InfoBlock>*/}
                      {/*    <Label>*/}
                      {/*        <FormattedMessage*/}
                      {/*            id='minOrderText'*/}
                      {/*            defaultMessage='Min Order'*/}
                      {/*        />*/}
                      {/*    </Label>*/}
                      {/*    <Infos>*/}
                      {/*        ${10}*/}
                      {/*    </Infos>*/}
                      {/*</InfoBlock>*/}

                      {/*<DeliveryOpt>*/}
                      {/*    ${10}*/}
                      {/*    <br/>{' '}*/}
                      {/*    <FormattedMessage id='deliveryText' defaultMessage='Delivery'/>*/}
                      {/*</DeliveryOpt>*/}
                    </RestaurantOtherInfos>
                  </RestaurantMeta>
                </div>
              </ProductDetailsWrapper>
            </Box>

            <ContentArea>
              <SidebarCardMenu
                {...this.props}
                active={category}
                onCategoryClick={(category) => {
                  updateSearchParameters({category: category});

                  this.setState({category});
                }}
                data={[
                  {id: null, icon: "warehouse-alt", title: "All Products"},
                  ...location.CATEGORIES.filter((item) => item.PRODUCTS.length > 0).map((item) => {
                    return {id: item.ID, icon: item.LOGO || "coffee", title: item.NAME}
                  })
                ]}
              />

              <main>
                <ProductGrid category={category} {...this.props} />
              </main>
            </ContentArea>
          </Modal>
        </ModalContainer>
      </>
    )
  }

  // render() {
  //     const isTablet = window.matchMedia("(max-width: 990px)").matches;
  //     const {location, categories} = this.props.shop;
  //
  //     const headerOffset = isTablet ? -137 : -177;
  //     if (location === null) {
  //         return (
  //             <div>
  //                 Loading
  //             </div>
  //         )
  //     }
  //
  //     const filterProducts = (products) => {
  //         const search = getSearch();
  //         if (!search) {
  //             return products;
  //         }
  //         return new Fuse(products, {
  //             keys: ['NAME'],
  //         }).search(search).map(({ item }) => item);
  //     }
  //
  //     return (
  //         <>
  //             <ModalContainer>
  //                 <Modal>
  //                     <Box position='relative' bg='gray.200' pt={[60, 89, 78]} pb={60}>
  //                         <ProductDetailsWrapper>
  //
  //                             <ProductPreview>
  //                                 <img src={getObjectImage(location, "LOGO", "appicon.png")} alt={location.NAME}/>
  //                             </ProductPreview>
  //
  //                             <div id='cart-sticky'>
  //                                 <RestaurantMeta id='restaurantMeta'>
  //                                     <RestaurantNameAddress>
  //                                         <RestaurantName>
  //                                             {location.NAME}
  //                                         </RestaurantName>
  //
  //                                         <RestaurantAddress>
  //                                             {location.ADDRESS}
  //                                         </RestaurantAddress>
  //                                     </RestaurantNameAddress>
  //
  //                                     <RestaurantOtherInfos>
  //                                         {/*<InfoBlock>*/}
  //                                         {/*    <Label>*/}
  //                                         {/*        <FormattedMessage id='cuisineText'
  // defaultMessage='Cuisine'/>*/}
  //                                         {/*    </Label>*/}
  //                                         {/*    <Infos>*/}
  //                                         {/*        {categories.map((item) => item.NAME).join(', ')}*/}
  //                                         {/*    </Infos>*/}
  //                                         {/*</InfoBlock>*/}
  //
  //                                         {/*<InfoBlock>*/}
  //                                         {/*    <Label>*/}
  //                                         {/*        <FormattedMessage*/}
  //                                         {/*            id='minOrderText'*/}
  //                                         {/*            defaultMessage='Min Order'*/}
  //                                         {/*        />*/}
  //                                         {/*    </Label>*/}
  //                                         {/*    <Infos>*/}
  //                                         {/*        ${10}*/}
  //                                         {/*    </Infos>*/}
  //                                         {/*</InfoBlock>*/}
  //
  //                                         {/*<DeliveryOpt>*/}
  //                                         {/*    ${10}*/}
  //                                         {/*    <br/>{' '}*/}
  //                                         {/*    <FormattedMessage id='deliveryText' defaultMessage='Delivery'/>*/}
  //                                         {/*</DeliveryOpt>*/}
  //                                     </RestaurantOtherInfos>
  //                                 </RestaurantMeta>
  //
  //                                 <Sticky top={isTablet ? 80 : 90} innerZ={999}>
  //                                     <CategoriesWrapper id="dog">
  //                                         <CategoriesInner>
  //                                             {categories.map((item, index) => (
  //                                                 <Link
  //                                                     activeClass='active'
  //                                                     className='category'
  //                                                     to={item.NAME}
  //                                                     offset={headerOffset}
  //                                                     spy={true}
  //                                                     smooth={true}
  //                                                     duration={500}
  //                                                     key={index}
  //                                                 >
  //                                                     {item.NAME}
  //                                                 </Link>
  //                                             ))}
  //                                         </CategoriesInner>
  //                                     </CategoriesWrapper>
  //                                 </Sticky>
  //                             </div>
  //
  //                             <MainContent>
  //                                 <MenuContainer>
  //                                     {categories.map((category, idx) => (
  //                                         <Element name={category.NAME} key={idx}>
  //                                             <ItemCategoryWrapper id={category.NAME}>
  //                                                 <ItemCategoryName>
  //                                                     {category.NAME}
  //                                                 </ItemCategoryName>
  //                                             </ItemCategoryWrapper>
  //
  //                                             {filterProducts(category.PRODUCTS).map((item) => {
  //                                                 let {base, price, discount} = getProductPrice(location,
  // getDefaultProduct(item), this.props.getPromotions());  return ( <ItemWrapper key={item.ID}> <ItemNameDetails>
  // <ItemName style={{marginBottom: item.DESCRIPTION ? 10 : 0}}> {item.NAME}  {item.INVENTORY !== null &&
  // item.INVENTORY <= 0 ? ( <ItemDetails style={{marginLeft: 6}}> - Out of Stock </ItemDetails> ) : <div/>}
  // </ItemName>  {item.DESCRIPTION ? ( <ItemDetails>{item.DESCRIPTION}</ItemDetails> ) : <div/>} </ItemNameDetails>
  // <ItemNamePricing> <HelpText> <FormattedMessage id='fromText' defaultMessage='From'/> &nbsp; </HelpText> {discount
  // > 0 ? ( <ItemDiscount> ${toDollars(base)} </ItemDiscount> ) : null} <ItemPrice sale={discount > 0}>
  // ${toDollars(price)} </ItemPrice> </ItemNamePricing>  <Button variant='select' type='button' className={false ?
  // 'selected' : ''} onClick={() => { this.props.showModal(location.ID + "_" + item.ID + "_PRODUCT", () => (
  // <QuickViewMobile modalProps={item} onModalClose={() => { this.props.hideModal(location.ID + "_" + item.ID +
  // "_PRODUCT") }}/> ), { onClose: () => { } }) }} > <PlusOutline width='14px' height='14px'/> </Button>
  // </ItemWrapper> ); })} </Element> ))} </MenuContainer>  <CartWrapper> <Sticky top='#cart-sticky' innerZ={999}>
  // <FixedCart scrollbarHeight='100vh' className='fixedCartBox' style={{ height: `calc(100vh - 193px)`, }}
  // onCheckout={this.onCheckout.bind(this)} /> </Sticky> </CartWrapper>  <FixedCartPopup
  // onCheckout={this.onCheckout.bind(this)} /> </MainContent> </ProductDetailsWrapper> </Box> </Modal>
  // </ModalContainer> </> ) }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shop));
