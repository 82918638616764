import React, {Component} from "react"
import {PaymentCardList,} from './cards.style';
import Carousel from "../../components/carousel/carousel";
import {Wrapper} from "../../components/payment-card/payment-card.style";
import GiftCard from "../../components/gift-card/gift-card";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {CardHeader} from "../../components/card-header/card-header";
import {FormattedMessage} from "react-intl";

function mapStateToProps(state) {
    return {checkout: state.checkout, user: state.user, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Cards extends Component {
    render() {
        let {giftCard} = this.props.checkout;
        let {giftCards} = this.props.user;
        let {location} = this.props.shop;
        let {increment} = this.props;
        let className = ""

        return (
            <>
                <CardHeader increment={increment}>
                    <FormattedMessage
                        id="__TODO__"
                        defaultMessage="Gift Cards"
                    />
                </CardHeader>

                <PaymentCardList>
                    <Carousel
                        autoPlay={false}
                        infinite={false}
                        data={giftCards.filter((item) => item.LOCATION_ID === location.ID && item.BALANCE > 0)}
                        component={(item) => {
                            return (
                                <Wrapper
                                    id={item.ID}
                                    htmlFor={`gift-card-${item.ID}`}
                                    className={`gift-card-radio ${className ? className : ''}`}
                                >
                                    <input
                                        type='radio'
                                        id={`gift-card-${item.ID}`}
                                        value={`gift-card-${item.ID}`}
                                        onChange={() => this.props.updateCheckoutGiftCard(item.ID)}
                                        checked={giftCard === item.ID}
                                    />

                                    <GiftCard selected={giftCard === item.ID} {...item} />
                                </Wrapper>
                            )
                        }}
                    />
                </PaymentCardList>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cards);