import React, {Component} from "react"
import PropTypes from "prop-types"
import SearchBox from "../../components/search-box/search-box";
import {injectIntl} from 'react-intl';
import {getSearchParameters, updateSearchParameters} from "../../utils/router-parser";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

function mapStateToProps(state) {
    return {modal: state.modal}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Search extends Component {
    state = {search: ""};

    componentDidMount() {
        let defaultParameters = getSearchParameters();

        if (defaultParameters.has("search")) {
            this.setState({search: defaultParameters.get("search")})
        }
    }

    handleOnChange(e) {
        const {value} = e.target;

        this.setState({search: value});
        // dispatch({ type: 'SET_SEARCH_TERM', payload: value });
    }

    onSearch(e) {
        let {onSubmit, searchType} = this.props;
        let {search} = this.state;

        e.preventDefault();
        updateSearchParameters({
            type: searchType || null, search
        })

        this.props.updateActiveSearch(search);

        if (onSubmit) {
            onSubmit();
        }
    }

    render() {
        let {intl, placeholder = 'Search'} = this.props;
        let {search} = this.state;
        let query = getSearchParameters();

        return (
            <SearchBox
                onEnter={this.onSearch.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                value={search}
                name="search"
                placeholder={intl.formatMessage({
                    id: "__TODO__",
                    defaultMessage: placeholder,
                })}
                categoryType={query.has("type") || 'restaurant'}
                buttonText={intl.formatMessage({
                    id: 'searchButtonText',
                    defaultMessage: 'Search',
                })}
                {...this.props}
            />
        )
    }
}

Search.propTypes = {
    minimal: PropTypes.bool,
    searchType: PropTypes.string,
    placeholder: PropTypes.string,
    showButtonText: PropTypes.bool,
    onSubmit: PropTypes.func
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Search)));
