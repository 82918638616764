import React, {Component} from "react"
import Fuse from 'fuse.js'
import PropTypes from "prop-types"
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import Placeholder from './../placeholder/placeholder';
import {
    ProductsRow,
    ProductsCol,
    ButtonWrapper,
    LoaderWrapper,
    LoaderItem,
    ProductCardWrapper,
} from './product-list.style';
import NoResult from "../no-result/no-result";
import LoadMoreButton from "../button/load-more-button";
import {FormattedMessage} from "react-intl";
import {getCategory, getSearch, getSearchParameters} from "../../utils/router-parser";
import Fade from 'react-reveal/Fade';
import ProductCard from "../product-card/product-card";
import LocationCard from "../location-card/location-card";

function mapStateToProps(state) {
    return {shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ProductList extends Component {
    renderLoading() {
        return (
            <LoaderWrapper>
                <LoaderItem>
                    <Placeholder uniqueKey="1"/>
                </LoaderItem>
                <LoaderItem>
                    <Placeholder uniqueKey="2"/>
                </LoaderItem>
                <LoaderItem>
                    <Placeholder uniqueKey="3"/>
                </LoaderItem>
            </LoaderWrapper>
        );
    }

    render() {
        let {loadMore = true, type} = this.props;
        let {products, locations, loading, loadingProducts} = this.props.shop;

        if (loading || loadingProducts) {
            return this.renderLoading();
        }

        const search = getSearch();
        if (search) {
            locations = new Fuse(locations, {
                keys: ['NAME', 'DESCRIPTION'],
            }).search(search).map(({ item }) => item);
        }

        if (!locations || !locations.length) {
            return <NoResult/>
        }

        return (
            <>
                <ProductsRow>
                    {locations.map((item, index) => (
                        <ProductsCol
                            key={index}
                            style={type === 'book' ? {paddingLeft: 0, paddingRight: 1} : {}}
                        >
                            <ProductCardWrapper>
                                <Fade
                                    duration={800}
                                    delay={index * 10}
                                    style={{height: '100%'}}
                                >
                                    <LocationCard data={item}/>
                                </Fade>
                            </ProductCardWrapper>
                        </ProductsCol>
                    ))}
                </ProductsRow>

                {loadMore && false && (
                    <ButtonWrapper>
                        <LoadMoreButton
                            onClick={() => {
                            }}
                            loading={false}
                            variant="secondary"
                            style={{
                                fontSize: 14,
                            }}
                            border="1px solid #f1f1f1"
                        >
                            <FormattedMessage id="loadMoreButton" defaultMessage="Load More"/>
                        </LoadMoreButton>
                    </ButtonWrapper>
                )}
            </>
        );
    }
}

ProductList.propTypes = {
    fetchLimit: PropTypes.number,
    loadMore: PropTypes.bool,
    type: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
