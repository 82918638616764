import React, {Component} from "react"
import OrderReceivedWrapper, {OrderReceivedContainer} from "./receipt.style";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import OrderDetails from "../../features/order-details/order-details";
import {
    DesktopView,
    MobileView,
    OrderBox,
    OrderList,
    OrderTitle,
} from "../../features/order-details/order-details.style";
import OrderCardMobile from "../../components/order-card-mobile/order-card-mobile";
import {getShortURL, request} from "../../utils/request";
import {Products} from "drip-drop"
import {getObjectImage, toDollars} from "../../utils/util";
import io from "socket.io-client"

class Receipt extends Component {
    state = {order: null};

    componentDidMount() {
        const {ID, DATE} = this.props.match.params;

        request(`receipt/${DATE}/${ID}`, "GET", null).then((order) => {
            this.setState({order}, () => this.setupSockets());
        }).catch(() => {
            // this.props.history.push("/");
        })
    }

    setupSockets() {
        let {order} = this.state;

        let socket = io.connect(getShortURL(), {
            query: 'b64=1'
        });

        socket.on("connect", () => {
            socket.emit("order", {
                TOKEN: order.ID
            });
        });

        socket.on('order_started', () => {
            let {order} = this.state;

            this.setState({order: {...order, DATE_STARTED: Date.now()}});
        });

        socket.on('order_complete', () => {
            let {order} = this.state;

            this.setState({order: {...order, DATE_COMPLETED: Date.now()}});
        });

        socket.on('order_delivery_started', () => {
            let {order} = this.state;

            this.setState({order: {...order, DATE_DELIVERY_STARTED: Date.now()}});
        });

        socket.on('order_arrived', () => {
            let {order} = this.state;

            this.setState({order: {...order, DATE_ARRIVED: Date.now()}});
        });

        socket.on('order_delivered', () => {
            let {order} = this.state;

            this.setState({order: {...order, DATE_DELIVERED: Date.now()}});
        });

        socket.on('delivery_claimed', ({id, employee}) => {
            let {order} = this.state;

            this.setState({order: {...order, ...employee}});
        });
    }

    getItems() {
        let {order} = this.state;

        return order.PRODUCTS.map((item) => {
            let customizations = Products.Helpers.getProductCustomizations(item);

            let values = Object.values(customizations).map((item, i) => (
                <>
                    <span style={{fontWeight: "bold"}}>
                        {item[0].KEY + ": "}
                    </span>

                    {item.map((option) => (
                        option.VALUE + (option.QUANTITY > 1 ? ` (${option.QUANTITY})` : "")
                    )).join(", ")}{i !== Object.values(customizations).length - 1 ? ", " : <div/>}
                </>
            ))

            return {
                title: item.NAME,
                image: getObjectImage(item, "LOGO", "appicon.png"),
                customizations: values,
                price: toDollars(item.PRICE),
                quantity: item.QUANTITY,
                total: toDollars(item.PRICE)
            }
        })
    }

    render() {
        let {order} = this.state;

        if (order === null) {
            return (
                <div>
                    loading
                </div>
            )
        }

        return (
            <OrderBox>
                <DesktopView>
                    <OrderReceivedWrapper style={{marginTop: 20}}>
                        <OrderReceivedContainer>
                            <OrderTitle style={{padding: '0 20px'}}>
                                <div>
                                    <FormattedMessage
                                        id='orderDetailsText'
                                        defaultMessage='Order Details'
                                    />
                                </div>

                                {/*<Link to="/">*/}
                                {/*    <a className="home-btn">*/}
                                {/*        <FormattedMessage id="backHomeBtn" defaultMessage="Back to Home"/>*/}
                                {/*    </a>*/}
                                {/*</Link>*/}
                            </OrderTitle>

                            <OrderDetails order={order} items={this.getItems()}/>
                        </OrderReceivedContainer>
                    </OrderReceivedWrapper>
                </DesktopView>

                <MobileView>
                    <OrderList style={{marginTop: 100}}>
                        <OrderCardMobile orders={[order]} order={order} className="active"/>
                    </OrderList>
                </MobileView>
            </OrderBox>
        )
    }
}

export default Receipt
