import React, {Component} from "react"
import PropTypes from "prop-types"
import Img from "react-image";
import placeholder from './product-placeholder.png';

const Placeholder = () => <img src={placeholder} alt="product img loader"/>;

class Image extends Component {
    render() {
        let {url, alt = 'placeholder', unloader, loader, className, style} = this.props;

        return (
            <Img
                draggable={false}
                style={style}
                src={url}
                alt={alt}
                loader={<Placeholder/>}
                unloader={<Placeholder/>}
                className={className}
            />
        );
    }
}

Image.propTypes = {
    url: PropTypes.string,
    alt: PropTypes.string,
    unloader: PropTypes.string,
    loader: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.any
}

export default Image