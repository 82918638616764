import React, {Component} from "react"
import PropTypes from "prop-types"
import {ItemBox, Image, Information, Name, Price, Weight, Total, RemoveButton,} from './cart-item.style';
import Counter from "../counter/counter";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import {getProductPrice} from "drip-drop/src/product/ProductHelper";
import {getObjectImage, sanitizeName, toDollars} from "../../utils/util";
import {withRouter} from "react-router-dom";

class CartItem extends Component {
  render() {
    let {data, onDecrement, onIncrement, onRemove} = this.props;
    const {location} = this.props.shop;

    let {price} = getProductPrice(location, data, this.props.getPromotions())

    return (
      <ItemBox>
        <Counter
          value={data.QUANTITY}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          variant="lightVertical"
        />

        <Image src={getObjectImage(data, "LOGO", "appicon.png")}/>

        <Information onClick={() => {
          this.props.closeModal && this.props.closeModal();

          this.props.history.push("/" + sanitizeName(location.COMPANY.NAME) + "/product/" + data.ID, {item: data});
        }}>
          <Name>{data.NAME}</Name>
          <Price>
            ${toDollars(price)}
          </Price>
        </Information>

        <Total>
          ${toDollars(price)}
        </Total>

        <RemoveButton onClick={onRemove}>
          <CloseIcon/>
        </RemoveButton>
      </ItemBox>
    )
  }
}

CartItem.propTypes = {
  data: PropTypes.object.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default withRouter(CartItem);
