import React, {Component} from "react"
import PropTypes from "prop-types"
import {Meta, OrderListHeader, OrderMeta, SingleOrderList, Status, TrackID} from "./order-card.style";
import {FormattedMessage} from "react-intl";
import {getProgressData, getProgressStep} from "../../../../pages/receipt/receipt.constants";
import moment from "moment";
import {toDollars} from "../../../../utils/util";

class OrderCard extends Component {
    render() {
        let {onClick, className, order} = this.props;

        return (
            <>
                <SingleOrderList onClick={onClick} className={className}>
                    <OrderListHeader>
                        <TrackID>
                            <FormattedMessage
                                id="intlOrderCardTitleText"
                                defaultMessage="Order"
                            />
                            <span>#{order.ID}</span>
                        </TrackID>
                        <Status>{getProgressData(order)[getProgressStep(order)]}</Status>
                    </OrderListHeader>

                    <OrderMeta>
                        <Meta>
                            <FormattedMessage
                                id="intlOrderCardDateText"
                                defaultMessage="Order Date"
                            />
                            : <span>{moment(order.DATE_SENT).format("MMMM DD, YYYY")}</span>
                        </Meta>
                        {/*<Meta>*/}
                        {/*    <FormattedMessage*/}
                        {/*        id="deliveryTimeText"*/}
                        {/*        defaultMessage="Delivery Time"*/}
                        {/*    />*/}
                        {/*    : <span>5 MInutes</span>*/}
                        {/*</Meta>*/}
                        <Meta className="price">
                            <FormattedMessage
                                id="intlOrderCardTotalText"
                                defaultMessage="Total Price"
                            />
                            :
                            <span>
                                ${toDollars(order.TOTAL + order.TIP_AMOUNT)}
                            </span>
                        </Meta>
                    </OrderMeta>
                </SingleOrderList>
            </>
        );
    }
}

OrderCard.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.any,
}

export default OrderCard;