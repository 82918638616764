import React, {Component} from "react"
import Collapse, {Panel} from "rc-collapse"
import PropTypes from "prop-types"
import AccordionWrapper from "./accordion.style";
import ExpandIcon from "./expand-icon";
import {FormattedMessage} from "react-intl";

class Accordion extends Component {
    render() {
        let {className, items = []} = this.props;

        return (
            <AccordionWrapper>
                <Collapse
                    accordion={true}
                    className={`accordion ${className}`.trim()}
                    defaultActiveKey="active"
                    expandIcon={ExpandIcon}
                >
                    {items.length !== 0 &&
                    items.map((item) => {
                        return (
                            <Panel
                                header={
                                    <h3>
                                        <FormattedMessage
                                            id={item.intlTitleId}
                                            defaultMessage="Please add title in language file"
                                        />
                                    </h3>
                                }
                                headerClass="accordion-title"
                                key={item.id}
                            >
                                <p key={item.id}>
                                    <FormattedMessage
                                        id={item.intlDetailsId}
                                        defaultMessage="Please add description in language file"
                                        values={item.values ? item.values : ''}
                                    />
                                </p>
                            </Panel>
                        );
                    })}
                </Collapse>
            </AccordionWrapper>
        )
    }
}

Accordion.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
    id: PropTypes.number,
    handleCategorySelection: PropTypes.func
}

export default Accordion;