import {FormattedMessage} from "react-intl";
import {
    ImageWrapper,
    ItemDetails,
    ItemName, ItemPrice,
    ItemSize,
    ItemWrapper
} from "../../features/order-details/order-details.style";
import React from "react";
import {ORDER_TYPE} from "../../redux/checkout";
import {decimalToDollars, toDollars} from "../../utils/util";

export const orderTableColumns = [
    {
        title: <FormattedMessage id='cartItems' defaultMessage='Items'/>,
        dataIndex: '',
        key: 'items',
        width: 300,
        ellipsis: true,
        render: (text, record) => {
            return (
                <ItemWrapper>
                    <ImageWrapper>
                        <img src={record.image} alt={record.title}/>
                    </ImageWrapper>

                    <ItemDetails>
                        <ItemName>{record.title}</ItemName>
                        <ItemSize>{record.customizations}</ItemSize>
                        <ItemPrice>${toDollars(decimalToDollars(record.price) / parseInt(record.quantity))}</ItemPrice>
                    </ItemDetails>
                </ItemWrapper>
            );
        },
    },
    {
        title: (
            <FormattedMessage id='intlTableColTitle2' defaultMessage='Quantity'/>
        ),
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        width: 100,
    },
    {
        title: <FormattedMessage id='intlTableColTitle3' defaultMessage='Price'/>,
        dataIndex: '',
        key: 'price',
        align: 'center',
        width: 100,
        render: (text, record) => {
            return <p>${toDollars(decimalToDollars(record.total))}</p>;
        },
    },
];

export function getPaymentMethod(order) {
    if (order.DAILY_TAB) {
        return "In Store";
    }

    switch (order.TRANSACTION_TYPE) {
        case 0:
            return "mobile"
        case 1:
            if (order.BRAND && order.LAST_FOUR) {
                return order.BRAND.capitalize() + " **** **** **** " + order.LAST_FOUR;
            }

            return "Gift Card";
        case 2:
            return "Cash at Register"
        case 3:
            return "Tab"
        case 4:
            return "Gift Card"
        case 5:
            // TODO: Get other name

            return "Other"
        case 6:
            return "Invoice"
        case 7:
            return "Rewards"
        case 8:
            return "Split"

    }

    return "unknown"
}

export function getProgressData(order) {
    switch (order.TYPE) {
        default:
            return ['Order Received', 'Order in Progress', 'Order Completed'];
        case ORDER_TYPE.DELIVERY:
            return ['Order Received', 'Order in Progress', 'Order Completed', 'Order on the way', 'Order Arrived', 'Order Delivered'];
    }
}

export function getProgressStep(order) {
    switch (order.TYPE) {
        default:
            if (order.DATE_COMPLETED !== null) return 2;
            if (order.DATE_STARTED === null) return 0;

            return 1;
        case ORDER_TYPE.DELIVERY:
            if (order.DATE_STARTED === null) return 0;
            if (order.DATE_COMPLETED === null) return 1;
            if (order.DATE_DELIVERY_STARTED === null) return 2;
            if (order.DATE_ARRIVED === null) return 3;
            if (order.DATE_DELIVERED === null) return 4;

            return 5;
    }
}
