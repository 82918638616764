import React, {Component} from "react"
import {closeModal} from '@redq/reuse-modal';
import * as Yup from 'yup';
import {FormikProps, ErrorMessage, Formik, Form, withFormik} from 'formik';
import MaskedInput from 'react-text-mask';
import {FormattedMessage} from 'react-intl';
import {FieldWrapper, Heading, StyledInput, StyledError} from './contact-card.style';
import Button from "../button/button";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {request} from "../../utils/request";
import TextField from "../forms/text-field/text-field";

function mapStateToProps(state) {
    return {checkout: state.checkout, user: state.user, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const FormEnhancer = withFormik({
    mapPropsToValues: (item) => {
        return {
            id: item.id || null,
            type: item.type || 'secondary',
            number: item.number || '',
            name: item.name || ''
        }
    },
    validationSchema: Yup.object().shape({
        number: Yup.string().required('Number is required'),
        name: Yup.string().required('Name is required'),
    }),
    handleSubmit: async (values, {setSubmitting, props}) => {
        let {number, name} = values;
        let {phones} = props.user;

        let phone = "1" + number.replaceAll(" ", "").replace("(", "").replace(")", "").replace("-", "");
        props.updatePhones([phone, ...phones]);
        props.updateCheckoutPhone(phone);
        props.updateCheckoutName(name);

        closeModal();
        setSubmitting(false);
    }
});


class ContactCard extends Component {
    render() {
        let {item} = this.props;
        let {values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting} = this.props;

        return (
            <Form>
                <Heading>
                    {item && item.id ? 'Edit Contact' : 'Add New Contact'}
                </Heading>

                <FieldWrapper>
                    <TextField
                        id="name"
                        type="text"
                        placeholder="Enter Order Name"
                        error={touched.name && errors.name}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <MaskedInput
                        mask={[
                            '(',
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            ')',
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                        ]}
                        className="form-control"
                        placeholder="Enter a phone number"
                        guide={false}
                        id="my-input-id"
                        value={values.number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="number"
                        render={(ref, props) => (
                            <StyledInput ref={ref} {...props} />
                        )}
                    />
                </FieldWrapper>

                <ErrorMessage name="number" component={StyledError}/>

                <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    type="submit"
                    style={{width: '100%', height: '44px'}}
                >
                    <FormattedMessage
                        id="savedContactId"
                        defaultMessage="Save Contact"
                    />
                </Button>
            </Form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEnhancer(ContactCard));
