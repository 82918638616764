import {hideModal} from "./modals";
import coupon from "../features/coupon/coupon";

const defaultState = {
    items: [], coupon: null
};

const UPDATE_CART = 'UPDATE_CART';
const RESET_CART = 'RESET_CART';
const UPDATE_COUPON = 'UPDATE_COUPON';

export function updateCoupon(credentials) {
    return {
        type: UPDATE_COUPON,
        payload: credentials
    }
}

export function updateCart(credentials) {
    return {
        type: UPDATE_CART,
        payload: credentials
    }
}

export function loadCart() {
    return (dispatch, getState) => {
        let rawCarts = window.localStorage.getItem("PAST_CARTS") || "[]";
        let carts = JSON.parse(rawCarts);
        let {location} = getState().shop;

        let cart = carts.find((item) => item.LOCATION_ID === location.ID);
        if (!cart) {
            return dispatch(updateCart([]));
        }

        if (cart.COUPON) {
            // TODO: See if code is still valid

            dispatch(updateCoupon(cart.COUPON));
        } else {
            dispatch(updateCoupon(null));
        }

        dispatch(updateCart(cart.ITEMS));
    }
}

export function saveCart() {
    return (dispatch, getState) => {
        let {cart, shop} = getState();
        let {items, coupon} = cart;
        let {location} = shop;

        let rawCarts = window.localStorage.getItem("PAST_CARTS") || "[]";
        let carts = JSON.parse(rawCarts);

        let index = carts.findIndex((item) => item.LOCATION_ID === location.ID);
        if (index !== -1) {
            carts.splice(index, 1);
        }

        if (items.length > 0) {
            carts.push({LOCATION_ID: location.ID, ITEMS: items, COUPON: coupon});
        }

        window.localStorage.setItem("PAST_CARTS", JSON.stringify(carts));
    }
}

export function addCartItem(item) {
    return (dispatch, getState) => {
        let {items} = getState().cart;

        dispatch(updateCart([...items, item]));
        dispatch(saveCart());
    }
}

export function isInCart(unique) {
    return (dispatch, getState) => {
        let {items} = getState().cart;

        return !!items.find((item) => item.UNIQUE === unique);
    }
}

export function getCartItem(unique) {
    return (dispatch, getState) => {
        let {items} = getState().cart;

        return items.find((item) => item.UNIQUE === unique);
    }
}

export function incrementCartItem(unique) {
    return (dispatch, getState) => {
        let {items} = getState().cart;

        let index = items.findIndex((item) => item.UNIQUE === unique);
        if (index === -1) {
            return;
        }

        let cartItem = items[index];
        items.splice(index, 1, {...cartItem, QUANTITY: cartItem.QUANTITY + 1});

        dispatch(updateCart(items));
        dispatch(saveCart());
    }
}

export function removeCartItem(unique) {
    return (dispatch, getState) => {
        let {items} = getState().cart;

        let index = items.findIndex((item) => item.UNIQUE === unique);
        if (index === -1) {
            return;
        }

        items.splice(index, 1);
        dispatch(updateCart(items));
        dispatch(saveCart());
    }
}

export function updateCartItem(cartItem) {
    return (dispatch, getState) => {
        let {items} = getState().cart;

        let index = items.findIndex((item) => item.UNIQUE === cartItem.UNIQUE);
        if (index === -1) {
            return;
        }

        items.splice(index, 1, cartItem);
        dispatch(updateCart(items));
        dispatch(saveCart());
    }
}

export function decrementCartItem(unique) {
    return (dispatch, getState) => {
        let {cart, shop} = getState();
        let {location} = shop;
        let {items} = cart;

        let index = items.findIndex((item) => item.UNIQUE === unique);
        if (index === -1) {
            return;
        }

        let cartItem = items[index];
        if (cartItem.QUANTITY === 1) {
            items.splice(index, 1);

            dispatch(hideModal(location.ID + "_" + cartItem.ID + "_PRODUCT"))
            dispatch(hideModal(unique + "_CART"))
        } else {
            items.splice(index, 1, {...cartItem, QUANTITY: cartItem.QUANTITY - 1});
        }

        dispatch(updateCart(items));
        dispatch(saveCart());
    }
}

export function resetCart() {
    return {
        type: RESET_CART,
        payload: null
    }
}

export const cartReducer = (state = defaultState, action) => {
    let {type, payload} = action;

    switch (type) {
        default:
            return state;
        case UPDATE_CART:
            return {...state, items: payload};
        case RESET_CART:
            return {...state, items: [], coupon: null};
        case UPDATE_COUPON:
            return {...state, coupon: payload};
    }
}
