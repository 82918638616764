import React, {Component} from "react"
import PropTypes from "prop-types"
import {AUTHORIZED_MENU_ITEMS} from "../../../settings/navigation";
import NavLink from "../../../components/nav-link/nav-link";
import {FormattedMessage} from "react-intl";

class AuthorizedMenu extends Component {
    render() {
        let {onLogout} = this.props;

        return (
            <>
                {AUTHORIZED_MENU_ITEMS.map((item, idx) => (
                    <NavLink
                        key={idx}
                        className='menu-item'
                        href={item.href}
                        label={item.defaultMessage}
                        intlId={item.id}
                    />
                ))}

                <div className='menu-item' onClick={onLogout}>
                    <a>
                      <span>
                        <FormattedMessage id='nav.logout' defaultMessage='Logout'/>
                      </span>
                    </a>
                </div>
            </>
        )
    }
}

AuthorizedMenu.propTypes = {
    onLogout: PropTypes.bool.isRequired
}

export default AuthorizedMenu;