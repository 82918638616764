import React, {Component} from "react"
import {Form, withFormik} from "formik"
import * as Yup from "yup";
import {FieldWrapper, Heading} from "./tip-card.style";
import TextField from "../forms/text-field/text-field";
import Button from "../button/button";
import {FormattedMessage} from "react-intl";
import {closeModal} from '@redq/reuse-modal';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import "react-day-picker/lib/style.css"
import {decimalToDollars} from "../../utils/util";

function mapStateToProps(state) {
    return {checkout: state.checkout, user: state.user, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const FormEnhancer = withFormik({
    mapPropsToValues: (props) => {
        return {tip: ""};
    },
    validationSchema: Yup.object().shape({
        tip: Yup.string().required('Tip is required'),
    }),
    handleSubmit: async (values, {status, resetForm, setErrors, setStatus, setSubmitting, setFieldError, props, ...other}, three) => {
        if (isNaN(values.tip)) {
            return setFieldError("tip", "Please enter valid dollar amount");
        }

        props.updateCheckoutTip(decimalToDollars(values.tip));
        closeModal();
    },
});

class TipCard extends Component {
    render() {
        let {values, touched, errors, handleChange, handleBlur, handleSubmit} = this.props;

        return (
            <Form>
                <Heading>Input Custom Tip Amount</Heading>

                <FieldWrapper>
                    <TextField
                        id="tip"
                        type="text"
                        placeholder="Enter Tip Amount Here"
                        error={touched.tip && errors.tip}
                        value={values.tip}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FieldWrapper>

                <Button
                    onClick={handleSubmit}
                    type="submit"
                    style={{width: '100%', height: '44px'}}
                >
                    <FormattedMessage id="__TODO__" defaultMessage="Save Tip Amount"/>
                </Button>
            </Form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEnhancer(TipCard));