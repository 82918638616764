import React, {Component} from "react"
import {sitePages} from "../settings/pages";
import Banner from "../components/banner/banner";
import Input from "../components/forms/input";
import MobileBanner from "../components/banner/mobile-banner";
import {ContentSection, MainContentArea, MobileCarouselDropdown, SidebarSection} from "../assets/styles/pages.style";
import Sidebar from "../layouts/sidebar/sidebar";
import ProductList from "../components/product-grid/product-list";
import {Modal} from '@redq/reuse-modal';
import ModalContainer from "../components/modal/modal-container";
import {bindActionCreators} from "redux";
import Actions from "../redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

function mapStateToProps(state) {
    return {shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const OVERRIDE_SIDEBAR = true;

class Shops extends Component {
    renderMobileSideBar() {
        let {locations} = this.props.shop;

        if (locations.length < 10 || OVERRIDE_SIDEBAR) {
            return (
                <div/>
            )
        }

        return (
            <MobileCarouselDropdown>
                <Sidebar type={""}/>
            </MobileCarouselDropdown>
        )
    }

    renderSideBar() {
        let {locations} = this.props.shop;

        if (locations.length < 10 || OVERRIDE_SIDEBAR) {
            return (
                <div/>
            )
        }

        return (
            <SidebarSection>
                <Sidebar type={""}/>
            </SidebarSection>
        )
    }

    render() {
        let {locations} = this.props.shop;
        let page = sitePages["grocery"];

        return (
            <>
                <ModalContainer>
                    <Modal>
                        <MobileBanner intlTitleId={page.banner_title_id} type={"grocery"}/>
                        <Banner
                            intlTitleId={page.banner_title_id}
                            intlDescriptionId={page.banner_description_id}
                            imageUrl={page.banner_image_url}
                        />

                        {this.renderMobileSideBar()}

                        <MainContentArea>
                            {this.renderSideBar()}

                            <ContentSection
                                style={{width: locations.length < 10 || OVERRIDE_SIDEBAR ? "100%" : "calc(100% - 280px)"}}>
                                <ProductList fetchLimit={20}/>
                            </ContentSection>
                        </MainContentArea>
                    </Modal>
                </ModalContainer>
            </>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shops));
