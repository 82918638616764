import {request} from "../utils/request";
import {resetCart, saveCart} from "./cart";
import {getFullTotal} from "drip-drop/src/checkout/CheckoutHelper";
import {getPromotions} from "./shop";

const gju = require("geojson-utils");

const defaultState = {
    type: 1, sources: [], card: null, tip: null, address: null, schedule: null, notes: null, name: null, giftCard: null,
    phone: null, loading: false
};

export const ORDER_TYPE = {
    PICKUP: 1,
    DELIVERY: 2,
    CONTACTLESS: 3,
    IN_STORE: 5
}

const UPDATE_CHECKOUT_TYPE = "UPDATE_CHECKOUT_TYPE";
const UPDATE_CARD = 'UPDATE_CARD';
const UPDATE_CHECKOUT_LOADING = 'UPDATE_CHECKOUT_LOADING';
const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
const UPDATE_SOURCES = 'UPDATE_SOURCES';
const UPDATE_NAME = 'UPDATE_NAME';
const UPDATE_TIP = 'UPDATE_TIP';
const UPDATE_GIFT_CARD = 'UPDATE_GIFT_CARD';
const UPDATE_NOTES = 'UPDATE_NOTES';
const UPDATE_PHONE = 'UPDATE_PHONE';

export function updateCheckoutType(credentials) {
    return {
        type: UPDATE_CHECKOUT_TYPE,
        payload: credentials
    }
}

export function updateCheckoutName(credentials) {
    return {
        type: UPDATE_NAME,
        payload: credentials
    }
}

export function updateCheckoutPhone(credentials) {
    return {
        type: UPDATE_PHONE,
        payload: credentials
    }
}

export function updateCheckoutNotes(credentials) {
    return {
        type: UPDATE_NOTES,
        payload: credentials
    }
}

export function updateCheckoutGiftCard(credentials) {
    return (dispatch, getState) => {
        let {checkout, user} = getState();
        let {sources} = checkout;
        let {giftCards} = user;

        let index = sources.findIndex((item) => item.type === "giftCard");
        if (index !== -1) {
            sources.splice(index, 1);
        }

        let card = giftCards.find((item) => item.ID === credentials);
        sources.push({
            type: "giftCard",
            id: credentials,
            amount: card.BALANCE
        })

        dispatch(updateSources(sources));

        dispatch({
            type: UPDATE_GIFT_CARD,
            payload: credentials
        })
    }
}

export function updateCheckoutTip(credentials) {
    return {
        type: UPDATE_TIP,
        payload: credentials
    }
}

export function updateCheckoutLoading(credentials) {
    return {
        type: UPDATE_CHECKOUT_LOADING,
        payload: credentials
    }
}

export function updateCard(credentials) {
    return (dispatch, getState) => {
        let {checkout} = getState();
        let {sources} = checkout;

        let index = sources.findIndex((item) => item.type === "card");
        if (index !== -1) {
            sources.splice(index, 1);
        }

        index = sources.findIndex((item) => item.type === "token");
        if (index !== -1) {
            sources.splice(index, 1);
        }

        if (credentials != null) {
            if (credentials.indexOf("card_") === -1) {
                sources.push({
                    type: "token",
                    id: credentials
                });
            } else {
                sources.push({
                    type: "card",
                    id: credentials
                });
            }
        }

        dispatch(updateSources(sources));

        dispatch({
            type: UPDATE_CARD,
            payload: credentials
        });
    }
}

export function updateAddress(credentials) {
    return {
        type: UPDATE_ADDRESS,
        payload: credentials
    }
}

export function updateSchedule(credentials) {
    return {
        type: UPDATE_SCHEDULE,
        payload: credentials
    }
}

export function updateSources(credentials) {
    return {
        type: UPDATE_SOURCES,
        payload: credentials
    }
}

export function addSource(source) {
    return (dispatch, getState) => {
        let {sources} = getState().checkout;

        dispatch(updateSources([...sources, source]));
    }
}

export function getDeliveryFee() {
    return (dispatch, getState) => {
        let {checkout, shop} = getState();

        let {address} = checkout;
        let {location} = shop;

        if (location.ZONES.length > 0) {
            for (let zone of location.ZONES.filter((item) => item.ENABLED === 1)) {
                let inPolygon = gju.pointInPolygon(
                  {type: "Point", coordinates: [address.LATITUDE, address.LONGITUDE]},
                  {
                      type: "Polygon",
                      coordinates: [
                          zone.POINTS.map((item) => [item.LATITUDE, item.LONGITUDE]),
                      ],
                  }
                );

                if (inPolygon) {
                    return parseInt(zone.DELIVERY_FEE);
                }
            }
        }

        return parseInt(location.SETTINGS.DELIVERY_FEE);
    }
}

export function executeCheckout() {
    return async (dispatch, getState) => {
        dispatch(updateCheckoutLoading(true));

        let {shop, cart, checkout} = getState();
        let {sources, schedule, notes, type, name, phone, tip = 0, address: checkoutAddress} = checkout;
        let {items, coupon} = cart;
        let {location} = shop;

        let url = "/checkout/advanced", car = null, address = null;
        if (type === ORDER_TYPE.CONTACTLESS) {
            url = "/checkout/pickup";
        } else if (type === ORDER_TYPE.DELIVERY) {
            url = "/checkout/delivery"
            address = checkoutAddress;
        }

        let {total} = getFullTotal(location, items, dispatch(getPromotions()));
        let rawFee = type === 2 ? dispatch(getDeliveryFee()) : 0;

        return await request("shop/" + location.ID + url, "POST", {
            REDEEM: false,
            CART: items,
            PRICE: total + rawFee,
            NOTES: notes,
            SCHEDULE: schedule,
            TIP: tip,
            VERBOSE: true,
            SOURCES: sources,
            ADDRESS: address,
            CAR: car,
            NAME: name,
            COUPON: coupon ? coupon.CODE : null,
            WEB: true,
            PHONE: phone
        }).then(async (payload) => {
            if (type === 2) {
                payload = {...payload.delivery, ...payload.order};
            } else if (type === 3) {
                payload = {...payload.pickup, ...payload.order};
            }

            dispatch(resetCart());
            dispatch(saveCart());

            return Promise.resolve(payload);
        }).catch((err) => {
            if (err === 200) {
                alert("A product in your cart is out of stock");
            }

            return Promise.reject();
        }).finally(() => {
            dispatch(updateCheckoutLoading(false));
        });
    };
}

export function executeInStorePickup() {
    return async (dispatch, getState) => {
        dispatch(updateCheckoutLoading(true));

        let {shop, cart, checkout} = getState();
        let {sources, schedule, notes, type, name, phone, tip = 0, address: checkoutAddress} = checkout;
        let {items, coupon} = cart;
        let {location} = shop;

        return await request("shop/" + location.ID + "/checkout/pickup", "POST", {
            DATE_SCHEDULED: schedule,
            FROM_CHECKOUT: false,
            ITEMS: items,
            NAME: name,
            NOTES: notes,
            PHONE: phone
        }).then(async (payload) => {
            dispatch(resetCart());
            dispatch(saveCart());

            return Promise.resolve(payload);
        }).catch((err) => {
            if (err === 200) {
                alert("A product in your cart is out of stock");
            }

            return Promise.reject();
        }).finally(() => {
            dispatch(updateCheckoutLoading(false));
        });
    };
}

export const checkoutReducer = (state = defaultState, action) => {
    let {type, payload} = action;

    switch (type) {
        default:
            return state;
        case UPDATE_CHECKOUT_TYPE:
            return {...state, type: payload}
        case UPDATE_CARD:
            return {...state, card: payload};
        case UPDATE_CHECKOUT_LOADING:
            return {...state, loading: payload};
        case UPDATE_ADDRESS:
            return {...state, address: payload};
        case UPDATE_SCHEDULE:
            return {...state, schedule: payload};
        case UPDATE_SOURCES:
            return {...state, sources: payload};
        case UPDATE_NAME:
            return {...state, name: payload};
        case UPDATE_TIP:
            return {...state, tip: payload};
        case UPDATE_NOTES:
            return {...state, notes: payload};
        case UPDATE_PHONE:
            return {...state, phone: payload};
        case UPDATE_GIFT_CARD:
            return {...state, giftCard: payload};
    }
}
