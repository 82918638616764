import React, {Component} from "react"
import PropTypes from "prop-types"
import {Tree} from "./tree";

class TreeMenu extends Component {
    renderLevel(children) {
        let {onClick, active} = this.props;

        return children.map((subOption) => {
            if (!subOption.children) {
                return (
                    <Tree
                        key={subOption.ID}
                        name={subOption.NAME}
                        icon={subOption.ICON}
                        depth="child"
                        onClick={() => onClick(subOption)}
                        defaultOpen={active === subOption.NAME}
                    />
                );
            }

            return (
                <Tree
                    key={subOption.ID}
                    name={subOption.NAME}
                    icon={subOption.ICON}
                    dropdown={false}
                    depth="parent"
                    onClick={() => onClick(subOption)}
                    defaultOpen={false}
                >
                    {this.renderLevel(subOption.children)}
                </Tree>
            );
        })
    }

    render() {
        let {data} = this.props;

        return this.renderLevel(data)
    }
}

TreeMenu.propTypes = {
    data: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.string.isRequired,
    className: PropTypes.string,
}

export default TreeMenu;