import React, {Component} from "react"
import CheckoutWrapper, {
    Bold,
    BoldHover,
    CalculationWrapper,
    CartWrapper,
    CheckoutContainer,
    CheckoutInformation,
    CheckoutSubmit,
    CouponBoxWrapper,
    CouponCode,
    CouponInputBox,
    DeliverySchedule,
    HaveCoupon,
    InformationBox,
    ItemsWrapper,
    NoProductImg,
    NoProductMsg,
    OrderInfo,
    RemoveCoupon,
    Text,
    TextWrapper,
    Title
} from "./checkout.style";
import {Modal, openModal} from "@redq/reuse-modal";
import Sticky from 'react-stickynode';
import {FormattedMessage} from "react-intl";
import Scrollbar from "../../components/scrollbar/scrollbar";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {NoCartBag} from "../../assets/icons/NoCartBag";
import CheckoutItem from "../../components/checkout-item/checkout-item";
import {getFullTotal} from "drip-drop/src/checkout/CheckoutHelper";
import {getDistance, sanitizeName, toDollars} from "../../utils/util";
import Address from "../../features/address/address";
import Payment from "../../features/payment/payment";
import OrderType from "../../features/order-type/order-type";
import ModalContainer from "../../components/modal/modal-container";
import Button from "../../components/button/button";
import {withRouter} from "react-router-dom";
import {ORDER_TYPE} from "../../redux/checkout";
import Schedule from "../../features/schedule/schedule";
import Tip from "../../features/tip/tip";
import Cards from "../../features/cards/cards";
import {isProductUnavailable} from "drip-drop/src/product/ProductHelper";
import {withinFullHours} from "drip-drop/src/location/LocationHelper";
import Coupon from "../../features/coupon/coupon";
import NotesCard from "../../components/notes-card/notes-card";
import {CardHeader} from "../../components/card-header/card-header";
import Contact from "../../features/contact/contact";

function mapStateToProps(state) {
    return {shop: state.shop, cart: state.cart, checkout: state.checkout, user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const gju = require("geojson-utils");

class Checkout extends Component {
    state = {showCoupon: false, zone: null};

    componentDidMount() {
        let {account} = this.props.user;

        if (account !== null) {
            this.props.updateCheckoutPhone(account.PHONE);

            if (account.FULL_NAME.length > 0) {
                this.props.updateCheckoutName(account.FULL_NAME);
            }
        }

        window.scrollTo(0, 0);
    }

    async checkout() {
        if (this.isValid()) {
            let {location} = this.props.shop;
            let {type} = this.props.checkout;

            if (type === ORDER_TYPE.IN_STORE) {
                let {ID: unique, DATE_SENT: sent} =await this.props.executeInStorePickup();

                this.props.history.push("/" + sanitizeName(location.COMPANY.NAME) + "/receipt/" + sent + "/" + unique);
            } else {
                let {RECEIPT_ID: unique, DATE_SENT: sent} = await this.props.executeCheckout();

                this.props.history.push("/" + sanitizeName(location.COMPANY.NAME) + "/receipt/" + sent + "/" + unique);
            }

            window.scrollTo(0, 0);
        }
    }

    isWithinZone() {
        let {type, address} = this.props.checkout;
        let {location} = this.props.shop;

        if (location.ZONES.length > 0 && type === ORDER_TYPE.DELIVERY) {
            for (let zone of location.ZONES.filter((item) => item.ENABLED === 1)) {
                let inPolygon = gju.pointInPolygon(
                  {type: "Point", coordinates: [address.LATITUDE, address.LONGITUDE]},
                  {
                      type: "Polygon",
                      coordinates: [
                          zone.POINTS.map((item) => [item.LATITUDE, item.LONGITUDE]),
                      ],
                  }
                );

                if (inPolygon) {
                    return true;
                }
            }

            return false;
        }

        return !(type === ORDER_TYPE.DELIVERY && (getDistance(location, address) / 1609.344) > parseFloat(location.SETTINGS.DELIVERY_RADIUS));
    }

    isValid() {
        let {type, address, name, sources, schedule} = this.props.checkout;
        let {products, location} = this.props.shop;
        let {account} = this.props.user;
        let {items} = this.props.cart;

        if (type === ORDER_TYPE.DELIVERY && (account === null || address === null)) {
            return false;
        }

        if (!this.isWithinZone()) {
            return false;
        }

        for (let item of items) {
            let product = products.find((product) => product.ID === item.ID);

            let unavailable = isProductUnavailable(product, schedule ? new Date(schedule) : new Date());
            if (unavailable !== null) {
                return false;
            }
        }

        if (!withinFullHours(location, schedule ? new Date(schedule) : new Date())) {
            return false;
        }

        return items.length > 0 && (type === ORDER_TYPE.IN_STORE || sources.length > 0) && name !== null;
    }

    getSubmitText() {
        let {name, sources, schedule, type, address} = this.props.checkout;
        let {products, location} = this.props.shop;
        let {account} = this.props.user;
        let {items} = this.props.cart;

        if (!withinFullHours(location, schedule ? new Date(schedule) : new Date())) {
            return "Outside Store Hours"
        }

        if (type === ORDER_TYPE.DELIVERY && account === null) {
            return "Login Required for Delivery Orders";
        }

        if (type === ORDER_TYPE.DELIVERY && address === null) {
            return "Select Delivery Address";
        }

        if (!this.isWithinZone()) {
            return "Outside Delivery Range";
        }

        if (name === null) {
            return "Input Order Name";
        } else if (sources.length === 0 && type !== ORDER_TYPE.IN_STORE) {
            return "Add Payment Type"
        }

        for (let item of items) {
            let product = products.find((product) => product.ID === item.ID);

            let unavailable = isProductUnavailable(product, schedule ? new Date(schedule) : new Date());
            if (unavailable !== null) {
                return product.NAME + " " + unavailable;
            }
        }

        return "Proceed to Checkout";
    }

    renderOrderTypeExtra() {
        let {type} = this.props.checkout;

        if (type === ORDER_TYPE.DELIVERY) {
            return (
                <InformationBox>
                    <Address
                        increment={true}
                        flexStart={true}
                        buttonProps={{
                            variant: 'text',
                            type: 'button',
                            className: 'addButton',
                        }}
                        icon={true}
                    />
                </InformationBox>
            )
        }
    }

    renderTip() {
        let {tip} = this.props.checkout;

        if (tip === null) {
            return <div/>
        }

        return (
            <TextWrapper>
                <Text>
                    <FormattedMessage
                        id='__TODO__'
                        defaultMessage='Tips'
                    />
                </Text>
                <Text>${toDollars(tip)}</Text>
            </TextWrapper>
        )
    }

    renderDeliveryFee() {
        let {type} = this.props.checkout;
        let rawFee = type === 2 ? this.props.getDeliveryFee() : 0;

        if (rawFee === 0) {
            return <div/>
        }

        return (
            <TextWrapper>
                <Text>
                    <FormattedMessage
                        id='__TODO__'
                        defaultMessage='Delivery Fee'
                    />
                </Text>
                <Text>${toDollars(rawFee)}</Text>
            </TextWrapper>
        )
    }

    render() {
        let {location} = this.props.shop;
        let {giftCards} = this.props.user;
        let {items, coupon} = this.props.cart;
        let {loading, tip, type, notes} = this.props.checkout;
        let isSticky = window.matchMedia("(max-width: 768px)").matches
        let {hasCoupon} = this.state;

        if (location === null) {
            return (
                <div>
                    Loading
                </div>
            );
        }

        let rawFee = type === 2 ? this.props.getDeliveryFee() : 0;

        let {total: rawTotal, tax: taxes, subtotal, discount} = getFullTotal(location, items, this.props.getPromotions())
        let total = rawTotal + rawFee;

        return (
            <ModalContainer>
                <Modal>
                    <form>
                        <CheckoutWrapper>
                            <CheckoutContainer>
                                <CheckoutInformation>
                                    {(location.SETTINGS.DELIVERY_ENABLED === "1" || location.SETTINGS.PICKUP_ENABLED === "1") ? (
                                        <InformationBox>
                                            <OrderType flexStart={true}
                                                       buttonProps={{
                                                           variant: 'text',
                                                           type: 'button',
                                                           className: 'addButton',
                                                       }}
                                            />
                                        </InformationBox>
                                    ) : <div/>}

                                    {this.renderOrderTypeExtra()}

                                    <InformationBox>
                                        <DeliverySchedule>
                                            <Schedule increment={true}/>
                                        </DeliverySchedule>
                                    </InformationBox>

                                    <InformationBox>
                                        <Contact
                                            increment={true}
                                            flexStart={true}
                                            buttonProps={{
                                                variant: 'text',
                                                type: 'button',
                                                className: 'addButton',
                                            }}
                                            icon={true}
                                        />
                                    </InformationBox>

                                    {type !== ORDER_TYPE.IN_STORE ? (
                                        <InformationBox>
                                            <DeliverySchedule>
                                                <Tip increment={true} total={total}/>
                                            </DeliverySchedule>
                                        </InformationBox>
                                    ) : <div/>}

                                    {giftCards.filter((item) => item.LOCATION_ID === location.ID && item.BALANCE > 0).length > 0 && type !== ORDER_TYPE.IN_STORE ? (
                                        <InformationBox>
                                            <DeliverySchedule>
                                                <Cards increment={true}/>
                                            </DeliverySchedule>
                                        </InformationBox>
                                    ) : <div/>}

                                    <InformationBox
                                        className='paymentBox'
                                        style={{paddingBottom: 30}}
                                    >
                                        {type !== ORDER_TYPE.IN_STORE ? (
                                            <>
                                                <Payment increment={true}/>

                                                {coupon !== null ? (
                                                    <CouponBoxWrapper>
                                                        <CouponCode>
                                                            <FormattedMessage id='couponApplied'/>
                                                            <span>{coupon.CODE}</span>

                                                            <RemoveCoupon
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    this.props.updateCoupon(null);
                                                                    this.setState({showCoupon: false});
                                                                }}
                                                            >
                                                                <FormattedMessage id='removeCoupon'/>
                                                            </RemoveCoupon>
                                                        </CouponCode>
                                                    </CouponBoxWrapper>
                                                ) : (
                                                    <CouponBoxWrapper>
                                                        {!hasCoupon ? (
                                                            <HaveCoupon
                                                                onClick={() => this.setState({hasCoupon: true})}>
                                                                <FormattedMessage
                                                                    id='specialCode'
                                                                    defaultMessage='Have a special code?'
                                                                />
                                                            </HaveCoupon>
                                                        ) : (
                                                            <CouponInputBox>
                                                                <Coupon errorMsgFixed={true} className='normalCoupon'/>
                                                            </CouponInputBox>
                                                        )}
                                                    </CouponBoxWrapper>
                                                )}
                                            </>
                                        ) : (
                                            <CardHeader increment={true}>
                                                <FormattedMessage
                                                    id="__TODO__"
                                                    defaultMessage="Complete Order"
                                                />
                                            </CardHeader>
                                        )}

                                        {/*<TermConditionText>*/}
                                        {/*    <FormattedMessage*/}
                                        {/*        id='termAndConditionHelper'*/}
                                        {/*        defaultMessage='By making this purchase you agree to our'*/}
                                        {/*    />*/}
                                        {/*    <Link to="/">*/}
                                        {/*        <TermConditionLink>*/}
                                        {/*            <FormattedMessage*/}
                                        {/*                id='termAndCondition'*/}
                                        {/*                defaultMessage='terms and conditions.'*/}
                                        {/*            />*/}
                                        {/*        </TermConditionLink>*/}
                                        {/*    </Link>*/}
                                        {/*</TermConditionText>*/}

                                        <CheckoutSubmit>
                                            <Button
                                                type='button'
                                                onClick={this.checkout.bind(this)}
                                                disabled={!this.isValid()}
                                                size='big'
                                                loading={loading}
                                                style={{width: '100%'}}
                                            >
                                                <FormattedMessage
                                                    id='__TODO__'
                                                    defaultMessage={this.getSubmitText()}
                                                />
                                            </Button>
                                        </CheckoutSubmit>
                                    </InformationBox>
                                </CheckoutInformation>

                                <CartWrapper>
                                    <Sticky
                                        enabled={!isSticky}
                                        top={120}
                                        innerZ={999}
                                    >
                                        <OrderInfo>
                                            <Title>
                                                <FormattedMessage
                                                    id='cartTitle'
                                                    defaultMessage='Your Order'
                                                />
                                            </Title>

                                            <Scrollbar className='checkout-scrollbar'>
                                                <ItemsWrapper>
                                                    {items.length > 0 ? (
                                                        items.map((item) => (
                                                            <CheckoutItem key={`cartItem-${item.id}`} product={item}/>
                                                        ))
                                                    ) : (
                                                        <>
                                                            <NoProductImg>
                                                                <NoCartBag/>
                                                            </NoProductImg>

                                                            <NoProductMsg>
                                                                <FormattedMessage
                                                                    id='noProductFound'
                                                                    defaultMessage='No products found'
                                                                />
                                                            </NoProductMsg>
                                                        </>
                                                    )}
                                                </ItemsWrapper>
                                            </Scrollbar>

                                            <CalculationWrapper>
                                                <TextWrapper>
                                                    <Text>
                                                        <FormattedMessage
                                                            id='subTotal'
                                                            defaultMessage='Subtotal'
                                                        />
                                                    </Text>
                                                    <Text>
                                                        ${toDollars(subtotal)}
                                                    </Text>
                                                </TextWrapper>

                                                {discount > 0 ? (
                                                    <TextWrapper>
                                                        <Text>
                                                            <FormattedMessage
                                                                id='__TODO__'
                                                                defaultMessage='Discount'
                                                            />
                                                        </Text>
                                                        <Text>(${toDollars(discount)})</Text>
                                                    </TextWrapper>
                                                ) : <div/>}

                                                <TextWrapper>
                                                    <Text>
                                                        <FormattedMessage
                                                            id='__TODO__'
                                                            defaultMessage='Taxes'
                                                        />
                                                    </Text>
                                                    <Text>
                                                        ${toDollars(taxes)}
                                                    </Text>
                                                </TextWrapper>

                                                {this.renderDeliveryFee()}
                                                {this.renderTip()}

                                                <TextWrapper>
                                                    <Text>
                                                        <FormattedMessage id='__TODO__' defaultMessage='Notes'/>{' '}
                                                    </Text>
                                                    <BoldHover onClick={() => {
                                                        openModal({
                                                            show: true,
                                                            config: {
                                                                width: 360,
                                                                height: 'auto',
                                                                enableResizing: false,
                                                                disableDragging: true,
                                                                className: "add-address-modal",
                                                            },
                                                            closeOnClickOutside: true,
                                                            component: NotesCard,
                                                            componentProps: {notes}
                                                        });
                                                    }}>
                                                        {notes && notes.length > 0 ? "Edit Notes" : "Add Notes"}
                                                    </BoldHover>
                                                </TextWrapper>

                                                {notes && notes.length > 0 ? (
                                                    <TextWrapper>
                                                        <Text>
                                                            <i>{notes}</i>
                                                        </Text>
                                                    </TextWrapper>
                                                ) : <div/>}

                                                <TextWrapper style={{marginTop: 20}}>
                                                    <Bold>
                                                        <FormattedMessage id='totalText' defaultMessage='Total'/>{' '}
                                                    </Bold>
                                                    <Bold>
                                                        ${toDollars(total + tip)}
                                                    </Bold>
                                                </TextWrapper>
                                            </CalculationWrapper>
                                        </OrderInfo>
                                    </Sticky>
                                </CartWrapper>
                            </CheckoutContainer>
                        </CheckoutWrapper>
                    </form>
                </Modal>
            </ModalContainer>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));
