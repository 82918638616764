import React, {Component} from "react";
import {LoaderItem, LoaderWrapper} from "../../components/product-grid/product-list.style";
import Placeholder from "../../components/placeholder/placeholder"
import styled from "styled-components"
import css from '@styled-system/css'
import GridCard from "../../components/grid-card/grid-card";
import {getSearch} from "../../utils/router-parser";
import Fuse from 'fuse.js'

const Grid = styled.div(
  css({
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(2, minmax(180px, 1fr))',

    '@media screen and (min-width: 630px)': {
      gridTemplateColumns: 'repeat(3, minmax(180px, 1fr))',
    },

    '@media screen and (min-width: 768px)': {
      gridTemplateColumns: 'repeat(3, minmax(180px, 1fr))',
    },

    '@media screen and (min-width: 991px)': {
      gridTemplateColumns: 'repeat(3, minmax(180px, 1fr))',
    },

    '@media screen and (min-width: 1200px)': {
      gridTemplateColumns: 'repeat(4, minmax(180px, 1fr))',
    },

    '@media screen and (min-width: 1700px)': {
      gridTemplateColumns: 'repeat(5, minmax(240px, 1fr))',
    },

    '@media screen and (min-width: 1900px)': {
      gridTemplateColumns: 'repeat(6, minmax(240px, 1fr))',
    },
  })
);

class ProductGrid extends Component {
  state = {loading: false};

  renderLoading() {
    return (
      <LoaderWrapper>
        <LoaderItem>
          <Placeholder uniqueKey="1"/>
        </LoaderItem>
        <LoaderItem>
          <Placeholder uniqueKey="2"/>
        </LoaderItem>
        <LoaderItem>
          <Placeholder uniqueKey="3"/>
        </LoaderItem>
      </LoaderWrapper>
    );
  }

  render() {
    const {loading} = this.state;
    const {products} = this.props.shop;
    const {category, products: overrideProducts} = this.props;

    if (loading) {
      return this.renderLoading();
    }

    let productList = products;
    if (category !== null) {
      productList = productList.filter((item) => item.CATEGORY_ID === category);
    }

    const search = getSearch();
    if (search) {
      productList = new Fuse(productList, {
        keys: ['NAME'],
      }).search(search).map(({item}) => item);
    }

    if (overrideProducts) {
      productList = overrideProducts;
    }

    return (
      <section>
        <Grid>
          {productList.map((product) => (
            <GridCard item={product} key={product.ID} {...this.props} />
          ))}
        </Grid>
      </section>
    )
  }
}

export default ProductGrid;
