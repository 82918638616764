import React, {Component, useContext} from "react"
import PropTypes from "prop-types"
import StripeFormWrapper, {FieldWrapper, Heading} from "./stripe-form.style";
import {loadStripe} from '@stripe/stripe-js';
import {closeModal} from '@redq/reuse-modal';

import {
    Elements,
    CardElement,
    ElementsConsumer
} from '@stripe/react-stripe-js';
import {bindActionCreators} from "redux";
import Actions from "../../../redux";
import {connect} from "react-redux";
import {request} from "../../../utils/request";

function mapStateToProps(state) {
    return {shop: state.shop, cart: state.cart, user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

class StripeFormHelper extends Component {
    async handleSubmit() {
        const {stripe, elements} = this.props;
        const {getToken} = this.props;

        if (!stripe || !elements) {
            return console.log("OOF");
        }

        // Use elements.getElement to get a reference to the mounted Element.
        const cardElement = elements.getElement(CardElement);

        // Pass the Element directly to other Stripe.js methods:
        // e.g. createToken - https://stripe.com/docs/js/tokens_sources/create_token?type=cardElement
        const {token} = await stripe.createToken(cardElement);

        if (token) {
            let {id, card} = token;
            let {id: cardId, last4, brand} = card;
            let {account} = this.props.user;

            if (account !== null) {
                let sourceID = await request("account/source", "POST", {TOKEN: id});

                this.props.updateCard(sourceID);
                this.props.addCard({
                    ID: sourceID, LAST_FOUR: last4, BRAND: brand
                });
            } else {
                this.props.updateCard(id);

                this.props.addCard({
                    ID: id, LAST_FOUR: last4, BRAND: brand
                });
            }

            closeModal();
        }
    };

    render() {
        let {buttonText} = this.props;

        return (
            <StripeFormWrapper>
                <Heading>Enter card info</Heading>
                <FieldWrapper>
                    <CardElement/>
                </FieldWrapper>
                <button type="button" onClick={this.handleSubmit.bind(this)}>
                    Add Card
                </button>
            </StripeFormWrapper>
        );
    }
}

let Helper = connect(mapStateToProps, mapDispatchToProps)(StripeFormHelper);

const InjectedCheckoutForm = (() => (
    <ElementsConsumer>
        {({stripe, elements}) => (
            <Helper stripe={stripe} elements={elements}/>
        )}
    </ElementsConsumer>
));

class StripeForm extends Component {
    render() {
        let {price, buttonText} = this.props;

        return (
            <Elements stripe={stripePromise}>
                <InjectedCheckoutForm
                    getToken={(token) => {
                        console.log("TOKEN")
                    }}
                    buttonText={buttonText}
                />
            </Elements>
        );
    }
}

StripeForm.propTypes = {
    price: PropTypes.number.isRequired,
    buttonText: PropTypes.string
}

export default StripeForm;