import React, {Component} from "react"
import PropTypes from "prop-types"
import {
    StyledForm,
    StyledInput,
    StyledCategoryName,
    StyledSearchButton,
} from './search-box.style';
import {SearchIcon} from "../../assets/icons/SearchIcon";

class SearchBox extends Component {
    render() {
        let {onEnter, onChange, value, name, minimal, categoryType, buttonText, className, showButtonText = true, shadow,} = this.props;

        return (
            <StyledForm
                onSubmit={onEnter}
                className={className}
                boxShadow={shadow}
                minimal={minimal}
            >
                {minimal ? (
                    <>
                        <SearchIcon
                            style={{marginLeft: 16, marginRight: 16, color: '#212121'}}
                        />
                        <StyledInput
                            type='search'
                            onChange={onChange}
                            value={value}
                            name={name}
                            {...this.props}
                        />
                    </>
                ) : (
                    <>
                        <StyledInput
                            type='search'
                            onChange={onChange}
                            value={value}
                            name={name}
                            {...this.props}
                        />
                        <StyledSearchButton>
                            <SearchIcon style={{marginRight: 10}}/>
                            {showButtonText && buttonText}
                        </StyledSearchButton>
                    </>
                )}
            </StyledForm>
        )
    }
}

SearchBox.propTypes = {
    onEnter: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    minimal: PropTypes.bool,
    categoryType: PropTypes.string,
    buttonText: PropTypes.string,
    className: PropTypes.string,
    showButtonText: PropTypes.bool,
    shadow: PropTypes.string,
}

export default SearchBox;