import React from 'react';
export const BeautyHealth = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17.112'
      height='24'
      viewBox='0 0 17.112 24'
      {...props}
    >
      <g id='Beauty' transform='translate(-1278.138 -490.139)'>
        <path
          id='Path_17448'
          data-name='Path 17448'
          d='M1295.25,496.812a.432.432,0,0,0-.432-.432h-1.467a6.671,6.671,0,0,0-13.314,0h-1.467a.433.433,0,0,0-.432.432,8.567,8.567,0,0,0,8.124,8.545v6.335h-3.6a1.223,1.223,0,1,0,0,2.447h8.065a1.223,1.223,0,1,0,0-2.447h-3.6v-6.335A8.567,8.567,0,0,0,1295.25,496.812Zm-4.165,16.1a.359.359,0,0,1-.359.358h-8.065a.359.359,0,1,1,0-.717h8.065A.359.359,0,0,1,1291.085,512.916ZM1286.694,491a5.808,5.808,0,1,1-5.808,5.808A5.814,5.814,0,0,1,1286.694,491Zm-7.679,6.24h1.022a6.671,6.671,0,0,0,13.314,0h1.022a7.691,7.691,0,0,1-15.358,0Z'
          fill='currentColor'
        />
        <path
          id='Path_17449'
          data-name='Path 17449'
          d='M1334.423,532.013a.431.431,0,0,0,.306-.127l3.391-3.392a.432.432,0,0,0-.612-.611l-3.391,3.391a.432.432,0,0,0,.306.738Z'
          transform='translate(-50.749 -34.18)'
          fill='currentColor'
        />
        <path
          id='Path_17450'
          data-name='Path 17450'
          d='M1390.525,532.974a.431.431,0,0,0,.306-.127l.569-.569a.432.432,0,0,0-.612-.612l-.569.569a.433.433,0,0,0,.306.738Z'
          transform='translate(-101.725 -37.618)'
          fill='currentColor'
        />
        <path
          id='Path_17451'
          data-name='Path 17451'
          d='M1337.9,552.1a.432.432,0,0,0,.611,0l3.894-3.894a.432.432,0,0,0-.611-.611l-3.894,3.894A.432.432,0,0,0,1337.9,552.1Z'
          transform='translate(-54.184 -52.089)'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
