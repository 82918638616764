import React, {Component} from "react"
import PropTypes from "prop-types"
import {
  ButtonImgBox,
  CartPopupBoxButton,
  CartPopupButtonStyled,
  ItemCount,
  PriceBox, PriceBoxAlt,
  TotalItems
} from "./cart-popup-button.style";
import {ShoppingBag} from "../../assets/icons/ShoppingBag";
import {toDollars} from "../../utils/util";

class CartPopupButton extends Component {
  render() {
    let {itemCount, itemPostfix = 'items', price, pricePrefix = '$', style, onClick, className,} = this.props;

    return (
      <CartPopupButtonStyled style={style} onClick={onClick} className={className}>
        <ButtonImgBox>
          <ShoppingBag/>
        </ButtonImgBox>
        <ItemCount>
          {itemCount} {itemPostfix}
        </ItemCount>
        <PriceBox>
          {pricePrefix}

          {toDollars(price)}
        </PriceBox>
      </CartPopupButtonStyled>
    )
  }
}

CartPopupButton.propTypes = {
  itemCount: PropTypes.number,
  itemPostfix: PropTypes.any,
  price: PropTypes.number,
  pricePrefix: PropTypes.any,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
}

export const BoxedCartButton = ({
                                  itemCount, itemPostfix = 'items', price, pricePrefix = '$', style, onClick, className,
                                }) => (
  <CartPopupBoxButton style={style} onClick={onClick} className={className}>
    <TotalItems>
      <ShoppingBag/>
      {itemCount} {itemPostfix}
    </TotalItems>
    <PriceBoxAlt>
      {pricePrefix}
      {parseFloat(`${price}`).toFixed(2)}
    </PriceBoxAlt>
  </CartPopupBoxButton>
);


export default CartPopupButton;
