import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import RcDrawer from 'rc-drawer';

class Drawer extends Component {
    render() {
        let {className, children, closeButton, closeButtonStyle, drawerHandler, toggleHandler, open, width, placement,} = this.props;

        return (
            <Fragment>
                <RcDrawer
                    open={open}
                    onClose={toggleHandler}
                    className={`drawer ${className ? className : ''}`.trim()}
                    width={width}
                    placement={placement}
                    handler={false}
                    level={null}
                    duration=".4s"
                    {...this.props}
                >
                    {closeButton && (
                        <div
                            className="drawer__close"
                            onClick={toggleHandler}
                            style={closeButtonStyle}
                        >
                            {closeButton}
                        </div>
                    )}

                    {children}
                </RcDrawer>

                <div
                    className="drawer__handler"
                    style={{display: 'inline-block'}}
                    onClick={toggleHandler}
                >
                    {drawerHandler}
                </div>
            </Fragment>
        );
    }
}

Drawer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    closeButton: PropTypes.any,
    closeButtonStyle: PropTypes.object,
    drawerHandler: PropTypes.any.isRequired,
    toggleHandler: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    width: PropTypes.string,
    placement: PropTypes.string
}

export default Drawer;