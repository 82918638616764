import React, {Component} from "react"
import {CategoryWrapper, Col} from "./type-nav.style";
import {CATEGORY_MENU_ITEMS} from "../../settings/navigation";
import TypeNavCard from "./type-nav-card";
import {CategoryIcon} from "../../assets/icons/CategoryIcon";
import {withRouter} from "react-router-dom";
import {getSearchParameters, updateSearchParameters} from "../../utils/router-parser";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {general: state.general, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class TypeNav extends Component {
    handleOnClick = (item) => {
        updateSearchParameters({location: item.ID});

        this.props.switchShop(item)
        this.props.onRequestClose();
    }

    render() {
        let {location, locations} = this.props.shop;

        return (
            <CategoryWrapper>
                {locations.map((item) => (
                    <Col key={item.id}>
                        <TypeNavCard
                            onClick={() => this.handleOnClick(item)}
                            icon={<CategoryIcon name={item.icon}/>}
                            intlId={"__TODO__"}
                            defaultMessage={item.NAME}
                            active={location.ID === item.ID}
                            {...this.props}
                        />
                    </Col>
                ))}
            </CategoryWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TypeNav));