import React, {Component} from "react"
import {withFormik, Form} from "formik"
import * as Yup from "yup";
import {FieldWrapper, Heading} from "./schedule-card.style";
import TextField from "../forms/text-field/text-field";
import Button from "../button/button";
import {FormattedMessage} from "react-intl";
import {closeModal} from '@redq/reuse-modal';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import Select from "react-select";
import {COLORS} from "../../utils/util";
import DayPicker from 'react-day-picker/DayPicker';
import "react-day-picker/lib/style.css"
import {createPortal} from "react-dom"
import moment from "moment"
import {Locations} from "drip-drop"
import {ORDER_TYPE} from "../../redux/checkout";

function mapStateToProps(state) {
    return {checkout: state.checkout, user: state.user, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

const FormEnhancer = withFormik({
    mapPropsToValues: (props) => {
        let {schedule} = props.item;

        if (schedule === null) {
            return {
                date: "", time: ""
            }
        }

        return {
            date: moment(schedule),
            time: {value: schedule, label: moment(schedule).format("hh:mma")}
        };
    },
    validationSchema: Yup.object().shape({
        "date": Yup.string().required('Select a date'),
        "time": Yup.string().required('Select a time'),
    }),
    handleSubmit: async (values, {status, resetForm, setErrors, setStatus, setSubmitting, setFieldError, props, ...other}, three) => {
        props.updateSchedule(values.time.value);

        closeModal();
    },
});

class ScheduleCard extends Component {
    state = {show: false, lastClick: Date.now(), left: 0, top: 0, hours: []};

    componentWillMount() {
        this.componentId = "date"
        this.wrapperId = "dripCalendarWrapper_" + Math.floor(Math.random() * 100000)
        this.calendarId = "dripCalendarPicker_" + Math.floor(Math.random() * 100000)

        window.addEventListener("click", this.test.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.test.bind(this));
    }

    componentDidMount() {
        let {values} = this.props;

        this.recalculateHours(values.date);
    }

    focus() {
        this.field.blur();

        this.setState({
            show: true, left: document.getElementById(this.wrapperId).getBoundingClientRect().left,
            top: document.getElementById(this.wrapperId).getBoundingClientRect().top, lastClick: Date.now()
        })
    }

    test(e) {
        let {show, lastClick} = this.state
        if (!show) {
            return;
        }

        let component = document.getElementById(this.componentId);
        let calendar = document.getElementById(this.calendarId);
        if (e.target && ((component && component.contains(e.target))) || (calendar && calendar.contains(e.target))
            || (e.target.classList && e.target.classList.contains("fancy-icon"))) {
            return;
        }

        if (lastClick + 1000 > Date.now()) {
            return
        }

        this.setState({show: false})
    }

    getHours(day) {
        let {values} = this.props;
        let {location} = this.props.shop;

        if (!day) {
            return [];
        }

        let hours = Locations.Helpers.getHours(location, new Date(day));
        if (hours === null) {
            return [];
        }

        let startOfDay = moment(day).startOf("day");
        let toReturn = [];

        let start = hours.OPEN, end = hours.CLOSE;
        if (startOfDay.format("MM/DD/YY") === moment().format("MM/DD/YY")) {
            start = moment().minute(Math.ceil(moment().minute() / 15) * 15) - moment().startOf("day").valueOf();
        }

        while (end > start) {
            let leTime = moment(startOfDay).add(start, "milliseconds");
            leTime.minute(Math.round(leTime.minute() / 15) * 15).second(0).millisecond(0);

            toReturn.push({label: leTime.format("hh:mma"), value: leTime.valueOf()});

            start += 1000 * 60 * 15;
        }

        return toReturn;
    }

    recalculateHours(day) {
        this.setState({hours: this.getHours(day)});
    }

    render() {
        let {address, values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue} = this.props;
        let {show, left, top, hours} = this.state;
        let {type} = this.props.checkout;

        return (
            <Form>
                <Heading>Select {type === ORDER_TYPE.DELIVERY ? "Delivery" : "Pickup"} Time</Heading>

                <FieldWrapper>
                    <div id={this.wrapperId}>
                        <TextField
                            id="date"
                            type="text"
                            placeholder="Click to select a date"
                            error={touched.date && errors.date}
                            value={values.date ? moment(values.date).format("MM/DD/YY") : null}
                            innerRef={(e) => this.field = e}
                            onFocus={this.focus.bind(this)}
                        />

                        {show ? createPortal(
                            <DayPicker selectedDays={values.date ? values.date.toDate() : null} containerProps={{
                                style: {
                                    zIndex: 1000000, position: "absolute", left, top, backgroundColor: "white"
                                }, id: this.calendarId
                            }} onDayClick={(date) => {
                                setFieldValue("date", moment(date));
                                setFieldValue("time", null);

                                this.recalculateHours(moment(date));
                                this.setState({show: false});
                            }} month={values.date ? values.date.toDate() : new Date()}
                                       disabledDays={{before: new Date()}}/>
                            , document.getElementsByClassName("reuseModalParentWrapper")[0]) :
                            <div/>}
                    </div>
                </FieldWrapper>

                <FieldWrapper>
                    <Select menuPortalTarget={document.body} value={hours.find((item) => {
                        if (!values.time) {
                            return false;
                        }

                        return item.value === values.time.value
                    })} options={hours} onChange={(value) => {
                        setFieldValue("time", value);
                    }} styles={{
                        menuPortal: base => ({...base, zIndex: 999999}),
                        container: (base, {isFocused}) => ({
                            ...base,
                            paddingTop: 3,
                            paddingBottom: 4,
                            borderColor: isFocused ? COLORS.DRIP_MAIN : "#e1eaea"
                        }),
                        control: (base) => ({
                            ...base,
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingLeft: 10,
                            fontFamily: "Lato,sans-serif",
                            border: 0,
                            boxShadow: 0,
                            '&:hover': {
                                border: 0
                            }
                        }),
                    }} placeholder="Click to select a time"/>
                </FieldWrapper>

                <Button
                    onClick={handleSubmit}
                    type="submit"
                    style={{width: '100%', height: '44px'}}
                >
                    <FormattedMessage id="__TODO__" defaultMessage="Save Custom Time"/>
                </Button>
            </Form>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormEnhancer(ScheduleCard));
