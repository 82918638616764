import React, {Component} from "react"
import PropTypes from "prop-types"
import {CarouselResponsive, SingleItem} from "./multi-carousel.style";
import {isMobile, isTablet} from "react-device-detect"
import Carousel from 'react-multi-carousel';

class MultiCarousel extends Component {
    renderChildren() {
        let {items = [], title} = this.props;

        return items.slice(0, 6).map((item, index) => (
            <img
                src={item}
                key={index}
                alt={title}
                style={{
                    minWidth: 'auto',
                    height: 'auto',
                    position: 'relative',
                    margin: 'auto',
                }}
                className="product-image"
            />
        ));
    }

    getImages() {
        let {items = [], title} = this.props;

        return items.map((item, index) => (
            <img
                src={item}
                key={index}
                alt={title}
                style={{width: '100%', height: '100%', position: 'relative'}}
            />
        ));
    }

    renderDot(index, onClick, active) {
        return (
            <SingleItem
                data-index={index}
                key={index}
                onClick={() => onClick()}
                className={`custom-dot ${active && 'custom-dot--active'}`}
            >
                {React.Children.toArray(this.getImages())[index]}
            </SingleItem>
        )
    }

    render() {
        let {items = [], title} = this.props;

        let deviceType = 'desktop';
        if (isMobile) {
            deviceType = 'mobile';
        }

        if (isTablet) {
            deviceType = 'tablet';
        }

        return (
            <Carousel
                showDots
                ssr
                infinite={true}
                slidesToSlide={1}
                containerClass="carousel-with-custom-dots"
                responsive={CarouselResponsive}
                deviceType={deviceType}
                autoPlay={false}
                arrows={false}
                customDot={(
                        <SingleItem
                            data-index={0}
                            key={0}
                            // onClick={() => onClick()}
                            // className={`custom-dot ${active && 'custom-dot--active'}`}
                        >
                            {React.Children.toArray(this.getImages())[0]}
                        </SingleItem>
                    )}
                {...this.props}
            >
                {this.renderChildren()}
            </Carousel>
        );
    }
}

MultiCarousel.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
}

export default MultiCarousel;
