import React, {Component} from "react"
import PropTypes from "prop-types"
import Button from "../../components/button/button";
import {Box} from "../../components/box";
import {Plus} from "../../assets/icons/PlusMinus";
import {FormattedMessage} from "react-intl";
import RadioCard from "../../components/radio-card/radio-card";
import RadioGroup from "../../components/radio-group/radio-group";
import {ButtonGroup} from "../../components/button-group/button-group";
import {CardHeader} from "../../components/card-header/card-header";
import {openModal} from '@redq/reuse-modal';
import ContactCard from "../../components/contact-card/contact-card";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import {formatPhone} from "../../utils/util";
import AuthenticationForm from "../authentication-form/authentication-form";

function mapStateToProps(state) {
    return {user: state.user, checkout: state.checkout}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class Contact extends Component {
    handleOnDelete() {

    }

    handleModal(modalComponent, modalProps = {}, className = 'add-address-modal') {
        openModal({
            show: true,
            config: {
                width: 360,
                height: 'auto',
                enableResizing: false,
                disableDragging: true,
                className: className,
            },
            closeOnClickOutside: true,
            component: modalComponent,
            componentProps: {item: modalProps},
        });
    };

    render() {
        let {
            increment = false, flexStart = false, icon = false, buttonProps = {
                size: 'big', variant: 'outlined', type: 'button', className: 'add-button',
            }
        } = this.props;
        let {name, phone = ""} = this.props.checkout;
        let {account} = this.props.user;


        let names = [{
            id: 0,
            title: "Account Name",
            time_slot: "Click to login and user name"
        }, {
            id: 1,
            title: "Create Account",
            time_slot: "Click to create a new account"
        }, {
            id: 2,
            title: "Custom Name",
            time_slot: "Click to set custom name"
        }];

        console.log(phone);

        let checked = null;
        if (account !== null) {
            names.splice(0, 1, {
                id: 0,
                title: "Account Name",
                time_slot: (
                    <div>
                        {account.FULL_NAME}

                        <br />

                        {formatPhone(phone ? phone.slice(1) : "")}
                    </div>
                )
            });

            names.splice(1, 1);
        }

        if (account !== null && name === account.FULL_NAME) {
            checked = 0;
        } else if (name !== null) {
            checked = 2;
            names.splice(account === null ? 2 : 1, 1, {
                id: 2,
                title: "Custom Name",
                time_slot: (
                    <div>
                        {name}

                        <br />

                        {formatPhone(phone ? phone.slice(1) : "")}
                    </div>
                )
            });
        }

        return (
            <>
                <CardHeader increment={increment}>
                    <FormattedMessage
                        id='__TODO__'
                        defaultMessage='Contact Information'
                    />
                </CardHeader>

                <ButtonGroup flexStart={flexStart}>
                    <RadioGroup
                        items={names}
                        component={(item) => (
                            <RadioCard
                                id={item.id}
                                key={item.id}
                                title={item.title}
                                content={item.time_slot}
                                checked={item.id === checked}
                                onChange={(id) => {
                                    if (id === 2) {
                                        this.handleModal(ContactCard, item);
                                    } else if (id === 1) {
                                        openModal({
                                            show: true,
                                            overlayClassName: 'quick-view-overlay',
                                            closeOnClickOutside: true,
                                            component: AuthenticationForm,
                                            closeComponent: '',
                                            componentProps: {isJoin: false},
                                            config: {
                                                enableResizing: false,
                                                disableDragging: true,
                                                className: 'quick-view-modal',
                                                width: 400,
                                                height: 'auto',
                                            },
                                        });
                                    } else if (id === 0) {
                                        if (account !== null) {
                                            if (account.FULL_NAME.length === 0) {
                                                this.handleModal(ContactCard, item);
                                            } else {
                                                this.props.updateCheckoutName(account.FULL_NAME);
                                                this.props.updateCheckoutPhone(account.PHONE);
                                            }
                                        } else {
                                            openModal({
                                                show: true,
                                                overlayClassName: 'quick-view-overlay',
                                                closeOnClickOutside: true,
                                                component: AuthenticationForm,
                                                closeComponent: '',
                                                componentProps: {isJoin: true},
                                                config: {
                                                    enableResizing: false,
                                                    disableDragging: true,
                                                    className: 'quick-view-modal',
                                                    width: 400,
                                                    height: 'auto',
                                                },
                                            });
                                        }
                                    }
                                }}
                                name='contact'
                                onEdit={() => this.handleModal(ContactCard, item)}
                            />
                        )}
                    />
                </ButtonGroup>
            </>
        );
    }
}

Contact.propTypes = {
    increment: PropTypes.bool,
    flexStart: PropTypes.bool,
    icon: PropTypes.bool,
    buttonProps: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
