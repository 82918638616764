import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import styled from 'styled-components';

const Icon = styled.span`
  min-width: 16px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class NavLink extends Component {
    render() {
        let {href, label, intlId, icon, className, onClick, iconClass, dynamic,} = this.props;

        const isCurrentPath = this.props.history.location.pathname === href;
        return (
            <div onClick={onClick} className={className ? className : ''}>
                {dynamic ? (
                    <Link to={"/" + href} className={isCurrentPath ? ' current-page' : ''}
                          style={{display: 'flex', alignItems: 'center'}}>
                        {icon ? <Icon className={iconClass}>{icon}</Icon> : ''}

                        <span className="label">
              {intlId ? (
                  <FormattedMessage
                      id={intlId ? intlId : 'defaultNavLinkId'}
                      defaultMessage={label}
                  />
              ) : (
                  label
              )}
            </span>
                    </Link>
                ) : (
                    <Link to={href} className={isCurrentPath ? ' current-page' : ''}
                          style={{display: 'flex', alignItems: 'center'}}>
                        {icon ? <Icon className={iconClass}>{icon}</Icon> : ''}

                        <span className="label">
              {intlId ? (
                  <FormattedMessage
                      id={intlId ? intlId : 'defaultNavLinkId'}
                      defaultMessage={label}
                  />
              ) : (
                  label
              )}
            </span>
                    </Link>
                )}
            </div>
        );
    }
}

NavLink.propTypes = {
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    intlId: PropTypes.string,
    icon: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
    iconClass: PropTypes.string,
    dynamic: PropTypes.bool,
}

export default withRouter(NavLink);