import React, {Component} from "react"
import PropTypes from "prop-types"
import {ActionButton, CardButtons, CardContent, CardTitle, CardWrapper} from "./radio-card.style";
import {CloseIcon} from "../../assets/icons/CloseIcon";

class RadioCard extends Component {
    render() {
        let {
            id, name, title, content, editIcon, deleteIcon = <CloseIcon/>, withActionButtons, onEdit, onDelete, hasEdit, hasDelete,
            disabled, checked, onChange, onClick
        } = this.props;

        return (
            <CardWrapper
                htmlFor={`${name}-${id}`}
                className={`label ${title ? 'item-has-title' : 'no_title'} ${checked ? 'active' : 'not_active'}`}
            >
                <input
                    type='radio'
                    id={`${name}-${id}`}
                    name={name}
                    value={content}
                    disabled={disabled}
                    checked={checked}
                    onClick={onClick && onClick.bind(this, id)}
                    onChange={onChange.bind(this, id)}
                />
                {title && <CardTitle>{title}</CardTitle>}
                {content && <CardContent>{content}</CardContent>}
                {withActionButtons && (
                    <CardButtons className='button-wrapper'>
                        {hasEdit && (
                            <ActionButton onClick={onEdit} className='edit-btn'>
                                {editIcon}
                            </ActionButton>
                        )}
                        {hasDelete && (
                            <ActionButton onClick={onDelete} className='delete-btn'>
                                {deleteIcon}
                            </ActionButton>
                        )}
                    </CardButtons>
                )}
            </CardWrapper>
        );
    }
}

RadioCard.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,

    editIcon: PropTypes.any,
    deleteIcon: PropTypes.any,
    withActionButtons: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    hasEdit: PropTypes.bool,
    hasDelete: PropTypes.bool,
    disabled: PropTypes.bool,
    checked: PropTypes.bool
}

export default RadioCard;