import React, {Component} from "react"
import PropTypes from "prop-types"
import {CardLogo, CardNumber, Name, PaymentCardWrapper} from "./gift-card.style";
import {toDollars} from "../../utils/util";

class GiftCard extends Component {
    render() {
        let {selected, ID: id, LOCATION_NAME: name, BALANCE: balance} = this.props;

        return (
            <PaymentCardWrapper className="gift-card" selected={selected} color="rgb(228, 244, 252)">
                <CardLogo>
                    <img src="https://order.drip.is/static/media/GiftCard1.020eb362.png" alt={`gift-card-${id}`}/>
                </CardLogo>

                <div style={{padding: "0 20px 15px 20px"}}>
                    <CardNumber>
                        {name}
                    </CardNumber>
                    <Name>${toDollars(balance)}</Name>
                </div>
            </PaymentCardWrapper>
        );
    }
}

GiftCard.propTypes = {
    id: PropTypes.string.isRequired,
}

export default GiftCard;