import React, {Component} from "react"
import {
    ProductCardWrapper,
    ProductImageWrapper,
    ProductInfo,
    DiscountPercent,
    ButtonText,
} from './product-card.style';
import QuickViewMobile from "../../features/quick-view/quick-view-mobile";
import Image from "../image/image";
import {getObjectImage, toDollars} from "../../utils/util";
import Counter from "../counter/counter";
import {CartIcon} from "../../assets/icons/CartIcon";
import {FormattedMessage, injectIntl} from "react-intl";
import Button from "../button/button";
import {openModal} from "@redq/reuse-modal"
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {user: state.user, partner: state.partner, shop: state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class ProductCard extends Component {
    state = {isOpen: false};

    componentDidMount() {
    }

    render() {
        let {data} = this.props;
        let {location} = this.props.shop;
        let {LOGO: image, NAME: name, PRICE: price, ID: id} = data;

        return (
            <>
                <ProductCardWrapper onClick={() => {
                    this.props.showModal(location + "_" + id + "_PRODUCT", () => (
                        <QuickViewMobile modalProps={data} onModalClose={() => {
                            this.props.hideModal(location + "_" + id + "_PRODUCT")
                        }}/>
                    ), {
                        onClose: () => {
                            this.props.hideModal(location + "_" + id + "_PRODUCT")
                        }
                    })
                }} className="product-card">
                    <ProductImageWrapper>
                        <Image
                            url={getObjectImage(data, "LOGO", "appicon.png")}
                            className="product-image"
                            style={{position: 'relative'}}
                            alt={name}
                        />
                        {true ? (
                            <DiscountPercent>{1}%</DiscountPercent>
                        ) : null}
                    </ProductImageWrapper>
                    <ProductInfo>
                        <h3 className="product-title">{name}</h3>
                        <span className="product-weight">{3}</span>
                        <div className="product-meta">
                            <div className="productPriceWrapper">
                                {true ? (
                                    <span className="discountedPrice">
                                    ${toDollars(price)}
                                </span>
                                ) : null}

                                <span className="product-price">
                                ${toDollars(price)}
                            </span>
                            </div>

                            {true ? (
                                <Button
                                    className="cart-button"
                                    variant="secondary"
                                    borderRadius={100}
                                    onClick={() => {

                                    }}
                                >
                                    <CartIcon mr={2}/>
                                    <ButtonText>
                                        <FormattedMessage id="addCartButton" defaultMessage="Cart"/>
                                    </ButtonText>
                                </Button>
                            ) : (
                                <Counter
                                    value={3}
                                    onDecrement={() => {

                                    }}
                                    onIncrement={() => {

                                    }}
                                    className="card-counter"
                                />
                            )}
                        </div>
                    </ProductInfo>
                </ProductCardWrapper>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
