import React, {Component} from "react"
import {Modal} from "@redq/reuse-modal";
import ModalContainer from "../../components/modal/modal-container";
import {PageWrapper, SidebarSection} from "../../features/profile/profile.style";
import Sidebar from "../../features/profile/sidebar/sidebar";
import Orders from "../../features/profile/orders/orders";
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {user: state.user}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class OrdersPage extends Component {
    render() {
        let {account} = this.props.user;

        if (account === null) {
            return (
                <div>
                    Loading
                </div>
            );
        }

        return (
            <ModalContainer>
                <Modal>
                    <PageWrapper>
                        <SidebarSection>
                            <Sidebar/>
                        </SidebarSection>

                        <Orders />
                    </PageWrapper>
                </Modal>
            </ModalContainer>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);