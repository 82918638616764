import React, {Component} from "react"
import PropTypes from "prop-types"
import {
    ButtonText,
    DiscountPercent,
    MetaItem,
    MetaSingle,
    ModalClose,
    ProductCartBtn,
    ProductCartWrapper,
    ProductDescription,
    ProductDetailsWrapper,
    ProductInfo,
    ProductInfoWrapper,
    ProductMeta,
    ProductModal,
    ProductPreview,
    ProductPrice,
    ProductPriceWrapper,
    ProductTitle,
    ProductTitlePriceWrapper,
    ProductUnavailable,
    QuickViewWrapper,
    SalePrice,
} from './quick-view.style';
import {bindActionCreators} from "redux";
import Actions from "../../redux";
import {connect} from "react-redux";
import MultiCarousel from "../../components/multi-carousel/multi-carousel";
import {asyncTimeout, getObjectImage, toDollars} from "../../utils/util";
import {withRouter} from "react-router-dom"
import Button from "../../components/button/button";
import {CartIcon} from "../../assets/icons/CartIcon";
import {FormattedMessage} from "react-intl";
import Counter from "../../components/counter/counter";
import Truncate from "../../components/truncate/truncate";
import {CloseIcon} from "../../assets/icons/CloseIcon";
import Customization from "../../components/customization/customization";
import {pushLocation} from "../../utils/router-parser";
import {Products} from "drip-drop"
import {getProductPrice} from "drip-drop/src/product/ProductHelper";
import TextField from "../../components/forms/text-field/text-field";

function mapStateToProps(state) {
    return {shop: state.shop, cart: state.cart}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class QuickViewMobile extends Component {
    state = {unique: null, customizations: [], notes: ""};

    componentDidMount() {
        let {modalProps, cartItem = null} = this.props;

        let customizations = [], notes = "";
        if (cartItem === null) {
            for (let item of modalProps.CUSTOMIZATIONS) {
                if (item.DEFAULT_OPTION === null) {
                    continue;
                }

                let option = item.OPTIONS.find((option) => option.ID === item.DEFAULT_OPTION);
                if (!option) {
                    continue;
                }

                customizations.push({
                    ID: item.ID,
                    VALUE: option.ID,
                    LABEL: item.NAME,
                    DESCRIPTOR: option.NAME,
                    PRICE: option.PRICE,
                    QUANTITY: 1,
                    TYPE: item.TYPE,
                    MODIFIER: item.MODIFIER,

                    label: option.NAME,
                    value: option.ID,
                })
            }
        } else {
            customizations = cartItem.CUSTOMIZATIONS;
            notes = cartItem.NOTES;
        }

        this.setState({customizations, notes, unique: cartItem === null ? null : cartItem.UNIQUE})
    }

    async onCategoryClick(category) {
        let {onModalClose} = this.props;
        let {location} = this.props.shop;

        onModalClose();

        if (window.location.href.indexOf('/order') === -1) {
            pushLocation.call(this, "order", {location: location.ID});

            await asyncTimeout(500);
        }

        const yOffset = -160;
        const element = document.getElementById(category.NAME);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    onCartClick() {
        let {onModalClose, modalProps, cartItem = null} = this.props;
        let {customizations, unique: cartUnique, notes} = this.state;

        let isValid = true;
        for (let item of this.myRefs) {
            if (item === null) {
                continue;
            }

            if (!item.isValid()) {
                isValid = false;
            }
        }

        if (!isValid) {
            return;
        }

        if (cartUnique || cartItem) {
            this.props.updateCartItem({
                ...this.props.getCartItem(cartUnique || cartItem.UNIQUE),
                CUSTOMIZATIONS: JSON.parse(JSON.stringify(customizations)),
                NOTES: notes
            })

            return onModalClose();
        }

        let unique = Math.round(Math.random() * 1000000);
        this.props.addCartItem({
            UNIQUE: unique,
            LOCATION_ID: modalProps.LOCATION_ID,
            ID: modalProps.ID,
            NAME: modalProps.NAME,
            LOGO: modalProps.LOGO,
            PRICE: modalProps.PRICE,
            QUANTITY: 1,
            CATEGORY_ID: modalProps.CATEGORY_ID,
            CUSTOMIZATIONS: JSON.parse(JSON.stringify(customizations)),
            NOTES: notes
        });

        this.setState({unique})
    }

    renderCartButton() {
        let {unique} = this.state;
        let {cartItem = null, modalProps} = this.props;
        let {INVENTORY: inventory} = modalProps

        if (cartItem !== null || unique !== null) {
            return (
                <div style={{display: "flex", flexDirection: "row"}}>
                    <Counter // In cart
                        style={{marginRight: 8}}
                        value={this.props.getCartItem(unique || cartItem.UNIQUE).QUANTITY}
                        onDecrement={this.props.decrementCartItem.bind(this, unique || cartItem.UNIQUE)}
                        onIncrement={this.props.incrementCartItem.bind(this, unique || cartItem.UNIQUE)}
                    />

                    <Button
                        className='cart-button'
                        variant='secondary'
                        borderRadius={100}
                        onClick={this.onCartClick.bind(this)}
                    >
                        <CartIcon mr={2}/>

                        <ButtonText>
                            <FormattedMessage
                                id="__TODO__"
                                defaultMessage="Update"
                            />
                        </ButtonText>
                    </Button>
                </div>
            )
        }

        if (inventory !== null && inventory <= 0) {
            return (
                <Button
                    disabled={true}
                    className='cart-button'
                    variant='secondary'
                    borderRadius={100}
                    onClick={this.onCartClick.bind(this)}
                >
                    <CartIcon mr={2}/>

                    <ButtonText>
                        <FormattedMessage
                            id='__TODO__'
                            defaultMessage='Out of Stock'
                        />
                    </ButtonText>
                </Button>
            )
        }


        return (
            <Button
                className='cart-button'
                variant='secondary'
                borderRadius={100}
                onClick={this.onCartClick.bind(this)}
            >
                <CartIcon mr={2}/>

                <ButtonText>
                    <FormattedMessage
                        id='__TODO__'
                        defaultMessage='Add to Cart'
                    />
                </ButtonText>
            </Button>
        )
    }

    renderUnavailable() {
        let {modalProps} = this.props;
        let {UNAVAILABILITY: unavailability} = modalProps

        if (unavailability.length === 0) {
            return <div/>
        }

        return (
            <ProductMeta>
                <ProductUnavailable>
                    Pickup Unavailable
                </ProductUnavailable>

                <MetaSingle>
                    {unavailability.map((item) => (
                        <MetaItem key={item.ID}>
                            {Products.Helpers.getProductUnavailability(item).capitalize()}
                        </MetaItem>
                    ))}
                </MetaSingle>
            </ProductMeta>
        )
    }

    render() {
        let {modalProps, cartItem, onModalClose} = this.props;
        let {customizations, unique, notes} = this.state;
        let {categories, location} = this.props.shop;

        let {NAME: name, PRICE: price, DESCRIPTION: description, CATEGORY_ID: category, ADVANCED_TIME: advancedTime} = modalProps;
        this.myRefs = [];

        if (unique || cartItem) {
            let item = this.props.getCartItem(unique || cartItem.UNIQUE);

            if (!item) {
                return <div/>
            }
        }

        let {price: finalPrice, discount, base, promotions: productPromotions} = getProductPrice(location, JSON.parse(JSON.stringify({
            UNIQUE: Math.round(Math.random() * 1000000),
            LOCATION_ID: modalProps.LOCATION_ID,
            CATEGORY_ID: modalProps.CATEGORY_ID,
            ID: modalProps.ID,
            NAME: modalProps.NAME,
            LOGO: modalProps.LOGO,
            PRICE: modalProps.PRICE,
            QUANTITY: 1,
            CUSTOMIZATIONS: customizations
        })), this.props.getPromotions());

        productPromotions = productPromotions.filter((item) => {
            return item.DISCOUNT.FLAT_FEE !== null || item.DISCOUNT.PERCENTAGE !== null;
        })

        return (
            <>
                <ModalClose onClick={onModalClose.bind(this)}>
                    <CloseIcon/>
                </ModalClose>

                <QuickViewWrapper className='quick-view-mobile-wrapper'>
                    <ProductDetailsWrapper className='product-card' dir='ltr'>
                        <ProductModal>
                            <ProductPreview>
                                <MultiCarousel items={[getObjectImage(modalProps, "LOGO", "appicon.png")]}/>

                                {productPromotions.map((item) => {
                                    let amount = item.DISCOUNT.FLAT_FEE !== null ?
                                        "$" + toDollars(item.DISCOUNT.FLAT_FEE) :
                                        item.DISCOUNT.PERCENTAGE + "%";

                                    return (
                                        <DiscountPercent>
                                            {amount}
                                        </DiscountPercent>
                                    )
                                })}
                            </ProductPreview>

                            <ProductInfoWrapper dir="ltr">
                                <ProductInfo>
                                    <ProductTitlePriceWrapper>
                                        <ProductTitle>{name}</ProductTitle>
                                    </ProductTitlePriceWrapper>

                                    {advancedTime && advancedTime > 0 ? (<MetaItem>
                                        {advancedTime === 0 ? "Day" : advancedTime + " Days"} in Advanced Notice Needed
                                    </MetaItem>) : <div/>}

                                    {this.renderUnavailable()}

                                    <ProductDescription>
                                        <Truncate character={600}>{description}</Truncate>
                                    </ProductDescription>

                                    <ProductMeta>
                                        <MetaSingle>
                                            {categories ? categories.filter((item) => item.ID === category).map((item) => (
                                                <MetaItem
                                                    onClick={() => this.onCategoryClick(item)}
                                                    key={item.id}
                                                >
                                                    {item.NAME}
                                                </MetaItem>
                                            )) : ''}
                                        </MetaSingle>
                                    </ProductMeta>

                                    <ProductCartWrapper>
                                        <ProductPriceWrapper>
                                            <ProductPrice>
                                                ${toDollars(finalPrice)}
                                            </ProductPrice>

                                            {discount > 0 ? (
                                                <SalePrice>
                                                    ${toDollars(base)}
                                                </SalePrice>
                                            ) : null}
                                        </ProductPriceWrapper>

                                        <ProductCartBtn>
                                            {this.renderCartButton()}
                                        </ProductCartBtn>
                                    </ProductCartWrapper>

                                    {modalProps.CUSTOMIZATIONS.map((item, i) => (
                                        <Customization parentCustomizations={customizations}
                                                       ref={(e) => this.myRefs.push(e)}
                                                       updateCustomizations={(customizations, callback) => this.setState({customizations}, callback)}
                                                       item={item} updatePrice={() => {
                                        }} {...this.props} key={i}/>
                                    ))}

                                    <TextField
                                        rows="3"
                                        style={{minHeight: 30}}
                                        id="notes"
                                        as="textarea"
                                        placeholder="Enter Special Order Instructions Here"
                                        // error={touched["address-line1"] && errors["address-line1"]}
                                        value={notes}
                                        onChange={(e) => this.setState({notes: e.target.value})}
                                    />
                                </ProductInfo>
                            </ProductInfoWrapper>
                        </ProductModal>
                    </ProductDetailsWrapper>
                </QuickViewWrapper>
            </>
        );
    }
}

QuickViewMobile.propTypes = {
    modalProps: PropTypes.any.isRequired,
    onModalClose: PropTypes.func,
    hideModal: PropTypes.any,
    cartItem: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickViewMobile));
