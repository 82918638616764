import styled from 'styled-components';
import css from '@styled-system/css';

export const CategoryWrapper = styled.div(
    css({
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        // margin: '0 -7.5px',
    })
);

export const Col = styled.div(
    css({
        width: '33.333%',
        padding: '0 7.5px',
        marginBottom: 15,

        '@media screen and (min-width: 768px)': {
            width: '25%',
        },
    })
);
