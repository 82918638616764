import React, {Component} from "react"
import Sticky from 'react-stickynode';

import {LayoutWrapper} from "./styles/app-layout";
import Header from "./header/header";

import 'rc-drawer/assets/index.css';
import 'rc-table/assets/index.css';
import 'rc-collapse/assets/index.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-spring-modal/dist/index.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import './../components/multi-carousel/multi-carousel.style.css';
import './../components/scrollbar/scrollbar.css';
import '@redq/reuse-modal/lib/index.css';
import Intercom from 'react-intercom';
import { H } from 'highlight.run'

import MobileHeader from "./header/mobile/mobile-header";
import {bindActionCreators} from "redux";
import Actions from "../redux";
import {connect} from "react-redux";
import {isOnHomePage} from "../utils/router-parser";
import {withRouter} from "react-router-dom";

function mapStateToProps(state) {
    return {user: state.user, general: state.general, shop : state.shop}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch)
}

class App extends Component {
    async componentDidMount() {
        this.props.loadShop();
        this.props.loadUser();

        // window.Intercom('boot', {
        //     app_id: 'wzbgukrv',
        //
        //     // email: 'example@example.com',
        //     // user_id: 'abc123',
        //     // created_at: 1234567890
        // });
    }

    render() {
        const {isSticky} = this.props.general;
        const isHomePage = isOnHomePage.call(this);

        return (
            <>
                <LayoutWrapper>
                    <Sticky enabled={isSticky} innerZ={1001}>
                        <MobileHeader
                            className={`${isSticky ? 'sticky' : 'unSticky'} ${
                                isHomePage ? 'home' : ''
                            } desktop`}
                        />

                        <Header className={`${(isSticky && isHomePage) ? 'sticky' : 'unSticky'} ${
                            isHomePage ? 'home' : ''
                        }`}/>
                    </Sticky>

                    <Intercom appID="wzbgukrv" />

                    {this.props.children}
                </LayoutWrapper>
            </>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
