import React, {Component} from "react"
import PropTypes from "prop-types"
import {Spinner, StyledButton} from "./button.style";

class Button extends Component {
    render() {
        let {children, disabled, loading = false} = this.props;

        return (
            <StyledButton {...this.props} disabled={disabled}>
                {children}
                {loading && <Spinner/>}
            </StyledButton>
        )
    }
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
}

export default Button;